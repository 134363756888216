import React from "react";

function UserGuideIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#FFACC5"
        d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z"
      ></path>
      <path
        fill="#fff"
        d="M12.038 20.133c0 .76.608 1.368 1.33 1.368.76 0 1.35-.608 1.35-1.368 0-.741-.59-1.33-1.35-1.33-.722 0-1.33.589-1.33 1.33zm6.118-10.26c0-2.85-1.919-3.572-4.332-3.572-1.235 0-2.413.361-3.135.627v1.767c1.121-.361 2.09-.627 3.135-.627 1.444 0 2.318.513 2.318 1.805 0 1.197-1.368 2.242-2.66 3.306-1.159.931-1.159 1.729-1.159 2.641v.988c0 .304.323.456 1.007.456.608 0 .95-.114.95-.456v-.912c0-.969.266-1.235 1.121-1.995 1.026-.912 2.755-2.261 2.755-4.028z"
      ></path>
    </svg>
  );
}

export default UserGuideIcon;