import { properties } from 'api-manager/resource/Properties';
import * as React from 'react';

interface NaverUser {
  email: string
  name: string
  id: string
  profile_image: string;
  age?: string
  birthday?: string
  gender?: string
  nickname?: string
}
interface NaverResponse {
  accessToken: string
  accessTokenName: string
  status: string
  naverUser: NaverUser
}

interface IProps {
  clientId: string;
  callbackUrl: string;
  render: (props: any) => React.ComponentElement<any, any> | JSX.Element;
  onSuccess: (result: NaverResponse) => void;
  onFailure: (error: any) => void;
}

const initLoginButton = (props: IProps) => {
  const { clientId, callbackUrl, onSuccess, onFailure } = props;
  const naver = window['naver'];

  const naverLogin = new naver.LoginWithNaverId(
    {
      callbackUrl,
      clientId,
      isPopup: true,
      loginButton: {color: "green", type: 3, height: 60},
    }
  );

  naverLogin.init();

  if (!window.opener) {
    naver.successCallback = (data: NaverResponse) => onSuccess(data);
    naver.failureCallback = onFailure;
  } else {
    naverLogin.getLoginStatus((status: any) => {
      if (status) {
        window.opener.naver.successCallback(naverLogin.loginStatus);
      } else {
        window.opener.failureCallback(naverLogin);
      }
      window.close();
    })
  }
};

const appendNaverButton = () => {
  if (document && document.querySelectorAll('#naverIdLogin').length === 0) {
    const naverId = document.createElement('div');
    naverId.id = 'naverIdLogin';
    naverId.style.position =  'absolute';
    naverId.style.top = '-10000px';
    document.body.appendChild(naverId);
  }
}
const loadScript = (props: IProps) => {
  if (document && document.querySelectorAll('#naver-login-sdk').length === 0) {
    const script = document.createElement('script');
    script.id = 'naver-login-sdk';
    script.src = properties.NAVER_SDK;
    script.onload = () => initLoginButton(props);
    document.head.appendChild(script);
  }
}

class NaverLogin extends React.Component<IProps> {
   componentDidMount() {
    appendNaverButton();
    loadScript(this.props);
  }
    

  public render() {
    const { render } = this.props;
    return (
      render({ 
        onClick: () => { 
          if (!document || !(document as any).querySelector('#naverIdLogin').firstChild) return;
          const naverLoginButton: any = (document as any).querySelector('#naverIdLogin').firstChild;
          naverLoginButton.click();
        }
      })
    )
  }
}

export default NaverLogin;