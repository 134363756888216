import { useRef } from "react"
import styles from "./toggle-button.module.css";

interface ToggleButtonProps {
  checked: boolean;
  handleToggle: () => void;
}

export const ToggleButton:React.FC<ToggleButtonProps> = ({checked, handleToggle}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <label className={styles.switch}>
      <input type="checkbox" ref={checkboxRef} defaultChecked={checked} onClick={handleToggle}/>
      <span
        className={`${styles.slider} ${styles.round}`} 
        style={{
          border: checked ? "none" : "1px solid #EB4678",
        }}
      >
      </span>
    </label>
  )
}