import { MobileController } from "components/chat/control/MobileController";
import { TextChat } from "components/chat/text/TextChat";
import { VideoChat } from "components/chat/video/VideoChat";
import { UserSettingProvider } from "context/UserSettingContext";
import { UserVideoChatContext, UserVideoChatProvider } from "context/UserVideoChatContext";
import { UsersTextChatProvider } from "context/UsersTextChatContext";
import { inject, observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { ControllerButtonGroup, MetaverseWrapper } from "./MetaverseStyled";
import { UtilController } from "./UtilManager/UtilController";
import MetaverseInitialize from "./app";

import { Loading } from "components/loading/Loading";
import {
  DeviceDetectInterface,
  getDeviceDetect,
  handleDeviceResize,
} from "utils/WindowDimensions";
import { IconOnlyButton } from "components/buttons/common/IconOnlyButton";
import LocationIcon from "components/buttons/common/LocationButton";
import EmoteIcon from "components/buttons/common/EmoteButton";
import TextIcon from "components/buttons/common/TextButton";
import { TModal } from "components/sidebar/living/types";
import NavigationBar from "pages/main/NavigationBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SceneState } from "./SceneManager/SceneInterface";
import { NAVIGATION_ROUTER } from "routes/constant";

const MetaversePage = inject("store")(
  observer((props: any) => {
    const navigate = useNavigate();
    const { store } = props;
    const { userStore, movementStore, sceneStore, modalStore } = store;
    const { onChangeUserSpace } = useContext(UserVideoChatContext);
    const _ref = useRef<HTMLCanvasElement>(null);
    const LoadingElementRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingProgress, setLoadingProgress] = useState(0);
    let isJumping = false;

    useEffect(() => {
      if (sceneStore.network !== null) {
        sceneStore.network.leaveRoom();
      }

      // get scene from query string
      const scene = searchParams.get("scene");
      if (scene && SceneState[scene]) {
        sceneStore.setCurrentScene(SceneState[scene]);
      } else {
        sceneStore.setCurrentScene(SceneState.LOUNGE);
      }
    }, []);

    handleDeviceResize((device: DeviceDetectInterface) =>
      setIsMobile(device.isMobile)
    );


    useEffect(() => {
      if (userStore.isNewUser) navigate(NAVIGATION_ROUTER.CHARACTER);
      else {
        const { current: canvas } = _ref;
        if (!canvas) return;
        
        new MetaverseInitialize(
          canvas,
          store,
          onChangeUserSpace,
          setLoadingProgress
        );
      }
    }, [navigate, onChangeUserSpace, store, userStore.isNewUser]);

    const actionMove = (e: any) => {
      movementStore.setJoystickDeltaData(e);
    };

    const actionStop = (e: any) => {
      movementStore.setJoystickDeltaData(e);
    };

    const actionJump = (e: any) => {
      if (!isJumping) {
        isJumping = true;
        movementStore.setJumpingState(true);
        setTimeout(() => {
          movementStore.setJumpingState(false);
        }, 50);
        setTimeout(() => {
          isJumping = false;
        }, 500);
      }
    };

    const onChangeModal = (modal: TModal) => {
      modalStore.setModal(modal);
    };

    return (
      <Loading loading={store.isLoading} progress={loadingProgress}>
        <MetaverseWrapper isMobile={isMobile}>
          {userStore.allowMediaService() && sceneStore.isAllowMedia && (
            <>
              <MobileController
                actionMove={actionMove}
                actionStop={actionStop}
                actionJump={actionJump}
                isMobile={isMobile}
              />
              <UserSettingProvider>
                <UsersTextChatProvider>
                  <UtilController visible={true} isMobile={isMobile} />
                  <TextChat
                    visible={modalStore.modal === "TextChat"}
                    isMobile={isMobile}
                  />
                  <VideoChat visible={true} isMobile={isMobile} store={store} />
                  <ControllerButtonGroup
                    visible={modalStore.modal !== "Location"}
                    isMobile={isMobile}
                  >
                    <IconOnlyButton
                      isMobile={isMobile}
                      icon={<LocationIcon />}
                      onClick={() => onChangeModal("Location")}
                    />
                    <IconOnlyButton
                      isMobile={isMobile}
                      icon={<EmoteIcon />}
                      onClick={() => {}}
                    />
                    {isMobile && (
                      <IconOnlyButton
                        isMobile={isMobile}
                        icon={<TextIcon />}
                        onClick={() => onChangeModal("TextChat")}
                      />
                    )}
                  </ControllerButtonGroup>
                </UsersTextChatProvider>
              </UserSettingProvider>
            </>
          )}
          <canvas ref={_ref} id="metaverse-canvas" />
        </MetaverseWrapper>
      </Loading>
    );
  })
);

export default MetaversePage;
