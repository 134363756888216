import styled from "styled-components";

interface MemberTooltipProps {
  isMobile: boolean;
}

export const MemberTooltip = styled.div<MemberTooltipProps>`
  position: absolute;
  top: 5%;
  left: 3%;

  padding: ${(props) => (props.isMobile ? "5px 6px" : "6px 8px")};
  background: rgba(0, 0, 0, 0.57);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  .member-amount {
    font-size: ${(props) => (props.isMobile ? "10px" : "15px")};
  }
  font-weight: 600;
  gap: 6px;
`;
