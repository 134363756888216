import {
  Box,
  TextField,
  TextFieldProps,
} from "@mui/material";
import styled from "styled-components";
export type InputFormProps = TextFieldProps & {};

type ITextAreaStyled = {
  error: string;
};

interface IContactUsPageStyled {
  isMobile?: boolean;
}
export const ContactUsPageStyled = styled(Box)<IContactUsPageStyled>`
  padding: ${(props) =>
    props.isMobile ? "150px 12px 70px" : "180px 100px 123px 100px"};
  .contact-us-header {
    padding-bottom: 35px;
    border-bottom: 1px solid #aaaaaa;
    span {
      font-weight: bold;
      font-size: ${(props) => (props.isMobile ? "30px" : "70px")};
      font-family: "Godo B";
    }
  }

  .form {
    padding-top: 35px;
    max-width: 670px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.isMobile ? "30px" : "43px")};

    .submit-btn {
      display: flex;
      justify-content: center;
      button {
        width: 238px;
        height: 53px;
        border: 2px solid #000000;
        border-radius: 16px;
        font-family: "Godo M";
        font-weight: 400;
        font-size: 19px;
        color: #000000;
        text-transform: capitalize;
      }
    }
  }

  .submitted {
    width: 819px;
    height: 184px;
    border: 2px solid #0a8817;
    border-radius: 30px;
    margin-top: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .submitted-text {
      font-family: "Godo M";
      font-weight: 400;
      font-size: 25px;
      color: #0a8817;
      text-align: center;
      p {
        margin: 0;
        line-height: 1.3;
      }
    }
  }
`;

export const InputForm = styled(TextField)<InputFormProps>`
  .MuiInputBase-root {
    border-radius: 30px;
    width: 100%;
    border: ${(props) => `1px solid ${props.error ? "red" : "#939393"} `};
    font-family: "Godo M";
    color: #939393;
    font-weight: 400;
    font-size: ${(props) => props.isMobile ? "12px" : "15px"};
    padding-left: 10px;
    input::placeholder {
      opacity: 1;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
  }
  .MuiFormHelperText-root {
    font-family: "Godo M";
    font-weight: 400;
    font-size: 15px;
    color: #ff2b2b !important;
  }
`;

export const TextAreaStyled = styled(Box)<ITextAreaStyled>`
  textarea {
    height: 252px !important;
    width: calc(100% - 40px);
    max-width: 630px;
    resize: none;
    padding: 20px;
    border-radius: 30px;
    border-color: ${(props) => (props.error ? "#FF2323" : "#979797")};
    font-family: "Godo M";
    color: #979797;
    font-weight: 400;
    font-size: ${(props) => (props.isMobile ? "12px" : "15px")};
    :focus-visible {
      outline: none;
    }
  }
  .text-area-error {
    font-family: "Godo M";
    font-weight: 400;
    font-size: 15px;
    color: #ff2b2b;
    display: ${(props) => (props.error ? "block" : "none")};
    margin-top: 3px;
    padding-left: 10px;
  }
`;
