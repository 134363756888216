import {
  Engine,
  HemisphericLight,
  Scene,
  Vector3,
  ArcRotateCamera,
  MeshBuilder,
  StandardMaterial,
  CubeTexture,
  Texture,
  Color3,
  Mesh,
  SceneLoader,
} from "@babylonjs/core";
import { UserVideoChatWay } from "context/UserVideoChatContext";
import { MetaverseStore } from "stores/MetaverseStore";
import { AssestController } from "../AssetsManager/AssestController";
import {
  CameraController,
  CAMERA_TYPE,
} from "../CameraManager/CameraController";

import { NetworkHandle } from "../Network/NetworkHandle";
import { SceneState } from "./SceneInterface";
import { CharacterModel } from "../CharacterManager/Character";
import { Inspector } from "../utils/Inspector";

interface LoungeProps {
  engine: Engine;
  store: MetaverseStore;
  onChangeScene: () => void;
  onLoad: (scene: Scene, camera: ArcRotateCamera) => void;
  onChangeUserSpace: (type: UserVideoChatWay, data: string) => void;
  onProgress: (value: number) => void;
}
export const Lounge = async (props: LoungeProps): Promise<void> => {
  const {
    engine,
    store,
    onChangeScene,
    onLoad,
    onChangeUserSpace,
    onProgress,
  } = props;
  let multiPlayer: NetworkHandle | null = null;
  const token = store.userStore.token;
  const scene = new Scene(engine);
  new HemisphericLight("light", new Vector3(0, 1, 0), scene);
  store.setIsLoading(true);
  const map = await new AssestController(scene).loadLounge(
    (isLoading: boolean) => store.setIsLoading(isLoading),
    onProgress
  );

  // Skybox
  const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
  const skyboxMaterial = new StandardMaterial("skyBox", scene);
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new CubeTexture("textures/skybox", scene);
  skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
  skyboxMaterial.specularColor = new Color3(0, 0, 0);
  skybox.material = skyboxMaterial;

  //Mouse
  await require("recast-detour")();
  const mergeMesh = Mesh.MergeMeshes(
    map.root,
    true,
    true,
    undefined,
    false,
    true
  ) as Mesh;
  const playerModel = new CharacterModel(scene);
  await playerModel.loadAssetManager();
  const { cameraMobile } = new CameraController(scene);

  multiPlayer = new NetworkHandle(
    playerModel,
    cameraMobile,
    store,
    token,
    scene,
    mergeMesh,
    onChangeUserSpace,
    () => {
      if (multiPlayer) {
        multiPlayer.leaveRoom();
        multiPlayer = null;
      }
      store.sceneStore.setLastScene(SceneState.INTERNET_CAFE);
      onChangeScene();
    },
    SceneState.INTERNET_CAFE,
    new Vector3(0, 1, 5)
  );
  await multiPlayer.init();
  store.sceneStore.setNetwork(multiPlayer);
  // character.checkCollisions = true;
  cameraMobile.onCollide = (mergeMesh) => {
    if (cameraMobile.radius > 1.3) {
      cameraMobile.radius -= 0.01;
    }
  };

  scene.collisionsEnabled = true;
  onLoad(scene, cameraMobile);
};
