import React from 'react';
import { SocialLoginButton } from 'components';

type Props = {
  callbackFunc?: () => void;
};

export const NaverLoginButton: React.FC<Props> = ({ callbackFunc }) => {
  return (
    <SocialLoginButton
      onClick={() => {
        callbackFunc?.();
      }}
      type="naver"
    />
  );
};
