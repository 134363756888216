import {
  AbstractMesh,
  ArcRotateCamera,
  FollowCamera,
  Animation,
  FreeCamera,
  Nullable,
  Scene,
  Vector3,
  IAnimationKey,
} from "@babylonjs/core";
export const CAMERA_TYPE = {
  FPS: "FPS",
  TPS: "TPS",
  TPS2: "TPS2",
  MOBILE: "MOBILE",
};
export class CameraController {
  private _cameraTPS: ArcRotateCamera;
  private _cameraTPS2: FollowCamera;
  private _cameraMobile: ArcRotateCamera;
  private _cameraFPS: FreeCamera;
  private _camera: FollowCamera | FreeCamera | ArcRotateCamera;
  private _scene: Scene;
  private _canvas: Nullable<HTMLCanvasElement>;
  constructor(scene: Scene) {
    this._scene = scene;
    this._canvas = this._scene.getEngine().getRenderingCanvas();
    this._cameraFPS = new FreeCamera(
      CAMERA_TYPE.FPS,
      new Vector3(0, 1, 0),
      this._scene
    );
    this._cameraTPS = new ArcRotateCamera(
      CAMERA_TYPE.TPS,
      Math.PI / 2,
      Math.PI / 4,
      10,
      new Vector3(0, 0, 0),
      this._scene
    );
    this._cameraMobile = new ArcRotateCamera(
      CAMERA_TYPE.MOBILE,
      -Math.PI / 2,
      Math.PI / 4,
      10,
      new Vector3(0, 0, 0),
      this._scene
    );

    this._cameraTPS2 = new FollowCamera(
      CAMERA_TYPE.TPS2,
      new Vector3(1, 2.5, 2),
      this._scene
    );
    this._camera = this._cameraTPS;
    this.init();
  }

  public setCamera = (camera: string): void => {
    if (camera === CAMERA_TYPE.FPS) {
      this._camera = this._cameraFPS;
    } else if (camera === CAMERA_TYPE.TPS) {
      this._camera = this._cameraTPS;
    } else if (camera === CAMERA_TYPE.MOBILE) {
      this._camera = this._cameraMobile;
    }
    this._scene.activeCamera = this._camera;
    this._scene.activeCamera.attachControl(this._canvas, true);
  };

  private setCameraFPSConfig = () => {
    this._cameraFPS.speed = 0.1;
    this._scene.collisionsEnabled = true;
  };

  private setCameraTPSConfig = () => {
    this._cameraTPS.upperAlphaLimit = 2.81;
    this._cameraTPS.lowerAlphaLimit = 1.95;
    this._cameraTPS.upperBetaLimit = 1.65;
    this._cameraTPS.upperRadiusLimit = 50;
    this._cameraTPS.lowerRadiusLimit = 5;
  };

  private setCameraMobileConfig = () => {
    this._cameraMobile.radius = Math.PI;
    this._cameraMobile.upperRadiusLimit = (3 * Math.PI) / 4;
    this._cameraMobile.lowerRadiusLimit = 1.3;
    this._cameraMobile.lowerBetaLimit = (3 * Math.PI) / 8;
    this._cameraMobile.upperBetaLimit = (5 * Math.PI) / 12;
    this._cameraMobile.attachControl(this._canvas, true);
    this._cameraMobile.checkCollisions = true;
    this._cameraMobile.collisionRadius = new Vector3(0.5, 0.5, 0.5);
    this._cameraMobile.minZ = 1.3;
    this._cameraMobile.inertia = 0.2;
    this._cameraMobile.fov = 0.9151;
  };

  private setCameraTPS2Config = () => {
    this._cameraTPS2.heightOffset = 2;
    this._cameraTPS2.rotationOffset = 180;
    this._cameraTPS2.maxCameraSpeed = 1.5;
    this._cameraTPS2.inputs.removeByType("FollowCameraPointersInput");
    this._cameraTPS2.radius = 3;
    this._cameraTPS2.upperRadiusLimit = 4;
    this._cameraTPS2.lowerRadiusLimit = 3;
    this._cameraTPS2.cameraAcceleration = 0.01;
    this._cameraTPS2.fov = 0.9;
  };

  public init = () => {
    this.setCamera(CAMERA_TYPE.TPS2);
    this.setCameraFPSConfig();
    this.setCameraTPSConfig();
    this.setCameraTPS2Config();
    this.setCameraMobileConfig();
  };

  public focusOnItem = (
    camera: ArcRotateCamera,
    item: AbstractMesh,
    vector3: Vector3
  ) => {
    const alpha = camera.alpha;
    const beta = camera.beta;
    const radius = camera.radius;
    const target = camera.getTarget().clone();
    const direction = camera.position
      .subtract(item.absolutePosition)
      .normalize();
    camera.position = direction;
    camera.setTarget(item.absolutePosition);
    camera.focusOn({ min: camera.position, max: vector3, distance: 0 }, true);
    // camera.focusOn([item], true);

    camera.rebuildAnglesAndRadius();
    const animCamAlpha = new Animation(
      "animCam",
      "alpha",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );
    const keysAlpha: IAnimationKey[] = [];
    keysAlpha.push({
      frame: 0,
      value: alpha,
    });
    keysAlpha.push({
      frame: 100,
      value: Math.PI / 2,
    });
    const animCamBeta = new Animation(
      "animCam",
      "beta",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const keysBeta: IAnimationKey[] = [];
    keysBeta.push({
      frame: 0,
      value: beta,
    });
    keysBeta.push({
      frame: 100,
      value: Math.PI / 2.5,
    });
    const animCamRadius = new Animation(
      "animCam",
      "radius",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const keysRadius: IAnimationKey[] = [];
    keysRadius.push({
      frame: 0,
      value: radius,
    });
    keysRadius.push({
      frame: 100,
      value: camera.radius,
    });

    const animCamTarget = new Animation(
      "animTarget",
      "_target",
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const keysTarget: IAnimationKey[] = [];
    keysTarget.push({
      frame: 0,
      value: target,
    });
    keysTarget.push({
      frame: 100,
      value: camera.target.clone(),
    });
    animCamAlpha.setKeys(keysAlpha);
    animCamBeta.setKeys(keysBeta);
    animCamRadius.setKeys(keysRadius);
    animCamTarget.setKeys(keysTarget);

    camera.animations.push(animCamAlpha);
    camera.animations.push(animCamBeta);
    camera.animations.push(animCamRadius);
    camera.animations.push(animCamTarget);

    camera.alpha = alpha;
    camera.beta = beta;
    camera.radius = radius;
    camera.target.copyFrom(target);
    this._scene.beginAnimation(camera, 0, 100, false, 2);
  };

  public get camera(): FollowCamera | FreeCamera | ArcRotateCamera {
    return this._camera;
  }

  public get cameraFPS(): FreeCamera {
    return this._cameraFPS;
  }

  public get cameraTPS(): ArcRotateCamera {
    return this._cameraTPS;
  }
  public get cameraTPS2(): FollowCamera {
    return this._cameraTPS2;
  }
  public get cameraMobile(): ArcRotateCamera {
    return this._cameraMobile;
  }
}
