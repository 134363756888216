import { FC } from "react";
import { Route, Routes } from 'react-router-dom';
import { router } from "./constant";

const CustomRoutes: FC = () => {
    return (
        <Routes>
            <Route>
                {router.map((el, i) => (<Route key={i} path={el.path} element={el.component} />))}
            </Route>
        </Routes>
    )
}

export default CustomRoutes;