import styled from 'styled-components';
import { brandColor } from './color';

// export enum ButtonState {
//   Default,
//   Focus,
//   Active,
//   Disable,
// }

export type ButtonState = 'Default' | 'Focus' | 'Active' | 'Disable';

export type ButtonType = 'Primary' | 'Secondary' | 'Tertiary' | 'Social' | 'Icon_Btn';

export interface ButtonProps {
  state: ButtonState;
  buttonType: ButtonType;
  paddingHorizon?: number;
  disable?: boolean;
}

interface SocialButtonProps {
  state?: ButtonState;
}

const getButtonColor = (buttonProps: ButtonProps) => {
  let background = '';
  let boxShadow = '';
  switch (buttonProps.buttonType) {
    case 'Primary':
    case 'Icon_Btn':
      background = brandColor({
        color: 'Primary',
        active: 'Active' === buttonProps.state,
        variation: 'Disable' === buttonProps.state ? 75 : 100,
      });
      if ('Default' === buttonProps.state) {
        boxShadow = 'inset 0px 0px 20px rgba(255, 92, 141, 0.7)';
      } else if ('Disable' === buttonProps.state) {
        boxShadow = '0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7)';
      }
      break;
    case 'Secondary':
      background = 'Disable' === buttonProps.state ? '#FFAEC6' : '#FF5C8D';
      break;
    case 'Tertiary':
      background = '#FFFFFF';
     break;
    default:
      break;
  }
  return `
    background: ${background};
  `;
}



// background: ${(props) => getButtonColor(props)};
// box-shadow: inset 0px 0px 20px rgba(255, 92, 141, 0.7);
export const ButtonFrame = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 10px;

  border-width: 0px;

  ${(props) => getButtonColor(props)}
  border-radius: 32px;
`

export const IconButton = styled.button<Pick<ButtonProps, 'state' | 'paddingHorizon'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  gap: 10px;

  background: ${({state}) => state === 'Disable' ? '#FF85A9' : '#FF5C8D'};
  border: 1px solid #E84878;
  box-shadow: 0px 6px 12px rgba(255, 92, 141, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.35);
  border-radius: 32px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7);
  }
  &:active {
    background: #EB4678;
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7);
  }
`;

export const PrimaryButton = styled.button<Pick<ButtonProps, 'state' | 'paddingHorizon'>>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px ${({paddingHorizon}) => paddingHorizon ? `${paddingHorizon}` : `24`}px;
  gap: 10px;

  background: ${({state}) => state === 'Disable' ? '#FF85A9' : '#FF5C8D'};
  box-shadow: ${({state}) => {
    let boxShadow = '';
    if (state === 'Default') {
      boxShadow = 'inset 0px 0px 20px rgba(255, 92, 141, 0.7)';
    } else if (state === 'Focus' || state === 'Active') {
      boxShadow = '0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7)';
    }
    return boxShadow;
  }};
  &:hover {
    cursor: pointer;
    box-shadow: ${({state}) => state === 'Disable' ? '' : '0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7)'};
  }
  &:active {
    background: #EB4678;
    box-shadow: ${({state}) => state === 'Disable' ? '' : '0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7)'};
  }

  border-radius: 32px;
  border-width: 0px;
`;

export const SecondaryButton = styled.button<Pick<ButtonProps, 'state' | 'paddingHorizon'>>`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px ${({paddingHorizon}) => paddingHorizon ? `${paddingHorizon}` : `24`}px;
  gap: 10px;

  /* TextColor / White */

  background: #FFFFFF;
  /* Primary */

  border: 2px solid  ${({state}) => state === 'Default' ? '#FF5C8D;' : '#FFAEC6;'};
  box-shadow: ${({state}) => state === 'Default' ? 'inset 0px 0px 12px #F1F3F4;' : ''};
  border-radius: 32px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.2), inset 0px 0px 12px #F1F3F4;
  }
  &:active {
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.2), inset 0px 0px 12px #F1F3F4;
  }
`

export const TertiaryButton = styled.button<{state: ButtonState}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 15px 11px;
  gap: 10px;

  background: #FFFFFF;

  border: 1px solid #D8D8D8;
  box-shadow: ${(props) => props.state === 'Disable' ? `` : `inset 0px 2px 3px rgba(255, 255, 255, 0.35)`};
  border-radius: 32px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px 3px 0px #FFFFFF, inset 0px 0px 12px #F1F3F4;
  }
  &:active {
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px -3px 0px #FFFFFF, inset 0px 0px 12px #F1F3F4;
  }
`

export const SocialButtonFrame = styled.button<SocialButtonProps>`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px 3px 0px #FFFFFF, inset 0px 0px 12px #F1F3F4;
  }
  &:active {
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px -3px 0px #FFFFFF, inset 0px 0px 12px #F1F3F4;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 66px;
  gap: 10px;

  width: 380px;
  height: 72px;

  background: ${(props) => props.state === 'Disable' ? `#F1F5F9` : `#FFFFFF`};
  box-shadow: ${(props) => props.state === 'Default' ? '0px 12px 20px rgba(123, 97, 255, 0.05), inset 0px 2px 3px rgba(255, 255, 255, 0.35), inset 0px 0px 12px #F1F3F4' : ''};
  border-radius: 16px;
  border-width: 0px;
`;