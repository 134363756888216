import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getMediaToken, MediaMetaData } from "../api";
import { TextChatRoom } from "./TextChatRoom";
import { LocalParticipant, RemoteParticipant, Room, RoomEvent } from "livekit-client";
import { UsersTextChatContext } from "context/UsersTextChatContext";
import { ChatUser, TModal } from "components/sidebar/living/types";
import { inject, observer } from "mobx-react";

interface ChatFrameProps {
  visible: boolean;
  isMobile: boolean;
}

const ChatFrame = styled.div<ChatFrameProps>`
  width: ${({isMobile}) => isMobile ? '100vw' : '500px'};

  position: absolute;
  z-index: 14;
  bottom: ${({isMobile}) => isMobile ? '0' : '10px'};
  right: -15px;

  display: ${({visible, isMobile}) => isMobile ? (visible ? 'grid' : 'none') : 'grid'};;
  grid-template-rows: auto 70px;
  place-items: center;

  visibility: ${({visible, isMobile}) => isMobile ? visible ? 'display' : 'hidden' : 'display'};
`

interface TextChatProps {
  visible: boolean;
  isMobile: boolean;
  store?: any;
}

export const TextChat = inject('store')(observer((props: TextChatProps) => {
  const {
    visible,
    isMobile,
    store
  } = props;
  const { userStore, sceneStore } = store;
  const {setMe, setRoom, initUsers, onConnected, onDisconnected, onParticipantMetadataChanged} = useContext(UsersTextChatContext);
  const [token, setToken] = useState<string>();

  const extractChatUser = (participant: RemoteParticipant | LocalParticipant) => {
    let mediaMetadata: MediaMetaData = {
      state: "active",
      stateMessage: "",
    }
    if (participant.metadata) {
      const json = JSON.parse(participant.metadata) as MediaMetaData;
      mediaMetadata.state = json.state || "active";
      mediaMetadata.stateMessage = json.stateMessage || "";
    }
    const user: ChatUser = {
      id: participant.identity,
      nickname: participant.name || "unknown",
      state: "active",
      stateMessage: "",
      ...mediaMetadata,
      connectedAt: 0
    }
    return user;
  }

  const onParticipantDisconnected = (remoteParticipant: RemoteParticipant) => {
    onDisconnected(remoteParticipant.identity);
  };

  const onParticipantConnected = (remoteParticipant: RemoteParticipant) => {
    onConnected(extractChatUser(remoteParticipant));
  }

  const onLeave = (room: Room) => {
    room.disconnect(true);
  }

  useEffect(() => {
    if (!userStore.token || !sceneStore.currentScene) return;
    getMediaToken({
      apiUserToken: userStore.token,
      nickname: userStore.user?.name || 'unkown',
      // room: sceneStore.currentScene,
      room: 'text-chat-room',
      canPublish: false,
      canPublishData: true,
      state: "active",
      stateMessage: "",
    }).then(setToken)
    .catch(() => setToken(undefined))
    return () => {
      setToken(undefined);
    }
  },[getMediaToken, userStore.token, userStore.user.name, sceneStore.currentScene])

  return (
    <ChatFrame
      visible={visible}
      isMobile={isMobile}
    >
      {
        token ?
        <TextChatRoom
          isMobile={isMobile}
          token={token}
          url={process.env.REACT_APP_LIVE_KIT_HOST || ''}
          roomOptions={{
            adaptiveStream: true,
            dynacast: true,
            publishDefaults: {
              simulcast: true,
            },
          }}
          onConnected={(room) => {
            setRoom(room);
            const users = Array.from(room.participants.values()).map(extractChatUser);
            initUsers(users);
            setMe(extractChatUser(room.localParticipant));
            room.on(RoomEvent.ParticipantConnected, onParticipantConnected);
            room.on(RoomEvent.ParticipantDisconnected, onParticipantDisconnected);
            room.on(RoomEvent.ParticipantMetadataChanged, onParticipantMetadataChanged);
          }}
          onLeave={onLeave}
          visible={visible}
        />
        : <></>
      }
    </ChatFrame>
  );
}))