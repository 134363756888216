import { MemberTooltip } from "./MemberInPlaceStyled";
import MemberIcon from "components/buttons/svg/MemberIcon";

interface MemberInPlaceProps {
  count: number;
  isMobile: boolean;
}

export const MemberInPlace = (props: MemberInPlaceProps) => {
  const { count, isMobile } = props;
  return (
    <MemberTooltip isMobile={isMobile}>
      <MemberIcon
        width={isMobile ? "8" : "14"}
        height={isMobile ? "9" : "15"}
      />
      <span className="member-amount">{count}</span>
    </MemberTooltip>
  );
};
