import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { inject, observer } from "mobx-react";
import NavigationBar from "pages/main/NavigationBar";
import { useState } from "react";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { ContactUsPageStyled, InputForm, TextAreaStyled } from "./styled";
import GreenCheckIcon from "components/buttons/svg/GreenCheckIcon";

interface IUserInfo {
  email: string;
  companyName: string;
  companyPosition: string;
  title: string;
  message: string;
}

interface IError {
  email: string;
  companyName: string;
  companyPosition: string;
  title: string;
  message: string;
}

const defaultInfo: IUserInfo = {
  email: "",
  companyName: "",
  companyPosition: "",
  title: "",
  message: "",
};

const defaultError: IError = {
  email: "",
  companyName: "",
  companyPosition: "",
  title: "",
  message: "",
};
export const ContactUsPage = inject("store")(
  observer((props: any) => {
    const { store } = props;
    const [isMobile, setIsMobile] = useState<boolean>(
      getDeviceDetect().isMobile
    );
    const [userInfo, setUserInfo] = useState<IUserInfo>(defaultInfo);
    const [error, setError] = useState<IError>(defaultError);
    const [isDirty, setIsDirty] = useState<boolean>(true);
    handleDeviceResize((device) => setIsMobile(device.isMobile));

    const handleValidate = (e, key) => {
      const value = e.target.value;
      setUserInfo((prev) => ({ ...prev, [key]: value }));

      if (value) {
        setError({ ...error, [key]: "" });
      } else {
        setError({ ...error, [key]: "Required value" });
      }
    };

    const handleSubmit = () => {
      Object.keys(userInfo).forEach((key) => {
        if (!userInfo[key]) {
          setError((prev) => ({ ...prev, [key]: "Required value" }));
          setIsDirty(true);
          return;
        }
        setIsDirty(false);
      });
    };
    return (
      <Box height="100vh" overflow="auto">
        <NavigationBar />

        <ContactUsPageStyled isMobile={isMobile}>
          <div className="contact-us-header">
            <span>Contact Us</span>
          </div>
          {isDirty ? (
            <div className="form">
              <InputForm
                isMobile={isMobile}
                onChange={(e) => handleValidate(e, "email")}
                error={error.email}
                helperText={error.email}
                variant="outlined"
                placeholder="Email Address"
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputForm
                    isMobile={isMobile}
                    error={error.companyName}
                    helperText={error.companyName}
                    onChange={(e) => handleValidate(e, "companyName")}
                    fullWidth
                    variant="outlined"
                    placeholder="Company Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputForm
                    isMobile={isMobile}
                    error={error.companyPosition}
                    helperText={error.companyPosition}
                    onChange={(e) => handleValidate(e, "companyPosition")}
                    fullWidth
                    variant="outlined"
                    placeholder="Company Position"
                  />
                </Grid>
              </Grid>
              <InputForm
                isMobile={isMobile}
                error={error.title}
                helperText={error.title}
                onChange={(e) => handleValidate(e, "title")}
                variant="outlined"
                placeholder="Title"
              />
              <TextAreaStyled isMobile={isMobile} error={error.message}>
                <textarea
                  onChange={(e) => handleValidate(e, "message")}
                  placeholder="Leave your message"
                />
                <p className="text-area-error">Required value</p>
              </TextAreaStyled>

              <Box className="submit-btn">
                <Button
                  variant="outlined"
                  children="Submit"
                  onClick={handleSubmit}
                />
              </Box>
            </div>
          ) : (
            <div className="submitted">
              <GreenCheckIcon />
              <div className="submitted-text">
                <p>Your messsage has been submitted</p>
                <p>We sill get back to you</p>
              </div>
            </div>
          )}
        </ContactUsPageStyled>
      </Box>
    );
  })
);
