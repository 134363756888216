import { Box } from "@mui/material";
import styled from "styled-components";

interface IMainWrapper {
  isMobile: boolean;
  mainState: any;
}

interface ILoginModal {
  isMobile: boolean;
}

interface INavBarWrapper {
  isMobile: boolean;
  isLogin: boolean;
  isExpand: boolean;
}

export const MainWrapper = styled.div<IMainWrapper>`
  width: 100%;
  position: relative;
  .button-group {
    position: fixed;
    bottom: ${(props) => (props.isMobile ? "20px" : "70px")};
    right: ${(props) => (props.isMobile ? "20px" : "50px")};
    display: flex;
    flex-direction: column;
    svg {
      cursor: pointer;
    }
  }
  .main {
    width: 100%;
    height: 100vh;
    position: relative;
    background: linear-gradient(
      257.24deg,
      #9adaff 2.42%,
      #b1e3ff 40.7%,
      #fbd1f9 100.4%,
      #def3ff 100.41%
    );
    canvas {
      width: 100%;
      height: 100%;
      outline: none;
    }

    .main-content {
      position: absolute;
      padding: 10px;
      width: ${(props) => (props.isMobile ? "90%" : "40%")};
      top: ${(props) => (props.isMobile ? "15%" : "30%")};
      right: ${(props) => (props.isMobile ? "0" : "5%")};
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: Godo B;
      color: white;
      .text-transition {
        height: max-content !important;
      }
      .intro {
        font-size: ${(props) => (props.isMobile ? "15px" : "20px")};
        margin-bottom: ${(props) => (props.isMobile ? "10px" : "20px")};
      }
      .page-title {
        font-size: ${(props) => (props.isMobile ? "35px" : "70px")};
        font-weight: 600;
        margin-bottom: ${(props) => (props.isMobile ? "10px" : "20px")};
        white-space: nowrap !important;
      }

      .page-describe {
        font-size: ${(props) => (props.isMobile ? "16px" : "23px")};
        white-space: pre !important;
        line-height: 1.5;
        padding-bottom: ${(props) => (props.isMobile ? "20px" : "30px")};
      }

      .action {
        font-size: ${(props) => (props.isMobile ? "15px" : "20px")};
        padding: ${(props) =>
          props.mainState.state === "sub-main" &&
          (props.isMobile ? "13px 21px" : "16px 41px")};
        width: ${(props) =>
          props.mainState.state === "sub-main" && "fit-content"};
        color: ${(props) => props.mainState.state === "sub-main" && "black"};

        background: ${(props) =>
          props.mainState.state === "sub-main" && "white"};
        box-shadow: ${(props) =>
          props.mainState.state === "sub-main" &&
          `4px 1px 10px rgba(0, 0, 0, 0.1),
          inset 0px 2px 3px rgba(255, 255, 255, 0.35),
          inset 0px 0px 12px #f1f3f4`};
        border-radius: ${(props) =>
          props.mainState.state === "sub-main" && "16px"};
        cursor: ${(props) => props.mainState.state === "sub-main" && "pointer"};
      }
    }
  }

  #places {
    overflow: auto;
  }
`;

export const NavBarWrapper = styled(Box)<INavBarWrapper>`
  width: ${(props) => (props.isMobile ? "186px" : "100%")};
  border-radius: ${(props) =>
    props.isMobile && (props.isExpand ? "15px" : "50px")};
  top: ${(props) => props.isMobile && "20px"};
  left: ${(props) => props.isMobile && "20px"};
  height: ${(props) => (props.isMobile ? "fit-content" : "60px")};
  position: fixed;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  background: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: ${(props) => props.isMobile && "10px"};
  .nav-menu {
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    .nav-item {
      cursor: pointer;
    }
  }
  .login-button {
    background: #2e2e2e;
    border-radius: 50px;
    height: ${(props) => (props.isMobile ? "26px" : "40px")};
    width: ${(props) => props.isMobile && "74px"};
    padding: ${(props) =>
      props.isLogin ? "0 11px 0 0" : props.isMobile ? "6px 10px" : "0px 11px"};
    margin: ${(props) => (props.isMobile ? "0 auto" : "0 10px 0 0")};
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1),
      4px 1px 10px rgba(199, 199, 199, 0.25);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    img {
      width: 117px;
      height: 36px;
    }
  }
  .mobile-content {
    margin: 15px 0;
    width: 100%;
    display: ${(props) => (props.isExpand ? "flex" : "none")};
    flex-direction: column;
    gap: 20px;
  }

  .mobile-login {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    img {
      height: 45px;
      width: 45px;
      border-radius: 50%;
    }
  }
`;

export const LoginModal = styled(Box)<ILoginModal>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 4px 1px 10px rgba(0, 0, 0, 0.1),
    4px 1px 10px rgba(199, 199, 199, 0.25),
    inset 0px 2px 3px rgba(255, 255, 255, 0.35);
  border: none;
  border-radius: 30px;
  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
  .login-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: ${(props) =>
      props.isMobile ? "50px 30px" : "53px 75px 110px 75px"};
    gap: 27px;
    .login-logo {
      margin-bottom: 50px;
    }
  }
`;
