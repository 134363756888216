import { SVGIconProps } from "./SVGIconInterface";
import { Refresh, EditNickName } from "./package";

export const SVGIcon = (props: SVGIconProps) => {
  const { name } = props;
  switch (name) {
    case "refresh":
      return <Refresh {...props} />;
    case "edit_nickname":
      return <EditNickName {...props} />;
    default:
      return <div>Default Icon</div>;
  }
};
