import { GoogleLoginButton, NaverLoginButton, TypoGraphy } from "components";
import LogOutIcon from "components/buttons/svg/LogOutIcon";
import { LoginModal, NavBarWrapper } from "./styled";
import MenuIcon from "components/buttons/svg/MenuIcon";
import { useState } from "react";
import CloseIcon from "components/buttons/svg/CloseIcon";
import { Box, Divider, Modal } from "@mui/material";
import { deleteCookie } from "utils/SessionStorageHelper";
import NaverLogin from "views/auth/NaverLogin";
import { properties } from "api-manager/resource/Properties";
import { ApiBaseResponse } from "api-manager/interfaces/FetchInterface";
import {
  LoginWithGoogle,
  LoginWithNaver,
} from "api-manager/services/AuthServices/AuthServices";
import { useGoogleLogin } from "@react-oauth/google";
import { SocialResponse } from "api-manager/services/AuthServices/AuthInterface";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTER } from "routes/constant";

interface INavigationBar {
  store?: any;
}
interface NavigationItem {
  title: string,
  path: string, 
  useAuth: boolean
}
const NavigationBar = inject("store")(
  observer((props: INavigationBar) => {
    const { store } = props;
    const { userStore } = store;
    const [expandMenu, setExpandMenu] = useState(false);

    const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);
    const [active, setActive] = useState(window.location.pathname);

    const navigate = useNavigate();
    const navList: NavigationItem[] = [
      {
        title: "Places",
        path: NAVIGATION_ROUTER.PLACES,
        useAuth: true
      },
      {
        title: "Character",
        path: NAVIGATION_ROUTER.CHARACTER,
        useAuth: true
      },
      {
        title: "Contact Us",
        path: NAVIGATION_ROUTER.CONTACT_US,
        useAuth: false
      },
    ];

    handleDeviceResize((device) => setIsMobile(device.isMobile));
    const setOpenLogin = (open: boolean) => {
      store.modalStore.setOpenLogin(open);
    };
    const handleLoginNaver = async (naverResponse) => {
      const response: ApiBaseResponse = await LoginWithNaver(
        naverResponse.accessToken
      );
      if (response.data.accessToken) {
        const data: SocialResponse = response.data;
        const { accessToken, refreshToken } = data;
        userStore.checkinUserStatus(accessToken);
        userStore.setRefreshToken(refreshToken);
        setOpenLogin(false);
      }
    };

    const handleLoginGoogle = useGoogleLogin({
      onSuccess: async (successResponse) => {
        const response = await LoginWithGoogle(successResponse);
        if (response.data.accessToken) {
          const data: SocialResponse = response.data;
          const { accessToken, refreshToken } = data;
          userStore.checkinUserStatus(accessToken);
          userStore.setRefreshToken(refreshToken);
          setOpenLogin(false);
        }
      },
    });

    const handleLogOut = () => {
      deleteCookie("accessToken");
      deleteCookie("refreshToken");
      userStore.setUser(null);
      navigate(NAVIGATION_ROUTER.DEFAULT);
    };

    const handleOpenLogin = () => {
      setOpenLogin(true);
    };

    const renderLoginButton = () => {
      return (
        <div
          className={
            userStore.user && isMobile ? "mobile-login" : "login-button"
          }
        >
          {!userStore.user ? (
            <div onClick={handleOpenLogin}>
              <TypoGraphy
                typographyType="Default"
                variant={isMobile ? "Caption2" : "Headline1"}
                color="white"
              >
                Log In
              </TypoGraphy>
            </div>
          ) : (
            <>
              <img src={userStore.user.picture} />
              <TypoGraphy
                typographyType="Default"
                variant={isMobile ? "Body2" : "Headline1"}
                color={isMobile ? "black" : "white"}
              >
                {userStore.user.name}
              </TypoGraphy>
              {!isMobile && <LogOutIcon onClick={handleLogOut} />}
            </>
          )}
        </div>
      );
    };
    
    const onSelectNav = (navItem: NavigationItem) => {
      if(navItem.useAuth){
        if (userStore.user ) {
          setActive(navItem.path);
          navigate(navItem.path);
        } else {
          handleOpenLogin();
        }
      }else{
        navigate(navItem.path);
      }
    }

    const renderNavItem = () => {
      return (
        <>
          {navList.map((nav, i) => (
            <div
              className="nav-item"
              key={i}
              onClick={() => {
                // if (userStore.user) {
                //   setActive(nav.path);
                //   navigate(nav.path);
                // } else {
                //   handleOpenLogin();
                // }
                onSelectNav(nav)
              }}
            >
              <TypoGraphy
                hover
                typographyType="Default"
                variant={isMobile ? "Body2" : "Headline1"}
                color={nav.path === active ? "#000000" : "#434343"}
              >
                {nav.title}
              </TypoGraphy>
            </div>
          ))}
          {userStore.user && isMobile && (
            <div className="nav-item" onClick={handleLogOut}>
              <TypoGraphy
                hover
                typographyType="Default"
                variant={isMobile ? "Body2" : "Headline1"}
                color="#434343"
              >
                Log out
              </TypoGraphy>
            </div>
          )}
        </>
      );
    };

    const goToMain = () => {
      navigate(NAVIGATION_ROUTER.DEFAULT);
    };

    return (
      <>
        <NavBarWrapper
          isLogin={userStore.user}
          isMobile={isMobile}
          isExpand={expandMenu}
        >
          {!isMobile ? (
            <>
              <Box className="nav-menu">
                <Box sx={{ cursor: "pointer" }} onClick={goToMain}>
                  {" "}
                  <img
                    src={require("../../assets/img/logos/heart-verse.png")}
                  />
                </Box>
                {renderNavItem()}
              </Box>
              {renderLoginButton()}
            </>
          ) : (
            <>
              <Box className="mobile-header">
                <Box onClick={goToMain}>
                  <img
                    src={require("../../assets/img/logos/heart-verse.png")}
                  />
                </Box>
                <Box onClick={() => setExpandMenu(!expandMenu)}>
                  {expandMenu ? <CloseIcon /> : <MenuIcon />}
                </Box>
              </Box>
              <Box className="mobile-content">
                <Box hidden={!Boolean(userStore.user)}>
                  {" "}
                  {renderLoginButton()}
                  <Divider sx={{ mt: "10px" }} />
                </Box>
                {renderNavItem()}
                <Box hidden={Boolean(userStore.user)}>
                  <Divider sx={{ mb: "10px" }} />
                  {renderLoginButton()}
                </Box>
              </Box>
            </>
          )}
        </NavBarWrapper>
        <Modal
          open={store.modalStore.openLogin}
          onClose={() => setOpenLogin(false)}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <LoginModal isMobile={isMobile}>
            <CloseIcon
              className="close-button"
              onClick={() => setOpenLogin(false)}
            />
            <Box className="login-modal-content">
              <img
                src={require("../../assets/img/logos/heart-verse.png")}
                className="login-logo"
              />
              <GoogleLoginButton callbackFunc={handleLoginGoogle} />

              <NaverLogin
                clientId={properties.NAVER_ID}
                callbackUrl={properties.NAVER_CALLBACK_URL}
                render={(props) => (
                  <NaverLoginButton callbackFunc={props.onClick} />
                )}
                onSuccess={handleLoginNaver}
                onFailure={(error) => console.error(error)}
              />
            </Box>
          </LoginModal>
        </Modal>
      </>
    );
  })
);

export default NavigationBar;
