import { Box } from "@mui/material";
import styled from "styled-components";

interface IExploreSpaceStyled {
  isMobile?: boolean;
}
export const ExploreSpaceStyled = styled(Box)<IExploreSpaceStyled>`
  padding: ${(props) =>
    props.isMobile ? "150px 10px 0 10px" : "75px 100px 0 100px"};
  .places-list {
    display: grid;
    grid-template-columns: ${({ isMobile }) =>
      `repeat(${isMobile ? 1 : 2}, 1fr)`};
    grid-gap: ${({ isMobile }) => (isMobile ? "10px" : "25px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "20px" : "50px")};
  }
  .show-more {
    width: 100%;
    .MuiDivider-wrapper {
      display: flex;
      gap: 10px;
      cursor: pointer;
    }
    .more-text {
      font-size: ${({ isMobile }) => (isMobile ? "15px" : "25px")};
      font-family: "Godo M";
      color: #444444;
    }
    svg {
      width: ${({ isMobile }) => (isMobile ? "20px" : "35px")};
      height: ${({ isMobile }) => (isMobile ? "20px" : "35px")};
    }
  }
`;
