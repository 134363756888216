import {
  AbstractMesh,
  Mesh,
  PBRMaterial,
  Scene,
  SceneLoader,
  Texture,
  Tools,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";
import { getOutfit } from "api-manager/services/CharacterServices/CharacterServices";

import { CustomItem } from "../CharacterManager/interface";
import { DISTANCE_RENDER, DISTANCE_RENDER_LOW_QUALITY } from "../constants";

import {
  characterItemColors,
  characterItems,
  tabData,
} from "pages/character/ShowRoomData";
import { IShowroomTab } from "pages/character/ShowRoomInterface";
import { applyLights } from "../utils/applyLights";
import { LIGHT_COLOR } from "./constant";
export interface Map {
  root: Mesh[];
  allMesh: AbstractMesh[];
}
const characterTotal = 6744148;
const loungeTotal = 47950016;
const livingLabTotal = 28088072;
let characterFileLowQuality: File;
let characterFileHighQuality: File;

export class AssestController {
  public _scene: Scene;
  constructor(scene: Scene) {
    this._scene = scene;
  }

  public loadAssets = async (path: string, filename: string): Promise<any> => {
    return SceneLoader.ImportMeshAsync(null, path, filename).then((result) => {
      const root = result.meshes[0];
      const meshes = result.meshes;
      const response = {
        root: root as Mesh,
        meshes: meshes,
        result: result,
      };
      return response;
    });
  };

  private async loadCharacterFileCache() {
    if (!characterFileLowQuality) {
      // download blob from ./models/character.glb
      const blob = await fetch("./models/character-low.glb").then((r) =>
        r.blob()
      );
      // create a file from blob
      characterFileLowQuality = new File([blob], "character-low.glb", {
        type: "application/octet-stream",
      });
    }
    if (!characterFileHighQuality) {
      // download blob from ./models/character.glb
      const blob = await fetch("./models/character.glb").then((r) => r.blob());
      // create a file from blob
      characterFileHighQuality = new File([blob], "character.glb", {
        type: "application/octet-stream",
      });
    }
  }

  public loadCharacter = async (
    characterName?: string,
    setLoading?,
    setLoadingProgress?
  ): Promise<any> => {
    const items = this.loadCharacterItems();
    // console.log("items", items);

    const outfit = await this.loadCharacterOutfit(items);

    // await this.loadCharacterFileCache();
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "character.glb",
      this._scene,
      (e) => {
        setLoadingProgress &&
          setLoadingProgress((e.loaded / characterTotal) * 100);
      }
    ).then((result) => {
      setLoading && setLoading(false);
      this.loadCharacterAssets(result.meshes);
      // result.meshes[0].name = "character";
      result.meshes.forEach((mesh) => {
        mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
      });
      result.meshes[0].name = characterName || "Unknown";
      return {
        root: result.meshes[0],
        outfit: outfit,
        meshes: result.meshes,
        items: items,
        animationGroups: result.animationGroups,
      };
    });
  };

  public loadCharacterLowQuality = async (rawMeshs: Mesh[]): Promise<any> => {
    await this.loadCharacterFileCache();
    const items = this.loadCharacterItems();
    const outfit = await this.loadCharacterOutfit(items);
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      characterFileLowQuality
    ).then((result) => {
      result.meshes.forEach((mesh) => {
        mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
      });
      rawMeshs.forEach((mesh) => {
        const lowMesh = result.meshes.findIndex((e) => e.name === mesh.name);
        if (lowMesh !== -1) {
          mesh.addLODLevel(
            DISTANCE_RENDER_LOW_QUALITY,
            result.meshes[lowMesh] as Mesh
          );
        } else {
          // console.log("missing: ", mesh.name)
          mesh.addLODLevel(DISTANCE_RENDER_LOW_QUALITY, null);
        }
        mesh.addLODLevel(DISTANCE_RENDER, null);
      });

      this.loadCharacterAssets(result.meshes);
      // result.meshes[0].name = "character";
      return {
        root: result.meshes[0],
        outfit: outfit,
        meshes: result.meshes,
        items: items,
        animationGroups: result.animationGroups,
      };
    });
  };

  public async loadCharacterOutfit(items) {
    const response = await getOutfit();
    if (response.data) {
      const data = this.transformOutfit(response.data, items);
      return data;
    } else return [];
  }

  public transformOutfit = (outfit, items) => {
    const data: CustomItem[] = [];
    if (outfit.hairId) {
      const hairItem = {
        label: outfit.hairId,
        name: outfit.hairId,
        type: "hair",
        color: outfit.hairColor || "",
        colorList: items.find((items) => items.type === "hair")?.data[0]
          .colorList,
      };
      data.push(hairItem);
    }
    if (outfit.faceId) {
      const faceItem = {
        label: outfit.faceId,
        name: outfit.faceId,
        type: "face",
        color: outfit.faceColor || "",
        colorList: items.find((items) => items.type === "face")?.data[0]
          .colorList,
      };
      data.push(faceItem);
    }
    if (outfit.bodyId) {
      const topItem = {
        label: outfit.bodyId,
        name: outfit.bodyId,
        type: "top",
        color: outfit.bodyColor || "",
        colorList: items.find((items) => items.type === "top")?.data[0]
          .colorList,
      };
      data.push(topItem);
    }
    if (outfit.bottomId) {
      const bottomItem = {
        label: outfit.bottomId,
        name: outfit.bottomId,
        type: "bottom",
        color: outfit.bottomColor || "",
        colorList: items.find((items) => items.type === "bottom")?.data[0]
          .colorList,
      };
      data.push(bottomItem);
    }
    if (outfit.shoesId) {
      const shoesItem = {
        label: outfit.shoesId,
        name: outfit.shoesId,
        type: "shoes",
        color: outfit.shoesColor || "",
        colorList: items.find((items) => items.type === "shoes")?.data[0]
          .colorList,
      };
      data.push(shoesItem);
    }
    if (outfit.suitId) {
      const suitItem = {
        label: outfit.suitId,
        name: outfit.suitId,
        type: "suit",
        color: outfit.suitColor || "",
        colorList: items.find((items) => items.type === "suit")?.data[0]
          .colorList,
      };
      data.push(suitItem);
    }
    if (outfit.hatId) {
      const hatItem = {
        label: outfit.hatId,
        name: outfit.hatId,
        type: "hat",
        color: "",
        colorList: [],
      };
      data.push(hatItem);
    }
    if (outfit.earringId) {
      const earringItem = {
        label: outfit.earringId,
        name: outfit.earringId,
        type: "earring",
        color: outfit.earringColor || "",
        colorList: [
          "Earring_Color_01",
          "Earring_Color_02",
          "Earring_Color_03",
          "Earring_Color_04",
          "Earring_Color_05",
        ],
      };
      data.push(earringItem);
    }
    if (outfit.glassId) {
      const glassItem = {
        label: outfit.glassId,
        name: outfit.glassId,
        type: "glasses",
        color: "",
        colorList: [],
      };
      data.push(glassItem);
    }

    if (outfit.otherId) {
      const otherItem = {
        label: outfit.otherId,
        name: outfit.otherId,
        type: "bag",
        color: "",
        colorList: [],
      };
      data.push(otherItem);
    }
    if (outfit.necklaceId) {
      const necklaceItem = {
        label: outfit.necklaceId,
        name: outfit.necklaceId,
        type: "necklace",
        color: "",
        colorList: [],
      };
      data.push(necklaceItem);
    }
    return data;
  };

  public loadLivingLabMap = async (onLoad: any, onProgress): Promise<any> => {
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "livinglab.glb",
      this._scene,
      (e) => {
        onProgress((e.loaded / livingLabTotal) * 100);
      }
    ).then((result) => {
      return SceneLoader.ImportMeshAsync(
        null,
        "./models/",
        "livinglab-low.glb",
        this._scene
      ).then((lowResult) => {
        const root = result.meshes.filter(
          (e) =>
            e.name.toLowerCase().includes("wall") ||
            e.name.toLowerCase().includes("floor_main") ||
            e.name.toLowerCase().includes("flowerpot") ||
            e.name.toLowerCase().includes("cube") ||
            e.name.toLowerCase().includes("desk") ||
            e.name.toLowerCase().includes("table") ||
            e.name.toLowerCase().includes("_skeleton") ||
            e.name === "audit_stairs"
        ) as Mesh[];
        const allMesh = result.meshes;
        // console.log("lowResult.meshes", lowResult.meshes.map((e) => e.name).join("\n"));
        // console.log("result.meshes", result.meshes.map((e) => e.name).join("\n"));
        allMesh.forEach((e) => {
          e.occlusionType = AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
          e.checkCollisions = true;
          const findMesh = lowResult.meshes.find(
            (mesh) => mesh.name === e.name
          );
          if (findMesh) {
            // console.log("found", e.name);
            (e as Mesh).addLODLevel(
              DISTANCE_RENDER_LOW_QUALITY,
              findMesh as Mesh
            );
          } else {
            // console.log("not found", e.name);
          }
        });
        allMesh.filter(
          (e) => e.name.toLowerCase().includes("door") && e.setEnabled(false)
        );
        applyLights(allMesh, LIGHT_COLOR, this._scene);
        const livingMap: Map = {
          root: root,
          allMesh: allMesh,
        };
        onLoad(false);
        return livingMap;
      });
    });
  };

  public loadLounge = async (onLoad: any, onProgress): Promise<any> => {
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "lounge.glb",
      this._scene,
      (e) => {
        onProgress((e.loaded / loungeTotal) * 100);
      }
    ).then((result) => {
      const root = result.meshes.filter((e) =>
        e.name == 'skeleton_floor' ||
        e.name === 'stairs' ||
        e.name.toLowerCase().includes("wall")
      ) as Mesh[];
      const allMesh = result.meshes;
      const map: Map = {
        root: root,
        allMesh: allMesh,
      };
      onLoad(false);
      return map;
    });
  };

  public loadGwanghwamun = async (onLoad: any, onProgress): Promise<any> => {
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "gwanghwamun.glb",
      this._scene,
      (e) => {
        onProgress((e.loaded / loungeTotal) * 100);
      }
    ).then((result) => {
      const root = result.meshes.filter((e) => e.name === "skeleton") as Mesh[];
      const allMesh = result.meshes;
      applyLights(allMesh, LIGHT_COLOR, this._scene);
      const map: Map = {
        root: root,
        allMesh: allMesh,
      };
      onLoad(false);
      return map;
    });
  };

  public loadAliceGarden = async (onLoad: any, onProgress): Promise<any> => {
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "alice-garden.glb",
      this._scene,
      (e) => {
        onProgress((e.loaded / loungeTotal) * 100);
      }
    ).then((result) => {
      const root = result.meshes.filter(
        (e) => e.name === "skeleton"
      ) as Mesh[];
      const allMesh = result.meshes;
      applyLights(allMesh, LIGHT_COLOR, this._scene);
      const map: Map = {
        root: root,
        allMesh: allMesh,
      };
      onLoad(false);
      return map;
    });
  };

  public loadCharacterItems = () => {
    return tabData.map((el) => {
      const type = el.title;

      return {
        ...el,
        data: this.getTabData(el),
      };
    });
  };

  private getTabData = (tab: IShowroomTab) => {
    if (tab.subTab.length > 0) {
      return tab?.subTab?.map((sub) => {
        return {
          type: sub,
          label: sub,
          data: this.filterData(sub),
        };
      });
    } else {
      return this.filterData(tab.title);
    }
  };

  private filterData = (type: string) => {
    return characterItems
      .filter((items) => items.type === type)
      .map((items) => {
        return {
          ...items,
          color: "",
          colorList: characterItemColors.find((color) => color.type === type)
            ?.skin,
        };
      });
  };

  public loadCharacterAssets = (meshes) => {
    meshes.forEach((mesh, i) => {
      if (i) {
        const texture = this.loadTexture(mesh.name);
        const material = this.loadMaterial(mesh.name);
        material.albedoTexture = texture;
        mesh.material = material;
      }
    });
    this.loadCostumeSkin();
  };

  public loadTexture(meshName: string) {
    const texture = new Texture(
      require(`../../../assets/img/character/textures/${meshName}.png`)
    );

    texture.name = meshName;
    texture.uAng = Tools.ToRadians(180);
    return texture;
  }

  public loadMaterial(meshName: string) {
    const material = new PBRMaterial(meshName);
    material.roughness = 1;
    material.metallic = 0;
    return material;
  }

  public loadCostumeSkin() {
    characterItemColors.map((color) => {
      color.skin.map((el) => {
        const skinTexture = new Texture(
          require(`../../../assets/img/character/textures/Char_${el}.png`)
        );
        skinTexture.name = `Char_${el}`;
        skinTexture.uAng = Tools.ToRadians(180);
      });
    });
  }
}
