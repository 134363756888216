import {
  AbstractMesh,
  ArcRotateCamera,
  Color4,
  Engine,
  HemisphericLight,
  PointerEventTypes,
  Scene,
  SceneLoader,
  Vector3,
  Animation,
  IAnimationKey,
  Axis,
  Space,
} from "@babylonjs/core";
import { autorun } from "mobx";
import { Inspector } from "pages/metaverse/utils/Inspector";
import { MetaverseStore } from "stores/MetaverseStore";
import { handleDeviceResize } from "utils/WindowDimensions";

const total = 12540012;
const zoomValue = [10, 9.8, 9.6, 9.4, 9.2, 9, 8.8, 8.6, 8.4, 8.2, 8];
const zoomValueMobile = [
  14, 13.8, 13.6, 13.4, 13.2, 13, 12.8, 12.6, 12.4, 12.2, 12,
];

export default class ModelMainPage {
  public _canvas: HTMLCanvasElement;
  public _engine: Engine;
  public _scene: Scene;
  public _camera: ArcRotateCamera;
  public _light: HemisphericLight;
  public _model!: AbstractMesh;
  private zoomLevel: number = 0;
  private minZoom: number = 0;
  private maxZoom: number = 10;
  private minRadius: number = 8;
  private maxRadius: number = 10;

  private zoomIncrease: number = 1;
  private _store: MetaverseStore;
  constructor(canvas: HTMLCanvasElement, store: MetaverseStore) {
    this._canvas = canvas;
    this._store = store;
    this._engine = new Engine(this._canvas, true, undefined, true);
    this._scene = new Scene(this._engine);
    this._camera = new ArcRotateCamera(
      "LandingPage Camera",
      Math.PI / 2,
      Math.PI / 3.5,
      (this._store.device.isMobile ? zoomValueMobile : zoomValue)[0],
      new Vector3(0, 0, 0),
      this._scene
    );
    this._light = new HemisphericLight(
      "light",
      new Vector3(1, 1, 0),
      this._scene
    );
    Inspector(this._scene);
    this.init();
    autorun(() => {
      this.zoomLevel = this._store.sceneStore.zoom;
      console.log("zoom level ", this._store.sceneStore.zoom);
      this.updateCameraRadius();
    });
    this.onScroll();
  }

  public async init() {
    await this.loadModel();
    this._scene.clearColor = Color4.FromHexString("");
    this._scene.activeCamera = this._camera;
    // this._scene.activeCamera.attachControl(this._canvas, true);
    this._engine.runRenderLoop(() => this.renderScene());
    handleDeviceResize((device) => {
      this._model.position.x = device.isMobile ? 0 : 3;
      this._model.position.y = !device.isMobile ? 0 : -3.5;
      this._engine.resize();
    });
  }

  private renderScene = () => {
    this._scene.render();
  };

  public async loadModel() {
    return SceneLoader.ImportMeshAsync(
      null,
      "./models/",
      "main-model.glb",
      this._scene
    ).then((result) => {
      const model = result.meshes[0];
      model.position.x = this._store.device.isMobile ? 0 : 3;
      model.position.y = !this._store.device.isMobile ? 0 : -3.5;

      model.scaling.scaleInPlace(0.2);
      this._model = model;
    });
  }

  public onScroll() {
    let lastPointerX = 0;
    let lastPointerY = 0;
    let isPointerDown = false;
    this._scene.onPointerObservable.add((pointerInfo) => {
      const event = pointerInfo.event as WheelEvent;
      switch (pointerInfo.type) {
        // case PointerEventTypes.POINTERWHEEL:
        //   const delta = Math.sign(event.deltaY);
        //   this.zoomLevel += delta;
        //   this.onWheel(event);
        //   break;
        case PointerEventTypes.POINTERDOWN:
          lastPointerX = event.clientX;
          lastPointerY = event.clientY;
          isPointerDown = true;
          break;
        case PointerEventTypes.POINTERUP:
          isPointerDown = false;
          break;
        case PointerEventTypes.POINTERMOVE:
          if (isPointerDown) {
            const event = pointerInfo.event as PointerEvent;
            const deltaX = event.clientX - lastPointerX;
            this.rotateModel(deltaX);
            lastPointerX = event.clientX;
          }
          break;
        default:
          break;
      }
    });
  }

  private rotateModel(deltaX: number) {
    const rotationSpeed = -0.005;
    this._model.rotate(Axis.Y, deltaX * rotationSpeed, Space.LOCAL);
  }

  private onWheel(event: WheelEvent) {
    const delta = Math.sign(event.deltaY);
    const zoomSpeed = 0.1;
    const currentRadius = this._camera.radius;
    const targetRadius = Math.max(
      this.minRadius,
      Math.min(this.maxRadius, currentRadius * (1 - delta * zoomSpeed))
    );
    const animCamRadius = new Animation(
      "animCam",
      "radius",
      60,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const keysRadius: IAnimationKey[] = [];
    keysRadius.push({
      frame: 0,
      value: currentRadius,
    });
    keysRadius.push({
      frame: 60,
      value: targetRadius,
    });
    animCamRadius.setKeys(keysRadius);
    this._camera.animations.push(animCamRadius);
    this._scene.beginAnimation(this._camera, 0, 60, false, 2);
  }
  private updateCameraRadius() {
    // const totalZoomLevels = 10; // total number of zoom levels
    // const zoomIncrement = (this.maxRadius - this.minRadius) / totalZoomLevels; // calculate zoom increment based on total number of zoom levels
    // const adjustedMaxRadius = this.maxRadius - this.zoomLevel * zoomIncrement;
    // const targetRadius = Math.max(
    //   this.minRadius,
    //   Math.min(
    //     adjustedMaxRadius,
    //     adjustedMaxRadius - this._camera.radius + this.minRadius
    //   )
    // );
    const targetRadius = (
      this._store.device.isMobile ? zoomValueMobile : zoomValue
    )[this._store.sceneStore.zoom];
    const animCamRadius = new Animation(
      "animCam",
      "radius",
      60,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );
    const keysRadius: IAnimationKey[] = [];
    keysRadius.push({
      frame: 0,
      value: this._camera.radius,
    });
    keysRadius.push({
      frame: 60,
      value: targetRadius,
    });
    animCamRadius.setKeys(keysRadius);
    this._camera.animations.push(animCamRadius);
    this._camera.radius = targetRadius;
    this._scene.beginAnimation(this._camera, 0, 60, false, 2);
  }
}
