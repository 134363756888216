import React from "react";

function GreenCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="#0A8817"
        d="M25.8 35.4l-6.45-6.45c-.55-.55-1.25-.825-2.1-.825-.85 0-1.55.275-2.1.825-.55.55-.825 1.25-.825 2.1 0 .85.275 1.55.825 2.1l8.55 8.55c.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9l16.95-16.95c.55-.55.825-1.25.825-2.1 0-.85-.275-1.55-.825-2.1-.55-.55-1.25-.825-2.1-.825-.85 0-1.55.275-2.1.825L25.8 35.4zM30 60c-4.15 0-8.05-.788-11.7-2.364-3.65-1.576-6.825-3.713-9.525-6.411-2.7-2.7-4.837-5.875-6.411-9.525C.79 38.05.002 34.15 0 30c0-4.15.788-8.05 2.364-11.7 1.576-3.65 3.713-6.825 6.411-9.525 2.7-2.7 5.875-4.837 9.525-6.411C21.95.79 25.85.002 30 0c4.15 0 8.05.788 11.7 2.364 3.65 1.576 6.825 3.713 9.525 6.411 2.7 2.7 4.838 5.875 6.414 9.525C59.215 21.95 60.002 25.85 60 30c0 4.15-.788 8.05-2.364 11.7-1.576 3.65-3.713 6.825-6.411 9.525-2.7 2.7-5.875 4.838-9.525 6.414C38.05 59.215 34.15 60.002 30 60z"
      ></path>
    </svg>
  );
}

export default GreenCheckIcon;
