import { useState } from "react";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { PlaceContent } from "./PlaceDescriptionStyled";

interface PlaceDescriptionProps {
    title: string;
    description: string;
  }
  
export const PlaceDescription = (props: PlaceDescriptionProps) => {
    const { title, description } = props;
    const [isMobile, setIsMobile] = useState<boolean>(getDeviceDetect().isMobile);
    handleDeviceResize((device) => setIsMobile(device.isMobile));
  
    return (
      <PlaceContent isMobile={isMobile}>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </PlaceContent>
    );
  };