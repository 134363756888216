import { useState, useEffect } from "react";
const audioUrl = require('assets/audio/gen_item_get.mp3');

type TReturn = [() => void, () => void]

export const useAudio = (url?: string): TReturn => {
  const [audio] = useState(new Audio(url || audioUrl));
  const [playing, setPlaying] = useState(false);

  // const toggle = () => setPlaying(!playing);
  const toggleAudio = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  const play = () => audio.play();

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [play, toggleAudio];
};