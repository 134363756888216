import { configure, makeAutoObservable } from "mobx";
import { date } from "mobx-sync";
import { MovementStore } from "./MovementStore";
import { UserStore } from "./UserStore";
import { SceneStore } from "./SceneStore";
import { Properties } from "api-manager/resource/PropertyInterface";
import { properties } from "api-manager/resource/Properties";
import { ModalStore } from "./ModalStore";
import { DeviceDetectInterface, getDeviceDetect } from "utils/WindowDimensions";

configure({ enforceActions: "observed" });

export class MetaverseStore {
  @date createdAt: Date = new Date();
  private _isLoading: boolean = false;
  private _userStore: UserStore;
  private _movementStore: MovementStore;
  private _sceneStore: SceneStore;
  private _modalStore: ModalStore;
  private _appProperties: Properties = properties;
  private _device: DeviceDetectInterface;
  constructor() {
    this._userStore = new UserStore();
    this._movementStore = new MovementStore();
    this._sceneStore = new SceneStore()
    this._modalStore = new ModalStore()
    this._device = getDeviceDetect()
    this.handleWindowResize()
    makeAutoObservable(this);
  }

  get userStore(): UserStore {
    return this._userStore;
  }
  get movementStore(): MovementStore {
    return this._movementStore;
  }
  get sceneStore(): SceneStore {
    return this._sceneStore;
  }
  get modalStore(): ModalStore {
    return this._modalStore;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get device(): DeviceDetectInterface{
    return this._device
  }

  public setIsLoading(state: boolean): void {
    this._isLoading = state;
  }

  private handleWindowResize(): void {
    //resize if the screen is resized/rotated
    window.addEventListener("resize", () => {
      this._device = getDeviceDetect();
    });
  }
}
