import { TModal } from "components/sidebar/living/types";
import { makeAutoObservable, observable } from "mobx";

export class ModalStore {
  @observable private _modal: TModal | null = null;
  @observable private _openLogin: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get openLogin() {
    return this._openLogin;
  }

  get modal() {
    return this._modal;
  }

  public setModal(modal: TModal) {
    this._modal === modal ? this.closeModal() : (this._modal = modal);
  }

  public closeModal() {
    this._modal = null;
  }

  public setOpenLogin(open: boolean) {
    this._openLogin = open;
  }
}
