import { properties } from "api-manager/resource/Properties";
import { getData, postData, postFormData, patchData } from "api-manager/utils/Fetch";
import { ApiBaseResponse } from "api-manager/interfaces/FetchInterface";

export const getUserProfile = async (): Promise<ApiBaseResponse> => {
  const response = await getData(properties.ENDPOINTS.endpoint_user_profile.url);
  return response
}

export const saveOutfit = async (payload: any): Promise<ApiBaseResponse> => {
  const response = await postData(properties.ENDPOINTS.endpoint_costume.url, payload);
  return response
};

export const getOutfit = async (): Promise<ApiBaseResponse> => {
  const response = await getData(properties.ENDPOINTS.endpoint_costume.url);
  return response;
};

export const uploadAvatar = async (payload: FormData): Promise<ApiBaseResponse> => {
  const response = await postFormData(properties.ENDPOINTS.endpoint_upload_avatar.url, payload);
  return response;
}

export const updateNickname = async (payload: any): Promise<ApiBaseResponse> => {
  const response = await patchData(properties.ENDPOINTS.endpoint_user_profile.url, payload);
  return response;
}

export const updateNicknameWithImage = async (payload: any): Promise<ApiBaseResponse> => {
  const formdata = new FormData();
  formdata.append('file', payload.file)
  const uploadAvatarResponse = await uploadAvatar(formdata);
  if(!uploadAvatarResponse.message){
    const updateNicknamePayload = { name: payload.name, key: uploadAvatarResponse.data }
    const updateNicknameResponse = await updateNickname(updateNicknamePayload)
    return updateNicknameResponse
  }
  return uploadAvatarResponse
}