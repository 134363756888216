import { ICharacterInfo, IPlayerInfo, IPlayerMove } from "../CharacterManager/interface";
import { CharacterInfoState } from "../Network/Schema/CharacterInfoState";
import { PlayerInfoState } from "../Network/Schema/PlayerInfoState";
import { PlayerMoveState } from "../Network/Schema/PlayerMoveState";

export function mapCharacterInfo(data: CharacterInfoState): ICharacterInfo {
  const character: ICharacterInfo = {
    sessionId: data.sessionId,
    id: data.id,
    skinId: data.skinId,
    skinColor: data.skinColor,
    hairId: data.hairId,
    hairColor: data.hairColor,
    faceId: data.faceId,
    faceColor: data.faceColor,
    bodyId: data.bodyId,
    bodyColor: data.bodyColor,
    bottomId: data.bottomId,
    bottomColor: data.bottomColor,
    shoesId: data.shoesId,
    shoesColor: data.shoesColor,
    suitId: data.suitId,
    suitColor: data.suitColor,
    glassId: data.glassId,
    glassColor: data.glassColor,
    hatId: data.hatId,
    hatColor: data.hatColor,
    earringId: data.earringId,
    earringColor: data.earringColor,
    otherId: data.otherId,
    necklaceId: data.necklaceId,
  };
  return character;
}

export function mapPlayerInfo(data: PlayerInfoState): IPlayerInfo {
  const player: IPlayerInfo = {
    sessionId: data.sessionId,
    id: data.id,
    name: data.name,
    role: data.role,
    nickname: data.nickname,
  };
  return player;
}

export function mapPlayerMovement(data: PlayerMoveState): IPlayerMove {
  const player: IPlayerMove = {
    sessionId: data.sessionId,
    x: data.x,
    y: data.y,
    z: data.z,
    vx: data.vx,
    vy: data.vy,
    vz: data.vz,
    vw: data.vw,
    seq: data.seq,
    rXCamera: data.rXCamera,
    rYCamera: data.rYCamera,
    rZCamera: data.rZCamera,
    vXCamera: data.vXCamera,
    vYCamera: data.vYCamera,
    vZCamera: data.vZCamera,
    vWCamera: data.vWCamera,
  };
  return player;
}
