import { Vector3 } from "@babylonjs/core";
export const scrollLimit = 10;

export const mainLimit = 4;
export const subMainLimit = 9;
export const placesLimit = 10;

export const mainStart = 0;
export const subMainStart = 7;
export const placesStart = 10;

export const MAIN_PAGE = {
  MAIN: "main",
  SUB_MAIN: "sub-main",
  PLACES: "places",
};
export const zoomVector = [
  new Vector3(0, 4, 9),
  new Vector3(5.5, 2, 9),
  new Vector3(5.5, 2, 8),
  new Vector3(5.5, 2, 7),
  new Vector3(5.5, 2, 6),
  new Vector3(5.5, 2, 5),
  new Vector3(5.5, 2, 4),
  new Vector3(5.5, 2, 3),
  new Vector3(5.5, 3, 3),
  new Vector3(5.5, 4, 2),
  new Vector3(5.5, 5, 2),
];

export const mainPageState = [
  {
    state: MAIN_PAGE.MAIN,
    scrollLimit: mainLimit,
    scrollStart: mainStart,
    content: {
      intro: "Into a new world",
      title: "Heartverse",
      describe: `“A special space created by Heartverse
Let’s go on a journey into a new world”`,
      actions: "↓Scroll to learn more",
    },
  },
  {
    state: MAIN_PAGE.SUB_MAIN,
    scrollLimit: subMainLimit,
    scrollStart: subMainStart,
    content: {
      intro: "",
      title: `Exploring Metaverse`,
      describe: `“You can explore various metaverse spaces and communicate 
with your friends. Start right now!”`,
      mobileDescribe: `“You can explore various metaverse spaces 
and communicate with your friends.
Start right now!”`,
      actions: "Get started now > ",
    },
  },
  {
    state: MAIN_PAGE.PLACES,
    scrollLimit: placesLimit,
    scrollStart: placesStart,
  },
];
