import { SVGIconProps } from "../SVGIconInterface";

const EditNickName = (props: SVGIconProps) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <mask
        id="mask0_66_3667"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <path
          d="M11.6854 8.99588C13.6216 8.99588 15.1912 7.4263 15.1912 5.49013C15.1912 3.55395 13.6216 1.98438 11.6854 1.98438C9.74927 1.98438 8.17969 3.55395 8.17969 5.49013C8.17969 7.4263 9.74927 8.99588 11.6854 8.99588Z"
          fill="white"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.66895 20.5151C1.66895 16.0893 5.70507 12.502 10.6837 12.502"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.1908 21.0154L20.199 16.0072L18.1957 14.0039L13.1875 19.0121V21.0154H15.1908Z"
          fill="white"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </mask>
      <g mask="url(#mask0_66_3667)">
        <path
          d="M-0.334961 -0.0185547H23.7045V24.0209H-0.334961V-0.0185547Z"
          fill="#AF4922"
        />
      </g>
    </svg>
  );
};

export default EditNickName;
