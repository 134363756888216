function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#D1D1D1"
        d="M22.78 23.844c1.79-.974 2.865-2.226 2.865-3.59 0-1.444-1.208-2.764-3.194-3.762-2.296-1.154-5.634-1.882-9.349-1.882s-7.053.728-9.35 1.882C1.768 17.49.56 18.81.56 20.254c0 1.445 1.207 2.765 3.193 3.763 2.296 1.154 5.634 1.882 9.35 1.882 3.896 0 7.377-.8 9.677-2.055z"
        opacity="0.5"
      ></path>
      <path
        fill="#D1D1D1"
        fillRule="evenodd"
        d="M4.322 8.985c0-4.513 3.931-8.172 8.78-8.172 4.849 0 8.78 3.66 8.78 8.172 0 4.478-2.802 9.702-7.175 11.57a4.11 4.11 0 01-3.21 0c-4.373-1.868-7.175-7.094-7.175-11.57zm8.78 3.117a2.509 2.509 0 100-5.018 2.509 2.509 0 000 5.018z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LocationIcon;