import iconCheck from "assets/img/character/check-icon.png";
import { MetaverseButton } from "components/buttons/common/MetaverseButton";
import { XButton } from "components/buttons/common/XButton";
import { Loading } from "components/loading/Loading";
import { inject, observer } from "mobx-react";
import NavigationBar from "pages/main/NavigationBar";
import { useEffect, useRef, useState } from "react";
import {
  DeviceDetectInterface,
  getDeviceDetect,
  handleDeviceResize,
} from "utils/WindowDimensions";
import Showroom from "./ShowRoom";
import { characterItemColors, characterItems, tabData } from "./ShowRoomData";
import { IShowroomItem, IShowroomTab, TAB } from "./ShowRoomInterface";
import {
  CheckedPosition,
  CloseBtnWrapper,
  CostumeColor,
  CostumeItem,
  NicknameInputWrapper,
  NicknameSettingContainer,
  NicknameStyled,
  NicknameTitleWrap,
  OptionSection,
  OutfitContainer,
  SaveBtnWrapper,
  ShowRoomCanvas,
  ShowroomWrapper,
  SkinPicker,
  SubTab,
  SubTabList,
  Tab,
  TabAction,
  TabContent,
  TabList,
  TabWrapper,
} from "./ShowRoomStyled";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Box } from "@mui/material";
import { NAVIGATION_ROUTER } from "routes/constant";
import { useNavigate } from "react-router-dom";

export const ShowroomContainer = inject("store")(
  observer((props: any) => {
    const navigate = useNavigate();
    const _ref = useRef<HTMLCanvasElement>(null);
    const { store } = props;
    const { userStore } = store;
    const { isNewUser, isShowChangeName } = userStore;
    const characterController = store?.userStore?.characterController;
    const [showRoomModel, setShowRoomModel] = useState<Showroom>();
    const [selectedTab, setSelectedTab] = useState<IShowroomTab>(tabData[0]);
    const [selectedSubTab, setSelectedSubTab] = useState<string>("");

    const [activeItem, setActiveItem] = useState<IShowroomItem | null>(null);
    const [selectedItems, setSelectedItems] = useState<IShowroomItem[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [device, setDevice] = useState<DeviceDetectInterface>(
      getDeviceDetect()
    );
    handleDeviceResize((device) => setDevice(device));
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);

    const onSelectTab = (tab) => {
      setSelectedTab(tab);
      !device.isMobile &&
        showRoomModel &&
        showRoomModel.characterZoom(tab.zoom);
      if (tab.subTab.length > 0) {
        onSelectSubTab(tab.subTab[0]);
      } else {
        const active = selectedItems.find((el) => el.type === tab.title);
        if (active) setActiveItem(active);
        else setActiveItem(null);
      }
    };

    const onSelectSubTab = (subTab) => {
      setSelectedSubTab(subTab);
      const active = selectedItems.find((el) => el.type === subTab);
      if (active) setActiveItem(active);
      else setActiveItem(null);
    };

    const handleSetOutfit = (outfit) => {
      characterController && characterController.setOutfit(outfit);
    };

    const handleSelectCostume = (item) => {
      setActiveItem(item);
      const outfit = validateOutfit(item);
      setSelectedItems(outfit);
      handleSetOutfit(outfit);
    };

    const validateOutfit = (item) => {
      let newList: IShowroomItem[] = [...selectedItems];
      if (selectedItems?.find((el) => el.name === item.name)) {
        setActiveItem(null);
        newList = newList.filter((el) => el.name !== item.name);
      } else {
        const type = selectedTab.subTab.length
          ? selectedSubTab
          : selectedTab.title;
        if (newList?.find((el) => el?.type === type)) {
          newList = newList.map((el) => {
            if (el?.type === type) {
              const newItem = {
                ...item,
                type: type,
              };
              return newItem;
            } else return el;
          });
        } else {
          newList = [...newList, item];
        }
      }

      if (item.type === TAB.SUIT) {
        newList = newList.filter(
          (el) => !(el.type === TAB.TOP || el.type === TAB.BOTTOM)
        );
      }

      if (item.type === TAB.TOP || item.type === TAB.BOTTOM) {
        newList = newList.filter((el) => el.type !== TAB.SUIT);
      }
      return newList;
    };

    const resetOutfit = () => {
      handleSetOutfit([]);
      setSelectedItems([]);
      setActiveItem(null);
    };

    const renderSubTab = () => {
      return (
        <OptionSection show={selectedTab.subTab.length > 0}>
          {selectedTab.subTab.length > 0 && (
            <SubTabList isMobile={device?.isMobile}>
              {selectedTab.subTab.map((sub) => (
                <SubTab
                  onClick={() => onSelectSubTab(sub)}
                  active={sub === selectedSubTab}
                  isMobile={device?.isMobile}
                >
                  {sub}
                </SubTab>
              ))}
            </SubTabList>
          )}
        </OptionSection>
      );
    };

    const renderColorPicker = () => {
      const colorList = characterItemColors.find(
        (el) =>
          el.type ===
          (selectedTab.subTab.length ? selectedSubTab : selectedTab.title)
      );
      return (
        <SkinPicker show={activeItem && colorList} isMobile={device?.isMobile}>
          <Box className="skin-picker">
            <Box className="skin-container">
              {colorList?.skin.map((color) => (
                <CostumeColor
                  isMobile={device.isMobile}
                  onClick={() => handleColorClick(color)}
                >
                  <img
                    className="costume-color"
                    src={require(`../../assets/img/character/textures/Char_${color}.png`)}
                  />
                  {activeItem && activeItem.color === color && (
                    <img className="check-icon" src={iconCheck} alt="" />
                  )}
                </CostumeColor>
              ))}
            </Box>
          </Box>
        </SkinPicker>
      );
    };

    const handleColorClick = (color) => {
      const newList = selectedItems.map((item) => {
        if (item.name === activeItem?.name) {
          const newActiveItem = {
            ...item,
            color: color,
          };
          setActiveItem(newActiveItem);

          return newActiveItem;
        } else return item;
      });

      setSelectedItems(newList);
      handleSetOutfit(newList);
    };

    useEffect(() => {
      const { current: canvas } = _ref;
      if (!canvas) return;
      const showroom = new Showroom(
        canvas,
        store,
        setLoading,
        setLoadingProgress
      );
      setShowRoomModel(showroom);
    }, [store]);

    useEffect(() => {
      if (characterController) {
        setSelectedItems(characterController._outfit);
      }
    }, [characterController]);

    useEffect(() => {
      if (userStore.user.name && inputRef.current) {
        inputRef.current.value = userStore.user.name;
      }
    }, [userStore.user]);

    const renderChangeNameButton = !isNewUser && (
      <NicknameStyled isMobile={device?.isMobile}>
        <MetaverseButton
          onClick={() => {
            showRoomModel &&
              showRoomModel.characterZoom(new Vector3(-7.8, 4.5, 5));
            userStore.setShowChangeName(true);
          }}
          icon="edit_nickname"
          type={"icon"}
          variant={"contained"}
          color={"default"}
        />
      </NicknameStyled>
    );

    return (
      <Loading loading={loading} progress={loadingProgress}>
        <NavigationBar />
        <ShowRoomCanvas ref={_ref} id="showroom-canvas" />

        {!isShowChangeName ? (
          <>
            {renderChangeNameButton}
            <ShowroomWrapper isMobile={device?.isMobile}>
              <TabWrapper isMobile={device?.isMobile}>
                <TabList isMobile={device?.isMobile}>
                  {tabData.map((tab) => (
                    <Tab
                      onClick={() => onSelectTab(tab)}
                      active={selectedTab.title === tab.title}
                      isMobile={device?.isMobile}
                    >
                      {tab.title}
                    </Tab>
                  ))}
                </TabList>
                {renderSubTab()}
                {renderColorPicker()}
                <TabContent isMobile={device?.isMobile}>
                  <OutfitContainer isMobile={device?.isMobile}>
                    {characterItems
                      .filter(
                        (items) =>
                          items.type ===
                          (selectedTab.subTab.length
                            ? selectedSubTab
                            : selectedTab.title)
                      )
                      .map((el) => (
                        <CostumeItem onClick={() => handleSelectCostume(el)}>
                          <img
                            src={require(`../../assets/img/character/costume_image/${el.name}.png`)}
                            alt=""
                          />
                          {activeItem && activeItem.name === el.name && (
                            <CheckedPosition>
                              <img src={iconCheck} alt="" />
                            </CheckedPosition>
                          )}
                        </CostumeItem>
                      ))}
                  </OutfitContainer>
                </TabContent>
                <TabAction>
                  <MetaverseButton
                    onClick={() => resetOutfit()}
                    icon="refresh"
                    type={"icon"}
                    variant={"contained"}
                    color={"primary"}
                  />
                  <MetaverseButton
                    onClick={() => {
                      characterController && characterController.saveOutfit()
                      if (isNewUser) {
                        userStore.setShowChangeName(true);
                      }
                    }}
                    label="save"
                    type={"text"}
                    variant={"contained"}
                    color={"default"}
                  />
                </TabAction>
              </TabWrapper>
            </ShowroomWrapper>
          </>
        ) : (
          <NicknameSettingContainer>
            <NicknameTitleWrap isMobile={device?.isMobile}>
              <XButton
                onClick={() => userStore.setShowChangeName(false)}
                fontSize={device?.isMobile ? 30 : 45}
                color="#FFFFFF"
              />
              Nickname Setting
            </NicknameTitleWrap>
            <NicknameInputWrapper isMobile={device?.isMobile}>
              <input ref={inputRef} placeholder="Enter your nickname" />
            </NicknameInputWrapper>
            <SaveBtnWrapper isMobile={device?.isMobile}>
              <MetaverseButton
                onClick={() => {
                  characterController && characterController.saveNickname(inputRef.current?.value || new Date().getTime().toString(), () => {
                    userStore.updateUser({name: inputRef.current?.value || new Date().getTime().toString()})
                    userStore.setIsNewUser(false);
                    userStore.setShowChangeName(false);
                    navigate(NAVIGATION_ROUTER.PLACES);
                  })
                }}
                label="save"
                type={"text"}
                variant={"contained"}
                color={"primary"}
              />
            </SaveBtnWrapper>
          </NicknameSettingContainer>
        )}
      </Loading>
    );
  })
);
