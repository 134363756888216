import { Nullable } from "@babylonjs/core";
import { action, makeAutoObservable, observable } from "mobx";
import { NetworkHandle } from "pages/metaverse/Network/NetworkHandle";
import { SceneState } from "pages/metaverse/SceneManager/SceneInterface";

export class SceneStore {
  private _sceneAllowMedia: SceneState[];
  private _currentScene: Nullable<SceneState> = null;
  private _lastMap: Nullable<SceneState> = null;
  private _isAllowMedia: boolean = false;
  private _isReRender: boolean = false;
  private _network: Nullable<NetworkHandle> = null;

  @observable private zoomLevel: number = 0;
  constructor() {
    this._sceneAllowMedia = [
      SceneState.LOUNGE,
      SceneState.INTERNET_CAFE,
      SceneState.PALACE,
      SceneState.ALICE,
    ];
    makeAutoObservable(this);
  }

  get lastMap(): Nullable<SceneState> {
    return this._lastMap;
  }

  get currentScene(): Nullable<SceneState> {
    return this._currentScene;
  }

  get isAllowMedia(): boolean {
    return this._currentScene
      ? this._sceneAllowMedia.includes(this._currentScene)
      : false;
  }

  get isReRender(): boolean {
    return this._isReRender;
  }

  get network(): Nullable<NetworkHandle> {
    return this._network;
  }

  public setNetwork(network: NetworkHandle) {
    this._network = network;
  }

  public setCurrentScene(scene: Nullable<SceneState>) {
    console.count("setCurrentScene calling");
    this._currentScene = scene;
  }

  public setLastScene(scene: SceneState) {
    this._lastMap = scene;
  }

  public runReRender() {
    this._isReRender = true;
  }

  public stopReRender() {
    this._isReRender = false;
  }
  
  @action
  public setZoom(level: number){
    this.zoomLevel = level
  }

  get zoom(){
    return this.zoomLevel
  }
}
