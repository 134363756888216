export enum TAB {
  SUIT = "suit",
  FACE = "face",
  HAIR = "hair",
  TOP = "top",
  BOTTOM = "bottom",
  SHOES = "shoes",
  ACC = "acc",
  HAT = "hat",
  EARRING = "earring",
  GLASSES = "glasses",
  BAG = "bag",
  NECKLACE = "necklace",
}

export interface IShowroomItem {
  label: string;
  name: string;
  type: string;
  color: string;
  colorList: string[];
}

export interface IShowroomTab {
  title: string;
  subTab: string[];
}
