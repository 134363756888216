import React from "react";

function MemberIcon(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "14"}
      height={height || "15"}
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        fill="#fff"
        d="M7.302.79c.86 0 1.687.357 2.296.992.608.635.95 1.496.95 2.393 0 .898-.342 1.759-.95 2.393a3.181 3.181 0 01-2.296.991 3.181 3.181 0 01-2.296-.99 3.459 3.459 0 01-.95-2.394c0-.897.341-1.758.95-2.393A3.181 3.181 0 017.302.791zm0 8.462c3.587 0 6.493 1.514 6.493 3.384v1.692H.81v-1.692c0-1.87 2.905-3.384 6.493-3.384z"
      ></path>
    </svg>
  );
}

export default MemberIcon;
