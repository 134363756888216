import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import styled from "styled-components";

const IconOnlyButtonWrap = styled.div`
  width: ${({ isMobile }) => (isMobile ? "56.47px" : "61.46px")};
  height: ${({ isMobile }) => (isMobile ? "56.47px" : "61.46px")};

  display: flex;
  justify-content: center;
  align-items: center;

  background: #3c37307d;
  border-radius: 50%;
  cursor: pointer;

  & > {
    width: ${({ isMobile }) => (isMobile ? "24px" : "25px")};
    height: ${({ isMobile }) => (isMobile ? "24px" : "25px")};
  }

  &:hover {
    background: #F8FFDCE5;
  }
`;

interface IconOnlyButtonProps {
  isMobile: boolean;
  icon: ReactJSXElement;
  onClick: () => void;
}

export const IconOnlyButton = (btnProps: IconOnlyButtonProps) => {
  const { isMobile, icon, onClick} = btnProps;

  return <IconOnlyButtonWrap isMobile={isMobile} onClick={onClick}>{icon}</IconOnlyButtonWrap>;
};
