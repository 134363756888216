import React from "react";

function ExpandMoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#585858"
        d="M16.887 22.137a.84.84 0 00.613.263.84.84 0 00.613-.262l4.9-4.9c.291-.292.364-.613.218-.963s-.423-.525-.831-.525h-9.8c-.408 0-.685.175-.831.525-.146.35-.073.67.219.963l4.9 4.9zM17.5 35c-2.42 0-4.696-.46-6.825-1.379-2.13-.92-3.981-2.166-5.556-3.74-1.575-1.575-2.822-3.427-3.74-5.556C.461 22.195.001 19.921 0 17.5c0-2.42.46-4.696 1.379-6.825.92-2.13 2.166-3.981 3.74-5.556 1.575-1.575 3.427-2.822 5.556-3.74C12.805.461 15.079.001 17.5 0c2.42 0 4.696.46 6.825 1.379 2.13.92 3.981 2.166 5.556 3.74 1.575 1.575 2.822 3.427 3.742 5.556.92 2.13 1.378 4.404 1.377 6.825 0 2.42-.46 4.696-1.379 6.825-.92 2.13-2.166 3.981-3.74 5.556-1.575 1.575-3.427 2.822-5.556 3.742-2.13.92-4.404 1.378-6.825 1.377zm0-3.5c3.88 0 7.183-1.363 9.91-4.09 2.728-2.726 4.091-6.03 4.09-9.91 0-3.88-1.363-7.183-4.09-9.91-2.726-2.728-6.03-4.091-9.91-4.09-3.88 0-7.183 1.363-9.91 4.09-2.728 2.726-4.091 6.03-4.09 9.91 0 3.88 1.363 7.183 4.09 9.91 2.726 2.728 6.03 4.091 9.91 4.09z"
      ></path>
    </svg>
  );
}

export default ExpandMoreIcon;
