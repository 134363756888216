import { Center } from "components";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";

export const Wrapper = styled(Center)`
  width: 100%;
  height: 100%;
  background: rgba(186, 226, 248, 0.75);
`;

export const ImgWrapper = styled.div`
  position: relative;
  height: ${() => (isMobile ? "290px" : "460px")};
`;

export const commonImgStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BackgroundImg = styled.img`
  ${commonImgStyle}
  ${() => (isMobile ? "width: 250px" : "")}
`;
export const HerotoroImg = styled.img`
  ${commonImgStyle}
  ${() => (isMobile ? "width: 250px" : "")}
`;
