import { TypoGraphy } from "components/typography";
import styled from "styled-components"
import {
  thickArrow
} from "assets/img/icon/v1/index"

const ButtonViewFrame = styled.div<{minimode: boolean, isMobile: boolean}>`
  display: grid;
  width: 100%;
  height: ${({isMobile}) => isMobile ? '50px' : '60px'};

  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #F1F5F9;
  border-radius: ${({minimode}) => minimode ? '25px' : '13px'};
  padding: 0px ${({isMobile}) => isMobile ? '0px' : '10px'};

  &:hover {
    cursor: pointer;
  }
`

const ButtonFrame = styled.div<{minimode: boolean, isMobile: boolean}>`
  display: grid;
  ${({minimode, isMobile}) => {
    if (minimode) {
      return `
        place-items: center;
      `
    } else {
      return `
        place-items: center start;
        grid-template-columns: 22px auto ${isMobile ? '0px' : '8px'};
        padding: 0px ${isMobile ? '0px' : '20px'};
        margin-left: ${isMobile ? '10px' : '0px'};
      `
    }
  }}

  gap: 0px 10px;

  &:hover {
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px 3px 0px #FFFFFF, inset 0px 0px 12px #F1F3F4;
  }
`

const ButtonTitleFrame = styled.div<{isMobile: boolean}>`
  padding: 0px ${({isMobile}) => isMobile ? '0px' : '20px'};
`

interface SideItemProps {
  title: string;
  icon: string;
  onClick: () => void;
  minimode: boolean;
  isMobile: boolean;
}

export const SideItem: React.FC<SideItemProps> = ({
  title,
  icon,
  onClick,
  minimode,
  isMobile
}) => {

  return (
    <ButtonViewFrame
      count={1}
      minimode={minimode}
      isMobile={isMobile}
    >
      <ButtonFrame
        onClick={onClick}
        minimode={minimode}
        isMobile={isMobile}
      >
        <img width={ isMobile ? 18 : 22}  src={icon} alt="" />
        {
          minimode ? <></>
          : <ButtonTitleFrame
              isMobile={isMobile}
            >
              <TypoGraphy
                variant={isMobile ? "Caption1" : "Body2"}
                color="#5F5F5F"
              >
                {title}
              </TypoGraphy>
            </ButtonTitleFrame>
        }
        {
          !isMobile ? <img src={thickArrow} alt="" width={7}/> : <></>
        }
      </ButtonFrame>
    </ButtonViewFrame>
  )
}