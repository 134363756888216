import React from "react";

function MenuIcon(props) {
  const { className, onClick } = props;

  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      fill="none"
      viewBox="0 0 19 15"
    >
      <path
        fill="#3A3A3A"
        d="M0 15v-2.5h19V15H0zm0-6.25v-2.5h19v2.5H0zM0 2.5V0h19v2.5H0z"
      ></path>
    </svg>
  );
}

export default MenuIcon;
