import {
  Mesh,
  Scene,
  Vector3,
  AbstractMesh,
  ArcRotateCamera,
} from "@babylonjs/core";
import { Room } from "colyseus.js/lib/Room";
import { UserVideoChatWay } from "context/UserVideoChatContext";
import { IPlayerChangeGroup } from "types";
import { ICharacterInfo, IJoinedRoom } from "../CharacterManager/interface";
import { mapCharacterInfo } from "../utils/gameServer";
import { mapPlayerMovement, mapPlayerInfo } from "../utils/mapper";
import Network from "./Network";
import { Queue } from "./Queue";
import { PlayerMoveState } from "./Schema/PlayerMoveState";
import { PlayerStates } from "./Schema/PlayerStates";
import { CharacterModel } from "../CharacterManager/Character";

import { CAMERA_TYPE } from "../CameraManager/CameraController";
import { MouseController } from "../MouseManager/MouseController";
import { OtherPlayerController } from "../OtherPlayer/OtherPlayerController";
import { PlayerMouseMoveState } from "./Schema/PlayerMouseMoveState";
import { MetaverseStore } from "stores/MetaverseStore";
import { SceneState } from "../SceneManager/SceneInterface";

export interface IMoveData {
  x: number;
  y: number;
  z: number;
  tX: number;
  tY: number;
  tZ: number;
  tW: number;
}

export interface IMouseMove {
  positionX: number;
  positionY: number;
  positionZ: number;
  type: string;
  vX: number;
  vY: number;
  direction: string;
  distance: number;
  rotateX: number;
  rotateY: number;
  angle: number;
  seq: number;
}

export type FCallBackMouseMove = (IMouseMove) => void;
export type FCallBackMove = (IMoveData) => void;
export type FCallBackAction = (string) => void;

export interface IHandlePlayerJoined {
  client: IJoinedRoom;
  scene: Scene;
  isLeave?: boolean;
  basePlayer?: any;
  mergeMesh?: Mesh;
  otherPlayers: any;
}

export class NetworkHandle {
  public otherPlayers: any;
  public room: Room<PlayerStates> | null = null;

  private token: string;
  public playerModel: CharacterModel;
  public camera;
  public store: MetaverseStore;
  public scene: Scene;
  public mergeMesh: Mesh;
  private seq: number = 0;
  private seqMouseMove: number = 0;
  private handleQueueJoined: Queue<IHandlePlayerJoined>;
  private _onChangeUserSpace: (type: UserVideoChatWay, data: string) => void;
  private _onChangeScene: () => void;
  private map: SceneState;
  private startPoint: Vector3;
  constructor(
    playerModel: CharacterModel,
    camera: ArcRotateCamera,
    store: MetaverseStore,
    token: string,
    scene: Scene,
    mergeMesh: Mesh,
    _onChangeUserSpace: (type: UserVideoChatWay, data: string) => void,
    _onChangeScene: () => void,
    map: SceneState,
    startPoint: Vector3
  ) {
    this.startPoint = startPoint;
    this.map = map;
    this.otherPlayers = [];
    this.token = token;
    this.scene = scene;
    this.mergeMesh = mergeMesh;
    this._onChangeUserSpace = _onChangeUserSpace;
    this.playerModel = playerModel;
    this.camera = camera;
    this.store = store;
    this._onChangeScene = _onChangeScene;
    this.handleQueueJoined = new Queue<IHandlePlayerJoined>(
      this.handlePlayerJoinedAndLeave
    );
    this.handleQueueJoined.runQueue();
  }
  public async init() {
    this.room = await Network.joinOrCreate<PlayerStates>(
      this.map,
      {
        token: this.token,
        data: {
          x: this.startPoint.x,
          y: this.startPoint.y,
          z: this.startPoint.z,
          vx: this.startPoint.x,
          vy: this.startPoint.y,
          vz: this.startPoint.z,
          vw: 0,
          rXCamera: 0,
          rYCamera: 0,
          rZCamera: 0,
          vXCamera: 0,
          vYCamera: 0,
          vZCamera: 0,
          vWCamera: 0,
          seq: 0,
        },
      }
    );
    this.room.onMessage("joined", async (client: IJoinedRoom) => {
      const data: IHandlePlayerJoined = {
        client,
        scene: this.scene,
        basePlayer: this.playerModel,
        mergeMesh: this.mergeMesh,
        otherPlayers: this.otherPlayers,
      };
      this.handleQueueJoined.enqueue(data);
    });
    this.room.onMessage("left", (client: string) => {
      const joinedData: IJoinedRoom = {
        movement: {
          sessionId: client,
          x: 0,
          y: 0,
          z: 0,
          vx: 0,
          vy: 0,
          vz: 0,
          vw: 0,
          seq: 0,
          rXCamera: 0,
          rYCamera: 0,
          rZCamera: 0,
          vXCamera: 0,
          vYCamera: 0,
          vZCamera: 0,
          vWCamera: 0,
        },
        info: {
          sessionId: client,
          name: "",
          id: "",
          role: "",
          nickname: "",
        },
        character: {
          sessionId: client,
          id: "",
          skinId: "",
          hairId: "",
          hairColor: "",
          faceId: "",
          faceColor: "",
          skinColor: "",
          bodyId: "",
          bodyColor: "",
          bottomId: "",
          bottomColor: "",
          shoesId: "",
          shoesColor: "",
          suitId: "",
          suitColor: "",
          glassId: "",
          glassColor: "",
          hatId: "",
          hatColor: "",
          earringId: "",
          earringColor: "",
          otherId: "",
          necklaceId: "",
        },
      };
      const data: IHandlePlayerJoined = {
        client: joinedData,
        scene: this.scene,
        isLeave: true,
        basePlayer: this.playerModel,
        mergeMesh: this.mergeMesh,
        otherPlayers: this.otherPlayers,
      };
      this.otherPlayers = this.otherPlayers.filter(
        (el) => el.player.info.sessionId !== client
      );
      this.handleQueueJoined.enqueue(data);
    });
    this.room.onMessage("move", (client: PlayerMoveState) => {
      const otherPlayer = this.otherPlayers.find(
        (el) => el.player.info.sessionId === client?.sessionId
      );
      if (otherPlayer) {
        otherPlayer.controller?.usePointerDown();
        // correct position
        otherPlayer.controller?.correctPosition(
          new Vector3(client.x, client.y, client.z)
        );
        otherPlayer.controller?.moveTo(
          new Vector3(client.vx, client.vy, client.vz),
          client.vw
        );
      }
    });
    this.room.onMessage("mouse-move", (client: PlayerMouseMoveState) => {
      const otherPlayer = this.otherPlayers.find(
        (el) => el.player.info.sessionId === client.sessionId
      );
      if (otherPlayer) {
        otherPlayer.controller?.useJoyStick();
        // correct position
        if (client.type === "STOP") {
          otherPlayer.controller?.correctPosition(
            new Vector3(client.positionX, client.positionY, client.positionZ)
          );
          return;
        }
        otherPlayer.controller?.mouseMove({
          positionX: client.positionX,
          positionY: client.positionY,
          positionZ: client.positionZ,
          type: client.type,
          vX: client.vX,
          vY: client.vY,
          direction: client.direction,
          distance: client.distance,
          rotateX: client.rotateX,
          rotateY: client.rotateY,
          angle: client.angle,
          seq: client.seq,
        });
      }
    });
    this.room.onMessage(
      "action",
      (data: { sessionId: string; action: string }) => {
        const otherPlayer = this.otherPlayers.find(
          (el) => el.player.info.sessionId === data.sessionId
        );

        if (otherPlayer) {
          switch (data.action) {
            case "jump":
              otherPlayer.controller?.jumpTo();
              break;
            default:
              break;
          }
          // otherPlayer.controller?.action();
        }
      }
    );
    this.room.onMessage("regroup", (message: IPlayerChangeGroup) => {
      this.store.userStore.setCurUserGroup(message.newGroupId);
      // this._onChangeUserSpace("Group", message.newGroupId);
    });
    this.room.onMessage("changed-costume", (message: ICharacterInfo) => {
      const otherPlayer = this.otherPlayers.find(
        (el) => el.player.info.sessionId === message.sessionId
      );
      if (otherPlayer) {
        otherPlayer.characterController?.loadCharacterOutfitFromServer(message);
        otherPlayer.characterLowController?.loadCharacterOutfitFromServer(
          message
        );
      }
    });
    this.room.onStateChange.once(() => {
      if (this.room !== null) {
        this.room.state.playerInfoState.forEach((player) => {
          if (this.room === null) return;

          const movementRaw = this.room.state.playerMoves.find(
            (move) => move.sessionId === player.sessionId
          );
          if (!movementRaw) return;
          const characterRaw = this.room.state.characterInfoState.find(
            (c) => c.sessionId === player.sessionId
          );
          if (!characterRaw) return;
          const client: IJoinedRoom = {
            movement: mapPlayerMovement(movementRaw),
            info: mapPlayerInfo(player),
            character: mapCharacterInfo(characterRaw),
          };

          if (player.sessionId === this.room.sessionId) {
            this.createCurrentPlayer(client);
            return;
          }

          const data: IHandlePlayerJoined = {
            client,
            scene: this.scene,
            basePlayer: this.playerModel,
            mergeMesh: this.mergeMesh,
            otherPlayers: this.otherPlayers,
          };
          this.handleQueueJoined.enqueue(data);
          return;
        });
      }
    });
  }

  private async handlePlayerJoinedAndLeave({
    client,
    scene,
    isLeave = false,
    basePlayer,
    mergeMesh,
    otherPlayers,
  }: IHandlePlayerJoined) {
    if (isLeave) {
      // dispose mesh with client.info.sessionId
      scene.getMeshById(client.info.sessionId)?.dispose(undefined, true);
      scene
        .getTransformNodeById(client.info.sessionId)
        ?.dispose(undefined, true);

      if (basePlayer) {
        // delete player in Map() by key client.info.sessionId
        basePlayer.getPlayers.player.delete(client.info.sessionId);
      }
      if(otherPlayers) {
        const index = otherPlayers.findIndex(
          (el) => el.player.info.sessionId === client.info.sessionId
        );
        otherPlayers.splice(index, 1);
      }

      return;
    }
    if (basePlayer && mergeMesh) {
      basePlayer.cloneCharacter(client);
      const { getPlayers } = basePlayer;

      const thisPlayer = getPlayers.player.get(client.info.sessionId)
        .rootNodes[0];

      const otherPlayerController = new OtherPlayerController(
        scene,
        thisPlayer,
        mergeMesh,
        new Vector3(client.movement.vx, client.movement.vy, client.movement.vz)
      );
      otherPlayers.push({
        player: client,
        controller: otherPlayerController,
      });
    }
  }

  public getCallBackMove(): FCallBackMove {
    return (moveData: IMoveData) => {
      this.seq++;
      const sendData = {
        x: moveData.x,
        y: moveData.y,
        z: moveData.z,
        vx: moveData.tX,
        vy: moveData.tY,
        vz: moveData.tZ,
        vw: moveData.tW,
        seq: this.seq,
        rXCamera: 0,
        rYCamera: 0,
        rZCamera: 0,
        vXCamera: 0,
        vYCamera: 0,
        vZCamera: 0,
        vWCamera: 0,
      };
      if (this.room) {
        this.room.send("move", sendData);
      }
    };
  }

  public getCallBackMouseMove(): FCallBackMouseMove {
    return (moveData: IMouseMove) => {
      this.seqMouseMove++;
      const sendData = {
        positionX: moveData.positionX,
        positionY: moveData.positionY,
        positionZ: moveData.positionZ,
        type: moveData.type,
        vX: moveData.vX,
        vY: moveData.vY,
        direction: moveData.direction,
        distance: moveData.distance,
        rotateX: moveData.rotateX,
        rotateY: moveData.rotateY,
        angle: moveData.angle,
        seq: this.seqMouseMove,
      };
      if (this.room) {
        this.room.send("mouse-move", sendData);
      }
    };
  }

  public getCallBackAction(): FCallBackAction {
    return (action: string) => {
      if (this.room) {
        this.room.send("action", action);
      }
    };
  }

  public leaveRoom() {
    if (this.room) {
      this.room.leave();
    }
  }

  public createCurrentPlayer(client: IJoinedRoom) {
    this.playerModel.cloneCharacter(client);
    this.scene.setActiveCameraById(CAMERA_TYPE.MOBILE);
    this.scene.activeCamera = this.camera;
    const { getPlayers } = this.playerModel;
    const currentPlayer = getPlayers.player.get(client.info.sessionId)
      .rootNodes[0];
    MouseController(
      this.scene,
      currentPlayer as AbstractMesh,
      this.mergeMesh,
      this.camera,
      this.getCallBackMove(),
      this.getCallBackAction(),
      this.getCallBackMouseMove(),
      this._onChangeUserSpace,
      this.store,
      this._onChangeScene,
      this.startPoint
    ).init();
  }

  public renamePlayerItems(playerID) {
    const currentUser = this.scene.getMeshByName(playerID);
    if (currentUser) {
      const allItems = currentUser.getChildMeshes();
      allItems.forEach((item) => {
        const newID = item.name.replace("Clone of ", playerID + "_");
        item.id = newID;
        item.name = newID;
      });
    }
  }
}
