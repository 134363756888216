import { CommonStyleProps } from "components/chat/types";
import styled from "styled-components";

export const VideoChatFrame = styled.div<CommonStyleProps>`
  position: absolute;
  ${({isMobile}) => {
    if (isMobile) {
      return `
        top: 20px;
        right: 15px;
      `
    } else {
      return `
        top: 40px;
        right: 35px;
      `
    }
  }}

  padding: 5px;

  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  visibility: ${({visible}) => visible ? 'display' : 'hidden'};
  opacity: ${({visible}) => visible ? '1' : '0'};
`

export const UsersViewFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 5px;
`

export const UserViewFrame = styled.div<Pick<CommonStyleProps,'isMobile'>>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  
  ${({isMobile}) => {
    if (isMobile) {
      return `
        width: 120px;
        height: 92.61px;
        gap: 5px 0px;
      `  
    } else {
      return `
        width: 228.8px;
        height: 171.6px;
        gap: 15px 0px;
      `
    }
  }}

  background: #5F5F5F;
  border-radius: 15px;
`
export const LocalStream = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: fill;

  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
  z-index: 2;
`

export const VideoControlFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 70px;
  gap: 0px 30px;
  width: 100%;
`

export const VideoControlItemsFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 30px;
`

export const ItemFrame = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: 30px auto;
  gap: 2px 0px;
  &:hover {
    cursor: pointer;
  }
`