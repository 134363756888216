import { createContext, useEffect, useState } from "react";
import { Props } from "./types";

type TMedia = 'audio' | 'mic' | 'video';

type TMedias = {
  [keyof in TMedia]: boolean
}

export type Resoultion = 'h720' | 'h360';


export interface ISetting {
  video:boolean;
  audio: boolean;
  mic: boolean;
  autoVideoConnection: boolean;
  resoultion: Resoultion;
  onChangeResoultion: (resoultion: Resoultion) => void;
  handleAutoVideoConnection: (flag: boolean) => void;
  handleMedia: (type: TMedia, active: boolean) => void;
  onChangeDevice: (type: MediaDeviceKind, device: MediaDeviceInfo | InputDeviceInfo) => void;
  micDevice?: MediaDeviceInfo;
  audioDevice?: MediaDeviceInfo;
  cameraDevice?: MediaDeviceInfo;
}

export const UserSettingContext = createContext<ISetting>({
  video: true,
  audio: true,
  mic: true,
  resoultion: 'h720',
  autoVideoConnection: true,
  handleAutoVideoConnection: (flag) => {},
  handleMedia: () => {},
  onChangeDevice(type, device) {},
  onChangeResoultion(resoultion) {},
});

export const UserSettingProvider = ({ children }: Props): JSX.Element => {
  const [mediaActive, setMediaActive] = useState<TMedias>({
    video: true,
    audio: true,
    mic: true,
  });
  const [resoultion, setResoultion] = useState<Resoultion>('h720');
  const [micDevice, setMicDevice] = useState<InputDeviceInfo>();
  const [cameraDevice, setCameraDevice] = useState<InputDeviceInfo>();

  const [ autoVideoConnection, setAutoVideoConnection ] = useState<boolean>(true);

  const onChangeDevice = (type: MediaDeviceKind, device: MediaDeviceInfo | InputDeviceInfo) => {
    switch(type) {
      case "audioinput":
        setMicDevice(device);
        break;
      case "videoinput":
        setCameraDevice(device);
        break;
    }
  }

  const onChangeResoultion = (resoultion: Resoultion) => {
    setResoultion(resoultion);
  }

  const handleMedia = (type: TMedia, active: boolean) => {
    setMediaActive({
      ...mediaActive,
      [type]: active,
    })
  }

  const initDevices = () => {
    if(navigator.userAgent.indexOf('Firefox') > -1){
      navigator.mediaDevices.getUserMedia({audio: true, video: true}).then((stream) => {
        setDevices();
      })
    } else setDevices();
  }

  const setDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log(devices,'devices');
      
      const mics = devices.filter((device) => device.kind === 'audioinput');
      const videos = devices.filter((device) => device.kind === 'videoinput');

      let selectedMic = mics[0];
      if(mics.length > 1 && selectedMic.deviceId === 'default') {
        selectedMic = mics[1];
      }

      let selectedVideo = videos[0];
      if(videos.length > 1 && selectedVideo.deviceId === 'default') {
        selectedVideo = videos[1];
      }
      setMicDevice(selectedMic);
      setCameraDevice(selectedVideo);
    })
  }

  const handleAutoVideoConnection = (flag: boolean) => setAutoVideoConnection(flag);

  useEffect(() => {
    initDevices();
    navigator.mediaDevices.addEventListener('devicechange', initDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', initDevices);
    }
  }, [])
  

  return (
    <UserSettingContext.Provider value={{
      ...mediaActive,
      handleMedia,
      autoVideoConnection,
      handleAutoVideoConnection,
      cameraDevice,
      micDevice,
      onChangeDevice,
      resoultion,
      onChangeResoultion
    }}>
      {children}
    </UserSettingContext.Provider>
  )
}
