import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { check } from "assets/img/icon/v1/index";
import { UserChatState } from "../types";
import { TypoGraphy } from "components/typography";
import { LegacyButton } from "components";
import { UsersTextChatContext } from "context/UsersTextChatContext";
import { MediaMetaData, updateParticipant } from "components/chat/api";
import { useAudio } from "hooks/videochat/useAudio";
import { inject, observer } from "mobx-react";
import { CommonStyleProps } from "components/chat/types";

const ModalFrame = styled.div<CommonStyleProps>`
  position: absolute;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
  border-radius: 30px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        width: 300px;
        height: 288px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 20px 0px;
        padding: 20px 0px;
      `;
    } else {
      return `
        width: 360px;
        height: 328px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 30px 0px;
        padding: 30px 0px;
      `;
    }
  }}
  display: flex;
  flex-direction: column;

  z-index: 1;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;

const TitleFrame = styled.div`
  display: grid;
  place-items: center;
`;

const StateListFrame = styled.div`
  height: 150px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0px 30px;
`;

const StateViewFrame = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  height: 40px;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }
`;
const StateItemFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 0px 10px;
`;

const State = styled.div<{ state: UserChatState }>`
  width: 16px;
  height: 16px;
  background: ${({ state }) => {
    if (state === "meeting") return "#644BE6";
    else if (state === "disabled") return "#979797";
    else return "#82E6C8";
  }};
  border-radius: 8px;
`;

const StateCheckFrame = styled.div`
  display: grid;
  place-items: center;
`;

const ButtonFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 30px;
`;

interface IUpdateStateModal extends CommonStyleProps {
  onClose: () => void;
  store?: any;
}

export const UpdateStateModal = inject("store")(
  observer((props: IUpdateStateModal) => {
    const { visible, isMobile, onClose, store } = props;
    const { userStore } = store;
    const { room } = useContext(UsersTextChatContext);
    const [checked, setChecked] = useState<UserChatState>("disabled");
    const [play] = useAudio();

    const onSubmit = () => {
      if (!userStore.token) return;
      if (room) {
        updateParticipant(userStore.token, room.name, {
          state: checked,
          stateMessage: userStore.user.stateMessage || "",
        })
          .then((response) => {
            const metaData = JSON.parse(response.metadata) as MediaMetaData;
            userStore.updateUser(metaData);
            onClose();
          })
          .catch(() => onClose());
      }
    };

    useEffect(() => {
      if (!visible) setChecked(userStore.user.state);
    }, [visible]);

    useEffect(() => {
      if (userStore.user.state) setChecked(userStore.user.state);
    }, [userStore.user.state]);

    return (
      <ModalFrame visible={visible} isMobile={isMobile}>
        <TitleFrame>
          <TypoGraphy variant="Headline2" color="#5F5F5F">
            Status
          </TypoGraphy>
        </TitleFrame>
        <StateListFrame>
          <StateViewFrame onClick={() => setChecked("active")}>
            <StateItemFrame>
              <State state={"active"} />
              <TypoGraphy variant="Body1" color="#000000">
                Online
              </TypoGraphy>
            </StateItemFrame>
            <StateCheckFrame>
              {checked === "active" ? <img src={check} alt="" /> : <></>}
            </StateCheckFrame>
          </StateViewFrame>
          <StateViewFrame onClick={() => setChecked("meeting")}>
            <StateItemFrame>
              <State state={"meeting"} />
              <TypoGraphy variant="Body1" color="#000000">
                Meeting
              </TypoGraphy>
            </StateItemFrame>
            <StateCheckFrame>
              {checked === "meeting" ? <img src={check} alt="" /> : <></>}
            </StateCheckFrame>
          </StateViewFrame>
          <StateViewFrame onClick={() => setChecked("disabled")}>
            <StateItemFrame>
              <State state={"disabled"} />
              <TypoGraphy variant="Body1" color="#000000">
                Offline
              </TypoGraphy>
            </StateItemFrame>
            <StateCheckFrame>
              {checked === "disabled" ? <img src={check} alt="" /> : <></>}
            </StateCheckFrame>
          </StateViewFrame>
        </StateListFrame>
        <ButtonFrame>
          <LegacyButton
            buttonType="Secondary"
            state="Default"
            paddingHorizon={isMobile ? 30 : 50}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </LegacyButton>
          <LegacyButton
            buttonType="Primary"
            state="Default"
            paddingHorizon={isMobile ? 30 : 50}
            onClick={() => {
              play();
              onSubmit();
            }}
          >
            Apply
          </LegacyButton>
        </ButtonFrame>
      </ModalFrame>
    );
  })
);
