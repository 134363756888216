import { SyncTrunk } from "mobx-sync";
import { MetaverseStore } from "stores/MetaverseStore";

export const initStore = (): MetaverseStore => {
  const store = new MetaverseStore();
  const trunk = mobxSyncCreateTrunk(store, "MetaverseStore");
  mobxSyncClearTrunk(trunk);
  mobxSyncInitTrunk(trunk);
  return store
};

export const mobxSyncCreateTrunk = (
  store: any,
  storageKey: string
): SyncTrunk => {
  return new SyncTrunk(store, {
    storage: sessionStorage,
    storageKey: storageKey,
  });
};

export const mobxSyncInitTrunk = (trunk: SyncTrunk): void => {
  trunk.init();
};

export const mobxSyncClearTrunk = (trunk: SyncTrunk): void => {
  trunk.clear();
};

export const setCookie = (name: string, value: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000); // Set it expire in 7 days
  document.cookie =
    name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
};

export const getCookie = (name: string) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  return (parts.length === 2 && parts.pop()?.split(";").shift()) || undefined;
};

export const deleteCookie = (name: string) => {
  const date = new Date();
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000); // Set it expire in -1 days
  document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
};
