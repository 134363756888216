import { Endpoints } from "./Endpoints";
import { Properties } from "./PropertyInterface";

const GoogleClientID = '287308265337-2614qsph1ig44osjs3grqro27b9r7tq3.apps.googleusercontent.com'
// const GoogleClientID ='596778306398-bt8ocovlpsu3b1ugg1o89gcpo0037lu6.apps.googleusercontent.com';
const NAVER_ID_SDK_URL = 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js';
const NAVER_CLIENT_ID = 'gttN3VxXPFH5FZ_covlb'
const NAVER_CALLBACK_URL = window.location.origin + '/login'

const properties: Properties = {
  ENDPOINTS: Endpoints,
  GOOGLE: GoogleClientID,
  NAVER_SDK: NAVER_ID_SDK_URL,
  NAVER_ID: NAVER_CLIENT_ID,
  NAVER_CALLBACK_URL: NAVER_CALLBACK_URL
};

export { properties };