import {
  blue_people,
  info,
  logout as logoutImage,
  setting,
} from "assets/img/icon/v1/index";
import { useAudio } from "hooks/videochat/useAudio";
import styled from "styled-components";
import { ISideBar } from "../SideBar";
import { SideItem } from "../SideItem";
import { TModal } from "../types";

const SideBarButtonsFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px 0px;
`;

interface SideBarButtonsProps extends Pick<ISideBar, "onChangeModal"> {
  minimode: boolean;
  isMobile: boolean;
}

export const SideBarButtons: React.FC<SideBarButtonsProps> = ({
  onChangeModal,
  minimode,
  isMobile,
}) => {
  const [playAudio] = useAudio();
  const onOpenItem = (modal: TModal) => {
    playAudio();
    onChangeModal(modal);
  };

  return (
    <SideBarButtonsFrame>
      <SideItem
        title="Friend List"
        icon={blue_people}
        onClick={() => onOpenItem("UserList")}
        minimode={minimode}
        isMobile={isMobile}
      />
      <SideItem
        title="User Guide"
        icon={info}
        onClick={() => onOpenItem("UserGuide")}
        minimode={minimode}
        isMobile={isMobile}
      />
      <SideItem
        title="Settings"
        icon={setting}
        onClick={() => onOpenItem("Setting")}
        minimode={minimode}
        isMobile={isMobile}
      />
      <SideItem
        title="Exit"
        icon={logoutImage}
        onClick={() => onOpenItem("Exit")}
        minimode={minimode}
        isMobile={isMobile}
      />
    </SideBarButtonsFrame>
  );
};
