import { realTimeDomain, env } from "api-manager/resource/Endpoints";
import * as Colyseus from "colyseus.js";
export interface JWT {
  name: string;
  id: string;
  role: string;
  nickname: string;
  iat: number;
  exp: number;
}

const client = new Colyseus.Client(realTimeDomain[env]);

export default client;
