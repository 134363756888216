import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useInput } from "hooks/useInput";
import { TypoGraphy } from "components/typography";
import { XButton } from "components/buttons/common/XButton";
import { Avatar } from "components/chat/video/Avatar";
import { UsersTextChatContext } from "context/UsersTextChatContext";
import { ChatUser } from "../types";
import { CommonStyleProps } from "components/chat/types";

const UserListModalFrame = styled.div<CommonStyleProps>`
  box-sizing: border-box;

  position: absolute;

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        width: 300px;
        height: 485px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 20px 0px;
        padding: 40px 20px;
      `;
    } else {
      return `
        width: 360px;
        height: 785px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 20px 0px;
        padding: 40px 20px;
      `;
    }
  }}

  display: flex;
  flex-direction: column;
  z-index: 2;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;
const HeaderFrame = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderInfoFrame = styled.div`
  display: flex;
  gap: 0px 7px;
  align-items: center;
`;

const ConnectCounterFrame = styled.div`
  display: flex;
  align-items: center;
`;

const SearchFrame = styled.div`
  display: grid;
  place-items: center;
`;

const Input = styled.input`
  margin-right: 20px;
  margin-left: 10px;
  padding: 10px 10px;

  width: 80%;

  resize: none;
  border: none;
  outline: none;

  text-align: left;

  white-space: nowrap;

  resize: none;

  font-family: Godo M;
  font-size: 17px;
  line-height: 19px;
  color: #000000;
  // border: none;
  border: solid 2px #ff5c8d;
  border-radius: 8px;
`;

const UserListFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
  padding: 0px 20px;
  height: 600px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    padding: 10px 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 25px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 25px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #979797;
  }
`;

const UserFrame = styled.div`
  width: 100%;
  display: grid;
  place-items: center start;
  gap: 0px 20px;
  grid-template-columns: 56px auto;
`;

interface IUserListModal extends CommonStyleProps {
  onClose: () => void;
}

export const UserListModal: React.FC<IUserListModal> = ({
  visible,
  isMobile,
  onClose,
}) => {
  const { users } = useContext(UsersTextChatContext);
  const [filteredUsers, setFilteredUsers] = useState<ChatUser[]>(users);
  const [input, onInput, inputInit] = useInput("");

  useEffect(() => {
    if (!input.length) setFilteredUsers(users);
    else
      setFilteredUsers(users.filter((user) => user.nickname.includes(input)));
  }, [input, users]);

  useEffect(() => {
    if (!visible) inputInit("");
  }, [visible]);

  return (
    <UserListModalFrame visible={visible} isMobile={isMobile}>
      <HeaderFrame>
        <HeaderInfoFrame>
          <TypoGraphy variant={isMobile ? "Body1" : "Headline1"}>
            Friend List
          </TypoGraphy>
          <ConnectCounterFrame>
            <TypoGraphy variant={isMobile ? "Body2" : "Body1"} color="#EB4678">
              {users.filter((user) => user.state !== "disabled").length}
            </TypoGraphy>
            <TypoGraphy variant={isMobile ? "Body2" : "Body1"} color="#979797">
              /{users.length}
            </TypoGraphy>
          </ConnectCounterFrame>
        </HeaderInfoFrame>
        <XButton onClick={onClose} />
      </HeaderFrame>
      <SearchFrame>
        <Input
          placeholder="Search by user nickname.."
          value={input}
          onChange={onInput}
        />
      </SearchFrame>
      <UserListFrame>
        {filteredUsers.map((user) => (
          <UserFrame key={user.id}>
            <Avatar size={"small"} state={user.state} visibleActive={true} />
            <TypoGraphy variant="Body2">{user.nickname}</TypoGraphy>
          </UserFrame>
        ))}
        {/*  {
          filteredUsers.filter(user => user.state !== 'disabled' && userInfo?._id !== user.id).map((user) => 
            <UserFrame
              key={user.id}
            >
              <Avatar 
                size={"small"}
                state={user.state}
                visibleActive={true}
              />
              <TypoGraphy
                variant="Body1"
              >
                {user.nickname}
              </TypoGraphy>
            </UserFrame>
          )
        }
        {
          filteredUsers.filter(user => user.state === 'disabled' && userInfo?._id !== user.id).map((user) => 
            <UserFrame
              key={user.id}
            >
              <Avatar 
                size={"small"}
                state={user.state}
                visibleActive={true}
              />
              <TypoGraphy
                variant="Body1"
              >
                {user.nickname}
              </TypoGraphy>
            </UserFrame>
          )
        } */}
      </UserListFrame>
    </UserListModalFrame>
  );
};
