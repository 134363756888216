import { useState } from "react";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { PlaceCardItem } from "./PlaceCardStyled";
import { MemberInPlace } from "./MemberInPlace";
import { Box } from "@mui/material";

export interface PlaceCardProps {
  place: IPlace;
  onClick: () => void;
}

interface IPlace {
  title: string;
  member: number;
  image: string;
  scene: any;
}

export const PlaceCard = (props: PlaceCardProps) => {
  const { place, onClick } = props;
  const [isMobile, setIsMobile] = useState<boolean>(getDeviceDetect().isMobile);
  handleDeviceResize((device) => setIsMobile(device.isMobile));
  return (
    <PlaceCardItem isMobile={isMobile} onClick={onClick}>
      <Box className="card-img">
        <img src={place.image} />
      </Box>
      <MemberInPlace count={place.member} isMobile={isMobile} />
      <div className="title">{place.title}</div>
    </PlaceCardItem>
  );
};
