import styled, { keyframes } from "styled-components";

const ChatNotificationFade = keyframes`
  50% {
    opacity: 0;
  }
`
interface ChatNotificationProps {
  visible: boolean;
  isMobile: boolean;
}

export const ChatNotification = styled.div<ChatNotificationProps>`
  position: absolute;

  height: 22px;
  width: 22px;

  left: ${({isMobile}) => isMobile ? '20px':'45px'};
  bottom: ${({isMobile}) => isMobile ? '70px':'75px'};

  background: #EB4678;
  border-radius: 17px;
  visibility: ${({visible}) => visible ? 'display' : 'hidden'};
  animation: ${({visible}) => visible ? ChatNotificationFade : ''} 1s step-end infinite;
  z-index: 99;
`

export const ChatInput = styled.input`
  margin: auto 0;
  margin-left: 10px;
  padding: 10px 10px;
  box-sizing: border-box;

  
  font-family: Godo M;
  font-size: 17px;
  line-height: 19px;
  color: #979797;
  
  resize: none;
  border: none;
  outline: none;

  background: rgba(255, 255, 255, 0);
  overflow: hidden;

  white-space:nowrap;

  &::-webkit-scrollbar {
    // width: 8px;
    // border-radius: 6px;
    // background: rgba(255, 255, 255, 0.4);
    // margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    // background: rgba(0, 0, 0, 0.3);
    // border-radius: 6px;
  }
`