import { CommonStyleProps } from "components/chat/types";
import { SideBar } from "components/sidebar/living/SideBar";
import { TModal, TModalParam } from "components/sidebar/living/types";
import { ModalBroker } from "./ModalBroker";
import { inject, observer } from "mobx-react";

interface UtilControllerProps extends CommonStyleProps {
  store?: any;
}

export const UtilController: React.FC<UtilControllerProps> = inject("store")(
  observer(({ visible, isMobile, store }) => {
    const { modalStore } = store;
    const onChangeModal = (modal: TModal, blur?: boolean, param?: TModalParam) => {
      modalStore.setModal(modal);
    };

    return (
      <>
        <SideBar visible={visible} isMobile={isMobile} onChangeModal={onChangeModal} closeModal={() => modalStore.closeModal()} />
        <ModalBroker modal={modalStore.modal} isMobile={isMobile} onClose={() => modalStore.closeModal()} />
      </>
    );
  })
);
