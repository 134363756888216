export type ProcessFunction<T> = (item: T) => Promise<void>;

export class Queue<T> {
  private _queue: T[] = [];
  private _process: ProcessFunction<T>;

  constructor(process: ProcessFunction<T>) {
    this._process = process;
  }

  public enqueue(item: T) {
    this._queue.push(item);
  }

  public async dequeue() {
    if (this._queue.length > 0) {
      const item = this._queue.shift();
      if (item) {
        await this._process(item);
      }
    }
  }

  public runQueue() {
    setTimeout(async () => {
      while (true) {
        await this.dequeue();
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    }, 0);
  }
}
