import { LegacyButton } from "components/buttons";
import { XButton } from "components/buttons/common/XButton";
import { TypoGraphy } from "components/typography";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTER } from "routes/constant";
import styled from "styled-components";

const Exit = styled.div`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? "313px" : "360px")};
  height: 204px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  padding: 30px 20px;
  z-index: 5;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ExitModal = inject("store")(
  observer((props: any) => {
    const { isMobile, visible, onClose, store } = props;
    const navigate = useNavigate();
    const handleExit = () => {
      onClose();
      store.sceneStore.setLastScene(store.sceneStore.currentScene)
      store.sceneStore.setCurrentScene(null)
      navigate(NAVIGATION_ROUTER.PLACES);
    };

    return (
      <Exit visible={visible} isMobile={isMobile}>
        <ContentWrapper>
          <HeaderFrame>
            <XButton onClick={onClose} />
          </HeaderFrame>
          <TypoGraphy variant="Headline2" color="#242424">
            Exit
          </TypoGraphy>
          <TypoGraphy variant="Body1" color="#EB4678" padding="30px 0 0">
            Are you like to leave the lounge?
          </TypoGraphy>
          <ButtonWrap>
            <LegacyButton
              buttonType="Secondary"
              state="Default"
              paddingHorizon={60}
              onClick={onClose}
            >
              Cancel
            </LegacyButton>
            <LegacyButton
              buttonType="Primary"
              state="Default"
              paddingHorizon={60}
              onClick={handleExit}
            >
              Exit
            </LegacyButton>
          </ButtonWrap>
        </ContentWrapper>
      </Exit>
    );
  })
);
