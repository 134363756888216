import { SVGIcon } from "components/icon/SVGIcon";
import { useState } from "react";
import styled from "styled-components";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
interface ButtonProps {
  type: string;
  variant: string;
  label?: string;
  icon?: string;
  size?: number;
  color: string;
  onClick: () => void
}

const ButtonWrapper = styled.div`
  background: ${({ variant, color }) => (variant === 'contained' ? color === "primary" ? "#FB8855" : "#FFFFFF" : 'transparent')};
  color: ${({ variant, color }) => (variant === 'contained' ? color === "primary" ? "#FFF" : "#AF4922" : 'transparent')};
  box-shadow: ${({ variant, color }) => (variant === 'contained' ? color === "primary" ? "0px 0px 20px rgba(173, 76, 35, 0.3)" : "0px 0px 12px rgba(172, 73, 33, 0.25)" : 'transparent')};
  font-family: 'Godo M';
  border-radius: 50px;
  width: ${({ iconOnly, isMobile }) => (iconOnly ? "49px" : isMobile ? '60px' : "220px")};
  height: ${({ isMobile }) => (isMobile ? "37px" : "53px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-transform: capitalize;
  cursor: pointer;
`;


export const MetaverseButton = (props: ButtonProps) => {
  const { type, variant, label, icon, size = 20, onClick, color } = props;
  const [isMobile, setIsMobile] = useState<boolean>(
    getDeviceDetect().isMobile
  );
  handleDeviceResize((device) => setIsMobile(device.isMobile));
  
  const iconOnly = type === "icon" && icon;

  return (
    <ButtonWrapper iconOnly={iconOnly} variant={variant} isMobile={isMobile} size={size} color={color} onClick={onClick}>
      {iconOnly ? <SVGIcon name={icon} size={size} /> : label}
    </ButtonWrapper>
  );
};
