type BrandColor = 'Primary' | 'Secondary' | 'Accent_Blue' | 'Accent_Green' | 'Accent_Yellow';

type Variation = 100 | 75| 50 | 25;

type Variations = {
  [key in Variation]: string;
};

// const primaryVariations: Variations = {
//   "100%": "#FF5C8D",
//   "75%": "#FF85A9",
//   "50%": "#FFAEC6",
//   "25%": "#FFD6E2"
// }

// const secondaryVariations: Variations = {
//   "100%": "#7B61FF",
//   "75%": "#9C88FF",
//   "50%": "#BDB0FF",
//   "25%": "#DED7FF"
// }

const getPrimaryColor = (active?: boolean, variation?: Variation) => {
  if (active) return '#EB4678';
  // return primaryVariations[variation ? variation : '100%'];
  return variation ? `rgba(255, 92, 141, ${variation/100})` : 'rgb(255, 92, 141)';
}

const getSecondaryColor = (active?: boolean, variation?: Variation) => {
  if (active) return '#644BE6';
  return variation ? `rgba(123, 97, 255, ${variation/100})` : 'rgb(123, 97, 255)';
}

export type BrandColorParam = {
  color: BrandColor;
  active?: boolean;
  variation?: Variation;
}

export const brandColor = ({color, active, variation}: BrandColorParam) => {
  let value: string = '';
  switch (color) {
    case 'Primary':
      value = getPrimaryColor(active, variation);
      break;
    case 'Secondary':
      value = getSecondaryColor(active, variation);
      break;
    case 'Accent_Blue':
      value = '#5AC8E6';
      break;
    case 'Accent_Green':
      value = '#82E6C8';
      break;
    case 'Accent_Yellow':
      value = '#FAD75F';
      break;
    default:
      value = '#FF5C8D';
      break;
  }
  return value;
}

type TextColorType = 'Black' | 'Gray1' | 'Gray2' | 'Gray3' | 'Purple' | 'Pink' | 'White';

export type TextColor = {
  [key in TextColorType]: string;
}

export const textColor: TextColor = {
  Black: '#242424',
  Gray1: '#5F5F5F',
  Gray2: '#979797',
  Gray3: '#D8D8D8',
  Purple: '#3C3282',
  Pink: '#E25A98',
  White: '#FFFFFF'
}

type BGColorType = 'BG1' | 'BG2' | 'BG3';

export type BGColor = {
  [key in BGColorType]: string;
}

export const bgColor: BGColor = {
  BG1: '#F1F5F9',
  BG2: 'linear-gradient(104.04deg, #D9E4F6 0%, #D9D5FB 100%)',
  BG3: '#C7C7CC'
}