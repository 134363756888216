export const ROOM_SIZE = [
  {
    id: 0,
    name: "default",
    rooms: {
      temp_floor_auditorium: {
        x: 24.2,
        z: 17.6,
      },
      temp_floor_BigroomA: {
        x: 3.5, // (19.8,23.2)
        z: 8, // (-4.1,3.4)
      },
      temp_floor_middleroomA: {
        x: 5.2, // (8.7, 13.6)
        z: 4.1, // (1.5, 5.5)
      },
      temp_floor_middleroomB: {
        x: 5.2, // (14.4, 19.1)
        z: 4.1, // (1.5, 5.5)
      },
      temp_floor_smallroomA: {
        x: 3.3, // (10.8, 13.8)
        z: 3.5, // (-5.6,-2.3)
      },
      temp_floor_smallroomB: {
        x: 3.3, // (10.8, 13.8)
        z: 3.8, // (-9.6,-5.8)
      },
      temp_privateroomA: {
        x: 1.7,
        z: 1.72,
      },
      temp_privateroomB: {
        x: 1.7,
        z: 1.75,
      },
      temp_privateroomC: {
        x: 1.7,
        z: 1.75,
      },
      temp_privateroomD: {
        x: 1.7,
        z: 1.75,
      },
    },
  },
];
