import { useCallback, useState } from "react";

type UseInputReturn = ReturnType<() => [ string, (event: React.ChangeEvent<HTMLInputElement>) => void, (value: string) => void]>

export const useInput = (_input?: string): UseInputReturn => {
  const [input, setInput] = useState<string>(!_input ? "" : _input);
  const onInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  },[])

  const inputInit = (value: string) => {
    setInput(value);
    // setInput(!_input ? "" : _input);
  }

  return [
    input,
    onInput,
    inputInit
  ]
}
