import { useCallback, useState } from "react";

type TReturn<T> = [boolean, (event: React.MouseEvent<T, MouseEvent>) => void, (event: React.MouseEvent<T, MouseEvent>) => void];

export const useMouseOver = <T>():TReturn<T> => {
  const [onMouse, setOnMouse] = useState<boolean>(false);

  const onMouseOut = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      setOnMouse(false);
    },
    [],
  )

  const onMouseOver = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      setOnMouse(true);
    },
    [],
  )
  
  return [
    onMouse,
    onMouseOver,
    onMouseOut,
  ]
}