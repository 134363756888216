import styled from "styled-components";

interface ChatAreaFrameProps {
  focus: boolean;
  visible: boolean;
  isMobile: boolean;
}

export const ChatAreaFrame = styled.div<ChatAreaFrameProps>`
  z-index: 1;
  opacity: ${({focus, isMobile})  => isMobile ? 1 : focus ? 1 : 0.5};
  visibility: ${({visible}) => visible ? 'visible' : 'hidden'};

  position: absolute;
  bottom: ${({isMobile}) => isMobile ? '0' : '80px'};
  left: 50%;
  transform: translate(-50%, 0%);

  ${({isMobile}) => {
    const width = isMobile ? '100vw' : '360px';
    const height = isMobile ? '100vh' : '100px';
    const marginBottom = isMobile ? '0' : '15px';
    const padding = isMobile ? '0' : '25px';
    const borderRadius = isMobile ? '0' : '30px'
    return `
      width: ${width};
      height: ${height};
      margin-bottom: ${marginBottom};
      padding: ${padding};
      border-radius: ${borderRadius};
    `
  }}

  background: #212121B5;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
`

export const ChatTextArea = styled.div`
  box-sizing: border-box;

  height: 100%;

  overflow: auto;
  text-align: left;
  // display:flex;
  // flex-direction:column_reverse;

  // background: rgba(255, 255, 255, 0);
  // backdrop-filter: blur(15px);

  &::-webkit-scrollbar {
    width: 5px;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
  }
`

export const MessageFrame = styled.div`
  margin-top: 4.5px;
  margin-bottom: 4.5px;
  ${({isMobile}) => isMobile && `
    padding-left: 20px;
    padding-top: 20px;
  `}
`