import {
  Mesh,
  PBRMaterial,
  Scene,
  SceneLoader,
  Texture,
  Tools,
} from "@babylonjs/core";
import {
  characterItemColors,
  characterItems,
} from "pages/character/ShowRoomData";
import { IShowroomTab } from "pages/character/ShowRoomInterface";
import { basicMesh, tabData } from "../../character/ShowRoomData";
import { CustomItem } from "./interface";
export class CharacterModel {
  private _character;
  private _cloneCharacter;

  private scene: Scene;
  private players;
  public characterItems;
  constructor(scene: Scene) {
    this.characterItems = this.loadCharacterItems();

    this.players = {
      assets: new Map(),
      player: new Map(),
    };
    this.scene = scene;
  }

  get character() {
    return this._character;
  }

  public setCloneCharacter(container) {
    this._cloneCharacter = container;
  }

  public async loadAssetManager() {
    const path = "./models/";
    const name = "character.glb";
    const key = name.split(".")[0];
    const container = await SceneLoader.LoadAssetContainerAsync(path, name);

    this.players.assets.set(key, container);
  }

  public cloneCharacter(client) {
    this.checkingAssests();
    const assetContainer = this.players.assets.get("character");
    if (assetContainer) {
      const entries = assetContainer.instantiateModelsToScene(
        undefined,
        false,
        {
          doNotInstantiate: true,
        }
      );
      const root: Mesh = entries.rootNodes[0];
      root.position.x = client.movement.x;
      root.name = client.info.name;
      root.id = client.info.sessionId;
      entries.animationGroups.forEach((animationGroup) => {
        const nameArr = animationGroup.name.split("of");
        animationGroup.stop();
        animationGroup.name = nameArr[1].trim();
        if (animationGroup.name === "Idle") {
          animationGroup.play(true);
        }
      });
      root.animations = entries.animationGroups;
      this.formatChildName(root, client.info.sessionId);
      this.players.player.set(client.info.sessionId, entries);
      this.loadOutfit(client);
    }
  }

  public loadOutfit(client) {
    const transformedData = this.transformOutfit(
      client.character,
      this.characterItems
    );
    const nameArray = [
      ...basicMesh,
      ...transformedData.map((item) => item.name),
    ].map((el) => `${client.info.name}.${el}`);

    const character = this.scene.getMeshByName(client.info.name)!;
    const meshes = character.getChildMeshes();
    const faceItem = transformedData.find((el) => el.type === "face");

    meshes.forEach((mesh: any) => {
      if (nameArray.includes(mesh.name)) {
        mesh.isVisible = true;
        const defaultMeshName = mesh.name.split(".")[1].trim();
        const newItem = transformedData.find(
          (el) => el.name === defaultMeshName
        )!;
        const textureName = newItem?.color
          ? `Char_${newItem.color}`
          : defaultMeshName;
        const texture = this.loadTexture(mesh.name, textureName);

        const material = this.loadMaterial(mesh.name);
        material.albedoTexture = texture;
        mesh.material = material;
      } else {
        mesh.isVisible = false;
      }
    });
    this.checkDefaultFace(meshes, client, faceItem);
  }

  private checkDefaultFace(meshes, client, faceItem) {
    const clientName = client.info.name;
    const defaultFace = meshes.find(
      (mesh) => mesh.name === `${clientName}.Chr_Face_00`
    )!;

    const defaultBody: any = meshes.find(
      (mesh) => mesh.name === `${clientName}.Chr_Body_00`
    )!;
    const faceTexture = this.scene.getTextureByName(
      `${clientName}.${faceItem ? faceItem?.name : "Chr_Body_00"}`
    );

    defaultFace.isVisible = faceItem ? false : true;
    defaultBody.material.albedoTexture = faceTexture;
  }

  public transformOutfit = (outfit, items) => {
    const data: CustomItem[] = [];
    if (outfit.hairId) {
      const hairItem = {
        label: outfit.hairId,
        name: outfit.hairId,
        type: "hair",
        color: outfit.hairColor || "",
        colorList: items?.find((items) => items.type === "hair")?.data[0]
          .colorList,
      };
      data.push(hairItem);
    }
    if (outfit.faceId) {
      const faceItem = {
        label: outfit.faceId,
        name: outfit.faceId,
        type: "face",
        color: outfit.faceColor || "",
        colorList: items.find((items) => items.type === "face")?.data[0]
          .colorList,
      };
      data.push(faceItem);
    }
    if (outfit.bodyId) {
      const topItem = {
        label: outfit.bodyId,
        name: outfit.bodyId,
        type: "top",
        color: outfit.bodyColor || "",
        colorList: items.find((items) => items.type === "top")?.data[0]
          .colorList,
      };
      data.push(topItem);
    }
    if (outfit.bottomId) {
      const bottomItem = {
        label: outfit.bottomId,
        name: outfit.bottomId,
        type: "bottom",
        color: outfit.bottomColor || "",
        colorList: items.find((items) => items.type === "bottom")?.data[0]
          .colorList,
      };
      data.push(bottomItem);
    }
    if (outfit.shoesId) {
      const shoesItem = {
        label: outfit.shoesId,
        name: outfit.shoesId,
        type: "shoes",
        color: outfit.shoesColor || "",
        colorList: items.find((items) => items.type === "shoes")?.data[0]
          .colorList,
      };
      data.push(shoesItem);
    }
    if (outfit.suitId) {
      const suitItem = {
        label: outfit.suitId,
        name: outfit.suitId,
        type: "suit",
        color: outfit.suitColor || "",
        colorList: items.find((items) => items.type === "suit")?.data[0]
          .colorList,
      };
      data.push(suitItem);
    }
    if (outfit.hatId) {
      const hatItem = {
        label: outfit.hatId,
        name: outfit.hatId,
        type: "hat",
        color: "",
        colorList: [],
      };
      data.push(hatItem);
    }
    if (outfit.earringId) {
      const earringItem = {
        label: outfit.earringId,
        name: outfit.earringId,
        type: "earring",
        color: outfit.earringColor || "",
        colorList: [
          "Earring_Color_01",
          "Earring_Color_02",
          "Earring_Color_03",
          "Earring_Color_04",
          "Earring_Color_05",
        ],
      };
      data.push(earringItem);
    }
    if (outfit.glassId) {
      const glassItem = {
        label: outfit.glassId,
        name: outfit.glassId,
        type: "glasses",
        color: "",
        colorList: [],
      };
      data.push(glassItem);
    }

    if (outfit.otherId) {
      const otherItem = {
        label: outfit.otherId,
        name: outfit.otherId,
        type: "bag",
        color: "",
        colorList: [],
      };
      data.push(otherItem);
    }
    if (outfit.necklaceId) {
      const necklaceItem = {
        label: outfit.necklaceId,
        name: outfit.necklaceId,
        type: "necklace",
        color: "",
        colorList: [],
      };
      data.push(necklaceItem);
    }
    return data;
  };

  public formatChildName(root, playerID) {
    root
      .getChildren()[0]
      .getChildren()
      .forEach((child) => {
        const nameArr = child.name.split("of");
        child.id = playerID;
        child.name = `${root.name}.${nameArr[1].trim()}`;
      });
  }

  public checkingAssests() {
    if (!this.players.assets) {
      this.loadAssetManager();
    }
  }

  public loadCharacterItems = () => {
    return tabData.map((el) => {
      const type = el.title;

      return {
        ...el,
        data: this.getTabData(el),
      };
    });
  };

  private getTabData = (tab: IShowroomTab) => {
    if (tab.subTab.length > 0) {
      return tab?.subTab?.map((sub) => {
        return {
          type: sub,
          label: sub,
          data: this.filterData(sub),
        };
      });
    } else {
      return this.filterData(tab.title);
    }
  };

  private filterData = (type) => {
    return characterItems
      .filter((items) => items.type === type)
      .map((items) => {
        return {
          ...items,
          color: "",
          colorList: characterItemColors.find((color) => color.type === type)
            ?.skin,
        };
      });
  };

  public loadTexture(meshName: string, textureName: string) {
    const texture = new Texture(
      require(`../../../assets/img/character/textures/${textureName}.png`)
    );

    texture.name = meshName;
    texture.uAng = Tools.ToRadians(180);
    return texture;
  }

  public loadMaterial(meshName: string) {
    const material = new PBRMaterial(meshName);
    material.roughness = 1;
    material.metallic = 0;
    return material;
  }

  public loadCostumeSkin() {
    characterItemColors.map((color) => {
      color.skin.map((el) => {
        const skinTexture = new Texture(
          require(`../../../assets/img/character/textures/Char_${el}.png`)
        );
        skinTexture.name = `Char_${el}`;
        skinTexture.uAng = Tools.ToRadians(180);
      });
    });
  }
  get characterClone() {
    return this._cloneCharacter;
  }

  get getPlayers() {
    return this.players;
  }
}
