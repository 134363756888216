import styled from "@emotion/styled";
import { Joystick } from "react-joystick-component";
import base from "assets/img/joystick/joystick_base.png";
import { ReactComponent as Jump } from "assets/img/joystick/joystick_jump.svg";
import { inject, observer } from "mobx-react";
import { observe } from "mobx";

const MobileControllerFrame = styled.div`
  z-index: 2;
  position: absolute;
  display: grid;
  grid-template-columns: 120px auto 100px;
  bottom: 120px;
  width: 100%;
`;

const JoystickFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const JumpButtonFrame = styled.div`
  position: relative;
  width: 100%;
`;

const JumpBase = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
interface IJumpButton {
  onClickJump: (e: any) => void;
}

const JumpButton: React.FC<IJumpButton> = ({ onClickJump }) => {
  return (
    <JumpButtonFrame>
      <JumpBase onClick={onClickJump}>
        <img width={75} src={base} alt='' />
      </JumpBase>
      <JumpBase onClick={onClickJump}>
        <Jump fill='white' />
      </JumpBase>
    </JumpButtonFrame>
  );
};

export interface IMobileControllerProps {
  actionMove: (e: any) => void;
  actionStop: (e: any) => void;
  actionJump: (e: any) => void;
  isMobile: boolean;
  store?: any;
}

export const MobileController: React.FC<IMobileControllerProps> = inject("store")(
  observer(({ actionMove, actionStop, actionJump, isMobile, store }) => {
    const { modalStore } = store;
    if (!isMobile || modalStore.modal === "Location") return <></>;

    return (
      <MobileControllerFrame>
        <JoystickFrame>
          <Joystick
            move={actionMove}
            size={100}
            baseColor='rgba(217, 217, 217, 0.19)'
            stickColor='rgba(255, 255, 255, 0.71)'
            stop={actionStop}
          ></Joystick>
        </JoystickFrame>
        <div></div>
        <JoystickFrame>
          <JumpButton onClickJump={actionJump} />
        </JoystickFrame>
      </MobileControllerFrame>
    );
  })
);
