import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ align = 'center' }) => `
    align-items: ${align};
  `}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  ${({ justify }) => (justify ? `justify-content: ${justify};` : '')}
  ${({ wrap }) => (wrap ? 'flex-wrap: wrap;' : '')}
`;
