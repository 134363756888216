import { DataPacket_Kind, Room } from "livekit-client";
import { useRef } from "react";
import { SendButton } from "./SendButton";
import { ChatBarFrame } from "./styles/chat-bar"
import { ChatInput } from "./styles/chat-input";

interface TextChatInputProps {
  room: Room;
  focus: boolean;
  isMobile: boolean;
  addMessage: (nickname: string, message: string) => void;
  onTextChatActive: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const TextChatInput: React.FC<TextChatInputProps> = ({
  room,
  focus,
  isMobile,
  addMessage,
  onTextChatActive
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const enter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      submit();
    }
  }

  const submit = () => {
    if (inputRef.current && room) {
      const message = inputRef.current.value.trim();
      if (message.length < 1) return;
      inputRef.current.value = '';
      const strData = JSON.stringify({
        message,
        date: new Date().getTime()
      });
      const encoder = new TextEncoder();
      const data = encoder.encode(strData);
      room.localParticipant.publishData(data, DataPacket_Kind.LOSSY);
      addMessage('ME', message);
      // setMessages((prev) => prev.concat(`[Me]: ${message}`));
    }
  }


  return (
    <ChatBarFrame
      onClick={onTextChatActive}
      focus={focus}
      enableBlink={false}
      isMobile={isMobile}
    >
      <ChatInput
        ref={inputRef}
        onKeyUp={enter}
        tabIndex={2}
        placeholder='Chat'
      />
      <SendButton
        onClick={submit}
        state="Default"
      />
    </ChatBarFrame>
  )
}