import styled from 'styled-components';
import { ButtonProps } from './types';

export interface IconButtonProps extends Pick<ButtonProps, 'state' | 'paddingHorizon'>{}

export const IconButton = styled.button<IconButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  gap: 10px;

  background: ${({state}) => state === 'Disable' ? '#FF85A9' : '#FF5C8D'};
  border: 1px solid #E84878;
  box-shadow: 0px 6px 12px rgba(255, 92, 141, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.35);
  border-radius: 32px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7);
  }
  &:active {
    background: #EB4678;
    box-shadow: 0px 12px 20px rgba(255, 92, 141, 0.5), inset 0px -3px 0px rgba(255, 255, 255, 0.3), inset 0px 8px 20px #EB4678, inset 0px 0px 12px rgba(255, 92, 141, 0.7);
  }
`;