import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export const XButton: React.FC<{onClick: () => void, fontSize?: number, color?: string}> = ({onClick, fontSize, color}) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
      icon={faXmark}
      fontSize={fontSize || 20}
      color={ color || '#979797'}
    />
  )
}