function GoUpButton(props) {
  const { onClick } = props;

  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      fill="none"
      viewBox="0 0 57 57"
    >
      <g filter="url(#filter0_d_23_3199)">
        <path
          fill="#fff"
          d="M28.095 4A24.095 24.095 0 1052.19 28.095 24.12 24.12 0 0028.095 4zm0 44.483a20.387 20.387 0 1120.388-20.388 20.41 20.41 0 01-20.388 20.388zM36.82 24.93a1.854 1.854 0 11-2.623 2.623L29.948 23.3v14.06a1.853 1.853 0 01-3.707 0v-14.06l-4.249 4.252a1.855 1.855 0 11-2.622-2.623l7.413-7.414a1.855 1.855 0 012.623 0l7.414 7.414z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_23_3199"
          width="56.19"
          height="56.19"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.324242 0 0 0 0 0.123854 0 0 0 0 0.341667 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23_3199"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_23_3199"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default GoUpButton;
