import { Box } from "@mui/material";
import GoDownButton from "components/buttons/svg/GoDownButton";
import GoUpButton from "components/buttons/svg/GoUpButton";
import { inject, observer } from "mobx-react";
import { ExploreSpace } from "pages/places/ExploreSpace";
import React, { useEffect, useRef, useState } from "react";
import ReactTextTransition from "react-text-transition";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import NavBarComponent from "./NavigationBar";
import { mainPageState, placesLimit, scrollLimit, subMainLimit } from "./const";
import { MainWrapper } from "./styled";
import ModelMainPage from "./ModelMainPage";
import { Footer } from "components/footer/Footer";

interface IMainPage {
  store?: any;
}

export const MainPage: React.FC<IMainPage> = inject("store")(
  observer(({ store }) => {
    const _ref = useRef<HTMLCanvasElement>(null);
    const placesRef = useRef<HTMLCanvasElement>(null);
    const [mainState, setMainState] = useState(mainPageState[0]);
    const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);
    const { sceneStore } = store;
    let currentY = 0;
    const handleChangeState = (goingDown: boolean) => {
      const currentStateIndex = mainPageState.findIndex(
        (el) => el === mainState
      );
      const newState = mainPageState[currentStateIndex + (goingDown ? 1 : -1)];
      if (newState) {
        setZoomRange(newState.scrollStart);
      }
    };

    const setZoomRange = (currentZoom: number) => {
      if (currentZoom >= 0 && currentZoom <= scrollLimit) {
        sceneStore.setZoom(currentZoom);
      }
    };

    const setOpenLogin = (open: boolean) => {
      store.modalStore.setOpenLogin(open);
    };

    const handleLogIn = () => {
      setOpenLogin(true);
    };

    const handleCanvasWheel = (event) => {
      const delta = Math.sign(event.deltaY);
      const currentZoom = sceneStore.zoom + delta;

      setZoomRange(currentZoom);
    };

    useEffect(() => {
      const { current: canvas } = _ref;
      if (!canvas) return;
      window.scrollTo(0, 0);
      new ModelMainPage(canvas, store);
      addCanvasEvents(canvas);
      addPlacesEvents(canvas);
      handleDeviceResize((device) => {
        setIsMobile(device.isMobile);
      });
    }, []);

    useEffect(() => {
      const newState = mainPageState.find(
        (el) => sceneStore.zoom <= el.scrollLimit
      );
      if (newState && newState.state !== mainState.state) {
        setMainState(newState);
        const root = document.getElementById("root");
        const places = document.getElementById("places");
        if (newState.state === "places") {
          root?.scrollTo({ top: places?.clientHeight, behavior: "smooth" });
        } else {
          root?.scrollTo(0, 0);
        }
      }
    }, [sceneStore.zoom]);

    const handleWheel = (event, canvas) => {
      if (
        (event.deltaY < 0 && canvas.scrollTop === 0) ||
        (event.deltaY > 0 &&
          canvas.scrollTop === canvas.scrollHeight - canvas.clientHeight)
      ) {
        event.preventDefault();
        handleCanvasWheel(event);
      }
    };

    const addCanvasEvents = (canvas) => {
      const main = document.getElementById("main");

      main?.addEventListener("wheel", (event) => {
        handleWheel(event, canvas);
      });
      main?.addEventListener("touchstart", (event) => {
        var value = event.changedTouches[0].clientY;
        currentY = value;
      });
      main?.addEventListener("touchend", (event) => {
        var value = event.changedTouches[0].clientY;
        const currentZoom = sceneStore.zoom + (value < currentY ? 1 : -1);
        setZoomRange(currentZoom);
      });
    };

    const addPlacesEvents = (canvas) => {
      const places = document.getElementById("places");

      places?.addEventListener("wheel", (event) => {
        if (event.deltaY < 0 && places.scrollTop === 0) {
          setZoomRange(subMainLimit);
        }
      });
      places?.addEventListener("touchstart", (event) => {
        var value = event.changedTouches[0].clientY;
        currentY = value;
      });

      places?.addEventListener("touchend", (event) => {
        var value = event.changedTouches[0].clientY;
        if (value > currentY && places.scrollTop === 0)
          setZoomRange(subMainLimit);
      });
    };

    return (
      <MainWrapper mainState={mainState} isMobile={isMobile}>
        <NavBarComponent />
        <div id="main" className="main">
          <canvas ref={_ref} id="metaverse-canvas" />
          <div id="main-content" className="main-content">
            <ReactTextTransition className="intro">
              {mainState.content?.intro}
            </ReactTextTransition>
            <ReactTextTransition className="page-title">
              {mainState.content?.title}
            </ReactTextTransition>
            <ReactTextTransition className="page-describe">
              {mainState.content?.mobileDescribe && isMobile
                ? mainState.content?.mobileDescribe
                : mainState.content?.describe}
            </ReactTextTransition>
            <ReactTextTransition className="action">
              <span onClick={handleLogIn}>{mainState.content?.actions}</span>
            </ReactTextTransition>
          </div>
        </div>
        <Box height="100vh" id="places" ref={placesRef}>
          <ExploreSpace haveLimit={false} />
          <div className="button-group">
            <GoUpButton onClick={() => handleChangeState(false)} />
            <GoDownButton onClick={() => handleChangeState(true)} />
          </div>
          <Footer />
        </Box>
      </MainWrapper>
    );
  })
);
