import { useState, useCallback, useContext, useEffect } from "react";
import { ReactComponent as Video } from "assets/img/icon/v2/video.svg";
import { ReactComponent as Mic } from "assets/img/icon/v2/mic.svg";
import { ReactComponent as OffVideo } from "assets/img/icon/v2/video-off.svg";
import { ReactComponent as OffMic } from "assets/img/icon/v2/mic-off.svg";
import { TypoGraphy } from "components/typography";
import { UserSettingContext } from "context/UserSettingContext";
import {
  ItemFrame,
  UsersViewFrame,
  VideoChatFrame,
  VideoControlFrame,
  VideoControlItemsFrame,
} from "./styles/Video";
import { Participant } from "livekit-client";
import { ParticipantView } from "./ParticipantView";
import { PageControl } from "./VideoChat";
import { useAudio } from "hooks/videochat/useAudio";
import { useMouseOver } from "hooks/videochat/useMouseOver";
import { inject, observer } from "mobx-react";

interface IUsersView {
  participants: Participant[];
  isMobile: boolean;
  store?: any;
}

export interface IVideo {
  id: string;
  name: string;
  onVideo: boolean;
  onAudio: boolean;
}

export const RemoteVideo: React.FC<IUsersView> = inject("store")(
  observer(({ participants, store, isMobile }) => {
    const [playAudio] = useAudio();
    const [page, setPage] = useState<number>(0);
    const { video, mic, handleMedia } = useContext(UserSettingContext);
    const [onVideo, onVideoOver, onVideoOut] = useMouseOver<SVGSVGElement>();
    const [onMic, onMicOver, onMicOut] = useMouseOver<SVGSVGElement>();
    const userInfo = store.userStore.user;
    const onClickArrow = useCallback(
      (direction: "left" | "right") => {
        playAudio();
        if (
          direction === "right" &&
          participants.filter((par) => !par.isLocal).length > (page + 1) * 5
        ) {
          setPage((page) => page + 1);
        } else if (direction === "left" && page > 0) {
          setPage((page) => page - 1);
        }
      },
      [page, participants]
    );

    return (
      <VideoChatFrame visible={true} isMobile={isMobile}>
        <UsersViewFrame>
          {participants
            .filter((participant) => !participant.isLocal)
            .filter((_, index) => page * 5 <= index && index < page * 5 + 5)
            .map((participant) => (
              <ParticipantView
                isMobile={isMobile}
                key={participant.identity}
                participant={participant}
              />
            ))}
          {participants
            .filter((participant) => participant.isLocal)
            .map((participant) => (
              <ParticipantView
                isMobile={isMobile}
                avatar={userInfo.picture}
                key={participant.identity}
                participant={participant}
              />
            ))}
        </UsersViewFrame>
        <VideoControlFrame>
          <PageControl direction="left" onClick={onClickArrow} />
          <VideoControlItemsFrame>
            <ItemFrame onClick={() => handleMedia("video", !video)}>
              {video ? (
                <Video
                  onMouseOver={onVideoOver}
                  onMouseOut={onVideoOut}
                  fill={onVideo ? "#FFFFFF" : "#D8D8D8"}
                />
              ) : (
                <OffVideo
                  onMouseOver={onVideoOver}
                  onMouseOut={onVideoOut}
                  fill={onVideo ? "#FFFFFF" : "#D8D8D8"}
                />
              )}
              <TypoGraphy
                typographyType="Default"
                variant="Caption3"
                color="#979797"
              >
                Video
              </TypoGraphy>
            </ItemFrame>
            <ItemFrame onClick={() => handleMedia("mic", !mic)}>
              {mic ? (
                <Mic
                  onMouseOver={onMicOver}
                  onMouseOut={onMicOut}
                  fill={onMic ? "#FFFFFF" : "#D8D8D8"}
                />
              ) : (
                <OffMic
                  onMouseOver={onMicOver}
                  onMouseOut={onMicOut}
                  fill={onMic ? "#FFFFFF" : "#D8D8D8"}
                />
              )}
              <TypoGraphy
                typographyType="Default"
                variant="Caption3"
                color="#979797"
              >
                Audio
              </TypoGraphy>
            </ItemFrame>
          </VideoControlItemsFrame>
          <PageControl direction="right" onClick={onClickArrow} />
        </VideoControlFrame>
      </VideoChatFrame>
    );
  })
);
