import { XButton } from "components/buttons/common/XButton";
import { TypoGraphy } from "components/typography";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MINI_ROOMS = ["Mini Meeting room A", "Mini Meeting room B", "Mini Meeting room C", "Mini Meeting room D"];

const ROOMS = ["Meeting room C", "Meeting room D"];

const BIG_ROOMS = ["Conference room", "Auditorium"];

const Location = styled.div`
  position: absolute;
  width: ${({ isMobile }) => isMobile && "100%"};
  bottom: ${({ isMobile }) => (isMobile ? "5%" : "12.5%")};
  ${({ isMobile }) =>
    !isMobile &&
    `
    left: 57%;
    transform: translate(-50%, 0);
  `};
  z-index: 5;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 12px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  & > div {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const RoomBtn = styled.button`
  padding: 12px;
  background-color: #ffffff;
  border: 2px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 43px;
  cursor: pointer;

  &:hover {
    background-color: #aed4f0;
  }

  flex: ${({ isMobile }) => isMobile && "1"};
  min-width: ${({ isMobile }) => isMobile && "48%"};
`;

export const CloseBtn = styled.div`
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #101010;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const LocationModal = inject("store")(
  observer((props: any) => {
    const { isMobile, visible, onClose, store } = props;
    const navigate = useNavigate();
    const handleLocation = () => {
      onClose();
    };

    return (
      <Location visible={visible} isMobile={isMobile}>
        <ContentWrapper>
          {isMobile && (
            <CloseBtn onClick={onClose}>
              <XButton onClick={onClose} fontSize={30} color='#FFFFFF' />
            </CloseBtn>
          )}
          <ButtonWrap>
            <div>
              {MINI_ROOMS.map((roomName) => (
                <RoomBtn key={roomName} isMobile={isMobile}>
                  <TypoGraphy variant='Body2' color='#5F5F5F'>
                    {roomName}
                  </TypoGraphy>
                </RoomBtn>
              ))}
            </div>
            <div>
              {ROOMS.map((roomName) => (
                <RoomBtn key={roomName} isMobile={isMobile}>
                  <TypoGraphy variant='Body2' color='#5F5F5F'>
                    {roomName}
                  </TypoGraphy>
                </RoomBtn>
              ))}
            </div>
            <div>
              {BIG_ROOMS.map((roomName) => (
                <RoomBtn key={roomName} isMobile={isMobile}>
                  <TypoGraphy variant='Body2' color='#5F5F5F'>
                    {roomName}
                  </TypoGraphy>
                </RoomBtn>
              ))}
            </div>
          </ButtonWrap>
        </ContentWrapper>
      </Location>
    );
  })
);
