import { Mesh, PointerEventTypes, Scene, Vector3 } from "@babylonjs/core";
import {
  getOutfit,
  saveOutfit,
  updateNickname
} from "api-manager/services/CharacterServices/CharacterServices";
import { AssestController } from "../AssetsManager/AssestController";
import { basicMesh } from "./../../character/ShowRoomData";
import { Character, CustomItem, ICharacterInfo } from "./interface";
export class CharacterController {
  private _character: Character;
  private _scene: Scene;
  private _outfit: CustomItem[];

  constructor(character: Character, scene: Scene) {
    this._character = character;
    this._scene = scene;
    this._outfit = [];
  }
  public get character(): Character {
    return this._character;
  }

  public rotateOnYAxis(
    scene: Scene,
    item: Mesh,
    sensitivity: number,
    outfit: any
  ) {
    var rotate = false;
    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          if (
            pointerInfo.pickInfo?.hit &&
            outfit.includes(pointerInfo.pickInfo?.pickedMesh?.name)
          ) {
            rotate = true;
          }
          break;
        case PointerEventTypes.POINTERUP:
          if (rotate) {
            rotate = false;
          }
          break;
        case PointerEventTypes.POINTERMOVE:
          if (rotate) {
            item.rotation = new Vector3(
              0,
              pointerInfo.event.clientX * sensitivity,
              0
            );
          }
          break;
        default:
          break;
      }
    });
  }

  private checkDefaultFace(faceItem?: CustomItem) {
    const defaultFace = this._character.meshes.find(
      (mesh) => mesh.name === "Chr_Face_00"
    )!;
    const defaultBody: any = this._character.meshes.find(
      (mesh) => mesh.name === "Chr_Body_00"
    )!;
    const faceTexture = this._scene.getTextureByName(
      `${faceItem?.color ? `Char_${faceItem.color}` : defaultFace.name}`
    );

    defaultFace.isVisible = faceItem?.name ? false : true;
    defaultBody.material.albedoTexture = faceTexture;
  }

  public async saveOutfitToServer(outfit): Promise<void> {
    const hairId = outfit.find((item) => item.type === "hair")?.name;
    const hairColor = outfit.find((item) => item.type === "hair")?.color;
    const faceId = outfit.find((item) => item.type === "face")?.name;
    const faceColor = outfit.find((item) => item.type === "face")?.color;
    const topId = outfit.find((item) => item.type === "top")?.name;
    const bottomId = outfit.find((item) => item.type === "bottom")?.name;
    const shoesId = outfit.find((item) => item.type === "shoes")?.name;
    const suitId = outfit.find((item) => item.type === "suit")?.name;
    const glassId = outfit.find((item) => item.type === "glasses")?.name;
    const hatId = outfit.find((item) => item.type === "hat")?.name;
    const earringId = outfit.find((item) => item.type === "earring")?.name;
    const earringColor = outfit.find((item) => item.type === "earring")?.color;
    const otherId = outfit.find((item) => item.type === "bag")?.name;
    const necklaceId = outfit.find((item) => item.type === "necklace")?.name;

    const payload = {
      skinId: "",
      skinColor: "",
      hairId: hairId || "",
      hairColor: hairColor || "",
      faceId: faceId || "",
      faceColor: faceColor || "",
      bodyId: topId || "",
      bodyColor: "",
      bottomId: bottomId || "",
      bottomColor: "",
      shoesId: shoesId || "",
      shoesColor: "",
      suitId: suitId || "",
      suitColor: "",
      glassId: glassId || "",
      glassColor: "",
      hatId: hatId || "",
      hatColor: "",
      earringId: earringId || "",
      earringColor: earringColor,
      otherId: otherId || "",
      necklaceId: necklaceId || "",
    };

    await saveOutfit(payload);
  }

  public async loadCharacterOutfit() {
    const response = await getOutfit();
    if (response.data) {
      const data = new AssestController(this._scene).transformOutfit(
        response.data,
        this._character.items
      );
      this.showOutfit(data);
    } else this.showOutfit([]);
  }

  public loadCharacterOutfitFromServer(data: ICharacterInfo) {
    const transformedData = new AssestController(this._scene).transformOutfit(
      data,
      this._character.items
    );
    this.showOutfit(transformedData);
  }

  public setOutfit(selectedItems: CustomItem[]) {
    this.showOutfit(selectedItems);
  }

  public showOutfit(items: CustomItem[]) {
    this._outfit = items;
    const nameArray = this.getNameArray(items);
    const faceItem = items.find((el) => el.type === "face");
    this._character.meshes.forEach((mesh: any) => {
      if (nameArray.includes(mesh.name)) {
        mesh.isVisible = true;

        const newItem = items.find((el) => el.name === mesh.name)!;
        if (newItem) {
          const texture = this._scene.getTextureByName(
            `${newItem.color ? `Char_${newItem.color}` : mesh.name}`
          );
          mesh.material.albedoTexture = texture;
        }
      } else {
        mesh.isVisible = false;
      }
    });
    this.checkDefaultFace(faceItem);
  }

  public saveOutfit() {
    this._character.outfit = this._outfit;
    this.saveOutfitToServer(this._outfit);
  }

  public getNameArray = (items: CustomItem[]) => {
    const nameArr: string[] = [...basicMesh];
    items?.map((el) => {
      return nameArr.push(el.name);
    });
    return nameArr;
  };

  public loadOutfit(store) {
    const outfit = this._character.outfit;
    this.showOutfit(outfit || []);
  }

  public saveNickname(nickname: string, callback: () => void) {
    updateNickname({name: nickname}).then((response) => {
      if (response.status === 'SUCCESS') {
        callback();
      }
    });
  }
}
