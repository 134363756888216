import React from "react";

function CloseIcon(props) {
  const { className, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#6C6C6C"
        d="M12.495 10l6.983-6.983a1.766 1.766 0 00-2.495-2.5L10 7.502 3.017.518a1.767 1.767 0 10-2.5 2.499L7.502 10 .518 16.983a1.767 1.767 0 102.499 2.5L10 12.498l6.983 6.983a1.767 1.767 0 002.5-2.499L12.494 10z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
