import { useParticipant } from "@livekit/react-components";
import { TypoGraphy } from "components/typography";
import { Participant } from "livekit-client";
import { Avatar } from "./Avatar";
import { UserViewFrame } from "./styles/Video";
import { VideoRenderer } from "./VideoRenderer";

interface ParticipantViewProps {
  participant: Participant;
  isMobile: boolean;
  avatar?: string;
}

export const ParticipantView: React.FC<ParticipantViewProps> = ({
  participant,
  isMobile,
  avatar,
}) => {
  const { cameraPublication, isLocal, isSpeaking } =
    useParticipant(participant);

  if (
    cameraPublication?.isSubscribed &&
    cameraPublication?.track &&
    !cameraPublication?.isMuted
  ) {
    return (
      <UserViewFrame isMobile={isMobile}>
        <VideoRenderer
          track={cameraPublication.track}
          isLocal={isLocal}
          isSpeaking={isSpeaking}
        />
      </UserViewFrame>
    );
  } else {
    return (
      <UserViewFrame isMobile={isMobile}>
        <Avatar
          avatar={avatar}
          size={isMobile ? "small" : "large"}
          state="active"
          visibleActive={false}
          noneHover={true}
        />
        <TypoGraphy
          typographyType="Default"
          variant={isMobile ? "Caption2" : "Body1"}
          color="#D8D8D8"
        >
          {participant.name ? participant.name : "unknown"}
        </TypoGraphy>
      </UserViewFrame>
    );
  }
};
