import styled from "styled-components";

interface PlaceCardItemProps {
  isMobile: boolean;
}

export const PlaceCardItem = styled.div<PlaceCardItemProps>`
  cursor: pointer;
  position: relative;
  .card-img {
    margin-bottom: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }

  .title {
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "25px")};
    font-family: "Godo M";
  }
`;
