import { createGlobalStyle } from "styled-components";
import "styles/fonts/font.css";

const GlobalStyle = createGlobalStyle`
:root {
  --toastify-color-success:#15979e;
  --toastify-color-error:#ff0000;
  --toastify-color-warning:#E78326;
  --toastify-color-info:#007aff;

  html, body {
    margin: 0;
    padding: 0;
    width:100vw;
    height:100vh;
  }
 
  #root{
    width:100%;
    height:100%;
    overflow: hidden;
    scroll-behavior: smooth;

    #scene-explorer-host {
      z-index: 10;
      position: fixed !important;
    }

    #inspector-host {
      position: fixed !important;
      z-index: 10;
    }
  }
}
`;

export default GlobalStyle;
