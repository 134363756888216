import { UserSettingContext } from "context/UserSettingContext";
import { createLocalAudioTrack, createLocalVideoTrack, Participant, RemoteParticipant, VideoPresets } from "livekit-client";
import { useCallback, useContext, useEffect, useState } from "react";
import { RemoteVideo } from "../RemoteVideo";
import { MeetingStageProps } from "./MeetingStage";

export const Meeting: React.FC<MeetingStageProps> = ({
  roomState,
  isMobile
}) => {
  const { participants, room } = roomState;
  const [ users, setUsers ] = useState<Map<string,Participant>>(new Map<string,Participant>());
  const { video, mic, cameraDevice, micDevice, resoultion } = useContext(UserSettingContext);

  const onParticipantConnected = useCallback((participant: RemoteParticipant) => {
    setUsers((users) => new Map(users.set(participant.identity, participant)))
  },[])

  const onParticipantDisConnected = useCallback((participant: RemoteParticipant) => {
    setUsers((users) => {
      users.delete(participant.identity);
      return new Map(users);
    })
  },[])

  useEffect(() => {
    setUsers(new Map<string, Participant>(participants.map((participant) => [participant.identity, participant])));
  }, [participants]);

  /* useEffect(() => {
    if(participants.length > 0 && requireInit && room) {
      room.localParticipant.setCameraEnabled(video);
      room.localParticipant.setMicrophoneEnabled(mic);
      if (room.localParticipant.isMicrophoneEnabled && mic === room.localParticipant.isMicrophoneEnabled
        && room.localParticipant.isCameraEnabled && video === room.localParticipant.isCameraEnabled
        ) setRequireInit(false);
    } else if (!requireInit && participants.length === 0) setRequireInit(true);
  }, [room, participants])

  useEffect(() => {
    if (room) {
      room.localParticipant.setCameraEnabled(video);
      room.localParticipant.setMicrophoneEnabled(mic);
    }
  },[video, mic]) */

  useEffect(() => {
    const videoTracks = room?.localParticipant.videoTracks;
    if (videoTracks) {
      const localTrackPublications = Array.from(videoTracks.values());
      let mediaStreamTraks: MediaStreamTrack[] = [];
      localTrackPublications.forEach((value) => {
        if (value.videoTrack?.mediaStreamTrack){
          mediaStreamTraks.push(value.videoTrack?.mediaStreamTrack)
        }
      })
      room?.localParticipant.unpublishTracks(mediaStreamTraks).then(() => {
        if (video) {
          createLocalVideoTrack({
            facingMode: "user",
            resolution: VideoPresets[resoultion],
            deviceId: cameraDevice?.deviceId
          }).then((track) => room?.localParticipant.publishTrack(track));
        }
      });
    }
  }, [video, cameraDevice, resoultion])

  useEffect(() => {
    const audioTracks = room?.localParticipant.audioTracks;
    if (audioTracks) {
      const localTrackPublications = Array.from(audioTracks.values());
      let mediaStreamTraks: MediaStreamTrack[] = [];
      localTrackPublications.forEach((value) => {
        if (value.audioTrack?.mediaStreamTrack){
          mediaStreamTraks.push(value.audioTrack?.mediaStreamTrack)
        }
      })
      room?.localParticipant.unpublishTracks(mediaStreamTraks).then((values) => {
        if (mic) {
          createLocalAudioTrack({
            echoCancellation: true,
            noiseSuppression: true,
            deviceId: micDevice?.deviceId            
          }).then((track) => room?.localParticipant.publishTrack(track));
        }
      });
    }
  }, [room?.localParticipant, mic, micDevice])

  useEffect(() => {
    room?.addListener('participantConnected', onParticipantConnected);
    room?.addListener('participantDisconnected', onParticipantDisConnected);
    return () => {
      room?.removeAllListeners('participantConnected');
      room?.removeAllListeners('participantDisconnected');
    }
  }, [room, onParticipantConnected, onParticipantDisConnected]);

  return (
    <RemoteVideo
      participants={Array.from(users.values())}
      isMobile={isMobile}
    />
  );
}