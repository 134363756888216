import { Box } from "@mui/material";
import styled from "styled-components";

export const ShowroomWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "40%")};
  position: absolute;
  bottom: 0;
  height: ${({ isMobile }) => (isMobile ? "50%" : "calc(100% - 60px)")};
  background: #ffcda7;
`;

export const TabWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? "8px" : "20px")};
  height: ${({ isMobile }) => `calc(100% - ${isMobile ? 24 : 40}px)`};
  display: flex;
  flex-direction: column;
`;

export const TabList = styled.div`
  display: flex;
  align-items: center;
  background: #ffff;
  box-shadow: 8px 8px 16px rgba(173, 79, 38, 0.25),
    -8px -8px 16px rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  height: ${({ isMobile }) => (isMobile ? "40px" : "45px")};
  padding: 0 4px;
`;

export const SubTabList = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: ${({ isMobile }) => (isMobile ? "24px" : "35px")};
  padding: 0 4px;
  gap: 5px;
`;

export const Tab = styled.div`
  border-radius: 50px;
  background: ${({ active }) => (active ? "rgba(251, 158, 88, 0.31)" : "#FFF")};
  color: ${({ active }) => (active ? "#AC4E26" : "#9C9BA7")};
  height: ${({ isMobile }) => (isMobile ? "30px" : "35px")};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "18px")};
  width: ${({ isMobile }) => (isMobile ? "54px" : "110px")};
  cursor: pointer;
`;

export const OptionSection = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  padding-top: ${({ show }) => show && "20px"};
  gap: 10px;
`;

export const SkinPicker = styled(Box)`
  .skin-picker {
    display: ${({ show }) => (show ? "block" : "none")};
    padding-top: ${({ show }) => show && "20px"};
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;

    .skin-container {
      display: flex;
      gap: 10px;
      width: fit-content;
    }
  }

  .skin-picker::-webkit-scrollbar {
    width: 5px;
    height: ${({ isMobile }) => (isMobile ? "5px" : "8px")};
    background-color: white; /* or add it to the track */
    border-radius: 5px;
  }

  /* Add a thumb */
  .skin-picker::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: gray;
  }
`;

export const SubTab = styled.div`
  border-radius: 50px;
  background: ${({ active }) => (active ? "#CDCDCD" : "#FFF")};
  color: #999999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  width: ${({ isMobile }) => (isMobile ? "54px" : "90px")};
  cursor: pointer;
`;

export const TabContent = styled.div`
  display: block;
  overflow-y: auto;
  height: ${({ isMobile }) => (isMobile ? "auto" : "100%")};
  flex: 1;
`;

export const OutfitContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    `repeat(${isMobile ? 3 : 5}, 1fr)`};
  grid-gap: 15px;
  padding-top: 20px;
`;

export const CostumeItem = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border: 3px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.05),
    inset -2px -2px 4px rgba(255, 255, 255, 0.25),
    inset 2px 2px 4px rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  cursor: pointer;
`;

export const CheckedPosition = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
`;

export const TabAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 20px;
`;

export const ShowRoomCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

export const NicknameStyled = styled.div`
  position: absolute;
  right: ${(props) => (props.isMobile ? "10px" : "20px")};
  bottom: ${({ isMobile }) => !isMobile && "30px"};
  top: ${(props) => props.isMobile && "44%"};
`;

export const NicknameSettingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  top: 60px;
`;

export const NicknameTitleWrap = styled.div`
  position: absolute;
  top: ${(props) => (props.isMobile ? "80px" : "45px")};
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Godo B";
  text-shadow: 0px 0px 7px #5c210a;
  font-size: ${(props) => (props.isMobile ? "20px" : "35px")};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NicknameInputWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.isMobile ? "20%" : "7%")};
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.isMobile ? "calc(100% - 30px)" : "100%")};
  height: ${(props) => (props.isMobile ? "60px" : "85px")};
  display: flex;
  align-items: center;
  justify-content: center;

  & > input {
    background: rgba(255, 255, 255, 0.79);
    border: 1px solid #919191;
    box-shadow: 0px 0px 12px rgba(172, 73, 33, 0.4);
    backdrop-filter: blur(7px);
    width: 100%;
    max-width: 600px;
    height: 100%;
    border-radius: 30px;
    padding: 0px 30px;
    font-size: ${(props) => (props.isMobile ? "15px" : "25px")};
  }
`;

export const SaveBtnWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.isMobile ? "calc(20% - 75px)" : "0")};
  left: 50%;
  transform: translate(-50%, -50%);

  & > div {
    width: 167px !important;
    height: 47px !important;
  }
`;

export const CloseBtnWrapper = styled.div`
  margin-left: 26px;
`;

export const CostumeColor = styled.div`
  position: relative;
  width: ${(props) => (props.isMobile ? "40px" : "50px")};
  height: ${(props) => (props.isMobile ? "40px" : "50px")};
  display: flex;
  justify-content: center;
  align-items: center;
  .costume-color {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
  .check-icon {
    position: absolute;
    width: ${(props) => (props.isMobile ? "10px" : "24px")};
    height: ${(props) => (props.isMobile ? "10px" : "24px")};
  }
`;
