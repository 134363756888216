import {
  AbstractMesh,
  ArcRotateCamera,
  FollowCamera,
  Animation,
  FreeCamera,
  Nullable,
  Scene,
  Vector3,
  IAnimationKey,
} from "@babylonjs/core";
export function focusOnItem(
  camera: ArcRotateCamera,
  item: AbstractMesh,
  vector3: Vector3,
  scene: Scene
) {
  const alpha = camera.alpha;
  const beta = camera.beta;
  const radius = camera.radius;
  const target = camera.getTarget().clone();
  const direction = camera.position.subtract(item.absolutePosition).normalize();
  camera.position = direction;
  camera.setTarget(item.absolutePosition);
  camera.focusOn({ min: camera.position, max: vector3, distance: 0 }, true);
  // camera.focusOn([item], true);

  camera.rebuildAnglesAndRadius();
  const animCamAlpha = new Animation(
    "animCam",
    "alpha",
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );
  const keysAlpha: IAnimationKey[] = [];
  keysAlpha.push({
    frame: 0,
    value: alpha,
  });
  keysAlpha.push({
    frame: 100,
    value: Math.PI / 2,
  });
  const animCamBeta = new Animation(
    "animCam",
    "beta",
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const keysBeta: IAnimationKey[] = [];
  keysBeta.push({
    frame: 0,
    value: beta,
  });
  keysBeta.push({
    frame: 100,
    value: Math.PI / 2.5,
  });
  const animCamRadius = new Animation(
    "animCam",
    "radius",
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const keysRadius: IAnimationKey[] = [];
  keysRadius.push({
    frame: 0,
    value: radius,
  });
  keysRadius.push({
    frame: 100,
    value: camera.radius,
  });

  const animCamTarget = new Animation(
    "animTarget",
    "_target",
    30,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const keysTarget: IAnimationKey[] = [];
  keysTarget.push({
    frame: 0,
    value: target,
  });
  keysTarget.push({
    frame: 100,
    value: camera.target.clone(),
  });
  animCamAlpha.setKeys(keysAlpha);
  animCamBeta.setKeys(keysBeta);
  animCamRadius.setKeys(keysRadius);
  animCamTarget.setKeys(keysTarget);

  camera.animations.push(animCamAlpha);
  camera.animations.push(animCamBeta);
  camera.animations.push(animCamRadius);
  camera.animations.push(animCamTarget);

  camera.alpha = alpha;
  camera.beta = beta;
  camera.radius = radius;
  camera.target.copyFrom(target);
  scene.beginAnimation(camera, 0, 100, false, 2);
}
