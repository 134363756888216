import styled from "styled-components";

interface ChatBarFrameProps {
  focus: boolean;
  enableBlink: boolean;
  isMobile: boolean;
}

export const ChatBarFrame = styled.div<ChatBarFrameProps>`
  z-index: 1;
  display: grid;
  position: relative;
  padding: 10px 5px;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  
  ${({isMobile}) => {
    const button_space = isMobile ? '45px' : '70px';
    const width = isMobile ? '332px' : '400px';
    const height = isMobile ? '46px' : '50px';
    return `
      grid-template-columns: auto ${button_space};
      width: ${width};
      height: ${height};
    `
  }}

  caret-color: #979797;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;

  border-width: 1px;
  border-color: black;

  opacity: ${({focus}) => focus ? 1 : 0.5};
  `
  // animation: ${({enableBlink}) => enableBlink ? ChatBarFade : ''} 1s step-end infinite;