import { useGoogleLogin } from "@react-oauth/google";
import {
  LoginWithGoogle,
  LoginWithNaver,
} from "api-manager/services/AuthServices/AuthServices";
import backgroundImg from "assets/img/siheung/background.png";
import herotoroImg from "assets/img/siheung/herotoro.png";
import { SocialLoginButton, Stack, TypoGraphy } from "components";
import { Button } from "components/buttons/common/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackgroundImg, HerotoroImg, ImgWrapper, Wrapper } from "./styled";
import NaverLogin from "./NaverLogin";
import { properties } from "api-manager/resource/Properties";
import { MetaverseStore } from "stores/MetaverseStore";
import { SocialResponse } from "api-manager/services/AuthServices/AuthInterface";
import { ApiBaseResponse } from "api-manager/interfaces/FetchInterface";
interface SignInProps {
  store: MetaverseStore;
}
const SiginIn = (props: SignInProps) => {
  const { store } = props;
  const { userStore } = store;
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  const onClickEnter = () => {
    navigate("/metaverse");
  };
  const onClickLogout = () => {
    setIsLogin(false);
  };

  useEffect(() => {
    if (userStore.token) {
      setIsLogin(true);
    }
  }, []);

  const handleLoginNaver = async (naverResponse) => {
    const response: ApiBaseResponse = await LoginWithNaver(
      naverResponse.accessToken
    );
    if (response.data.accessToken) {
      const data: SocialResponse = response.data;
      const { accessToken, refreshToken } = data;
      userStore.checkinUserStatus(accessToken);
      userStore.setRefreshToken(refreshToken);
      setIsLogin(true);
    }
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: async (successResponse) => {
      const response = await LoginWithGoogle(successResponse);
      console.log(response);
      if (response.data.accessToken) {
        const data: SocialResponse = response.data;
        const { accessToken, refreshToken } = data;
        userStore.checkinUserStatus(accessToken);
        userStore.setRefreshToken(refreshToken);
        setIsLogin(true);
      }
    },
  });

  return (
    <Wrapper>
      {isLogin ? (
        <>
          <ImgWrapper>
            <BackgroundImg src={backgroundImg} />
            <HerotoroImg src={herotoroImg} />
          </ImgWrapper>
          <TypoGraphy
            typographyType="Default"
            variant="Headline1"
            color="#5F5F5F"
          >
            사용자님 시흥시에 오신 것을 환영합니다.
          </TypoGraphy>
          <Stack direction="column" spacing={40} />
          <Button onClick={onClickEnter} buttonType="">
            <TypoGraphy typographyType="" variant="Headline1">
              메타버스 접속하기
            </TypoGraphy>
          </Button>
          <Stack direction="column" spacing={16} />
          <Button onClick={onClickLogout}>
            <TypoGraphy typographyType="Default" variant="Headline1">
              로그아웃
            </TypoGraphy>
          </Button>
        </>
      ) : (
        <>
          <TypoGraphy typographyType="Default" variant="LargeTitle">
            로그인
          </TypoGraphy>
          <Stack direction="column" spacing={16} />
          <TypoGraphy
            typographyType="Default"
            variant="Headline1"
            color="#5F5F5F"
          >
            사용자님 시흥시에 오신 것을 환영합니다.
          </TypoGraphy>
          <Stack direction="column" spacing={40} />
          <NaverLogin
            clientId={properties.NAVER_ID}
            callbackUrl={properties.NAVER_CALLBACK_URL}
            render={(props) => (
              <SocialLoginButton type="naver" onClick={props.onClick} />
            )}
            onSuccess={handleLoginNaver}
            onFailure={(error) => console.error(error)}
          />
          <Stack direction="column" spacing={16} />
          <SocialLoginButton type="google" onClick={handleLoginGoogle} />
        </>
      )}
    </Wrapper>
  );
};

export default SiginIn;
