function TextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#D1D1D1"
        d="M5.118 13.038h8.298v-2.075H5.118v2.075zm0-3.112h12.447V7.852H5.118v2.074zm0-3.111h12.447V4.74H5.118v2.075zM.97 21.335V2.665c0-.57.203-1.058.61-1.465.406-.406.894-.61 1.464-.609H19.64c.57 0 1.06.204 1.466.61.406.407.61.895.609 1.465v12.446c0 .57-.204 1.06-.61 1.466-.407.406-.895.61-1.465.608H5.12l-4.15 4.15z"
      ></path>
    </svg>
  );
}

export default TextIcon;