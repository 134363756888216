import { useContext, useState } from "react";
import styled from "styled-components";
import { back_circle } from "assets/img/icon/v1/index";
import close_icon from "assets/img/icon/v2/close.svg";
import { StateButton } from "./buttons/StateButton";
import { SideBarButtons } from "./buttons/SideBarButtons";
import { ClosedSideBar } from "./ClosedSideBar";
import { UserListItem } from "./UserListItem";
import { TypoGraphy } from "components/typography";
import { useAudio } from "hooks/videochat/useAudio";
import { TModal, TModalParam } from "./types";
import { inject, observer } from "mobx-react";
import { CommonStyleProps } from "components/chat/types";
const audio = require("assets/audio/gen_item_get.mp3");

interface SideBarFrameProps extends CommonStyleProps {
  minimode: boolean;
}

const SideBarFrame = styled.div<SideBarFrameProps>`
  box-sizing: border-box;

  position: absolute;

  ${({ minimode, isMobile }) => {
    const width = isMobile ? (minimode ? "70px" : "160px") : "285px";
    const height = isMobile ? (minimode ? "440px" : "490px") : "530px";
    const position = isMobile ? "10px" : "64px";
    return `
      width: ${width};
      height: ${height};
      top: ${position};
      left: ${position};
    `;
  }}

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  padding: 20px 15px;
  ${({ minimode }) => {
    if (minimode) {
      return `padding: 10px 2.5px;`;
    } else {
      return `padding: 20px 15px;`;
    }
  }}

  display: grid;
  /* grid-template-rows: 72px 40px 277px; */
  grid-template-rows: ${({ minimode, isMobile }) =>
      isMobile ? (minimode ? "60px" : "93px") : "72px"} 40px 277px;
  gap: 20px 0px;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  z-index: 1;
`;
const MobileCloseButtonFrame = styled.div`
  position: absolute;
  top: 35.5px;
  right: 17.5px;

  &:hover {
    cursor: pointer;
  }
  z-index: 5;
`;
const CloseButtonFrame = styled.div`
  position: absolute;
  bottom: 20px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 7px;

  &:hover {
    cursor: pointer;
  }
`;

const CloseMobileButton = styled.div<{ closed: boolean }>`
  position: absolute;
  top: -38px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;

  width: 10px;
  height: 10px;

  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 13px;

  &:hover {
    cursor: pointer;
    background: rgba(95, 99, 104, 0.1);
  }
`;

const BackImage = styled.img<{ minimode: boolean }>`
  ${({ minimode }) => {
    if (minimode) {
      return `
        width: 30px;
        transform: scaleX(-1);
      `;
    }
  }}
`;

export interface ISideBar extends CommonStyleProps {
  onChangeModal: (modal: TModal, blur?: boolean, param?: TModalParam) => void;
  closeModal: () => void;
  store?: any;
}

export const SideBar = inject("store")(
  observer((props: ISideBar) => {
    const { visible, isMobile, onChangeModal, closeModal, store } = props;
    const { userStore } = store;
    const [closed, setClosed] = useState<boolean>(false);
    const [minimode, setMinimode] = useState<boolean>(false);

    const [playAudio] = useAudio(audio);

    const onToggle = (flag: boolean) => {
      playAudio();
      setClosed(flag);
    };

    return (
      <>
        <SideBarFrame
          visible={visible && !closed}
          minimode={minimode}
          isMobile={isMobile}
        >
          {isMobile ? (
            <MobileCloseButtonFrame
              onClick={() => {
                closeModal();
                onToggle(true);
              }}
            >
              <CloseMobileButton>
                <img width={16} height={16} src={close_icon} alt="" />
              </CloseMobileButton>
            </MobileCloseButtonFrame>
          ) : (
            <></>
          )}
          <UserListItem
            isMobile={isMobile}
            onChangeModal={onChangeModal}
            minimode={minimode}
            store={store}
          />
          <StateButton
            state={userStore.user.state || "disabled"}
            onChangeModal={onChangeModal}
            minimode={minimode}
            isMobile={isMobile}
          />
          {/* <UserList
          userId={me?.id || ""}
          onChangeModal={onChangeModal}
        /> */}
          <SideBarButtons
            onChangeModal={onChangeModal}
            minimode={minimode}
            isMobile={isMobile}
          />
          <CloseButtonFrame
            onClick={() => {
              if (isMobile) {
                playAudio();
                setMinimode((prev) => !prev);
              } else {
                closeModal();
                onToggle(true);
              }
            }}
          >
            <BackImage src={back_circle} alt="" minimode={minimode} />
            {minimode ? (
              <></>
            ) : (
              <TypoGraphy variant="Body2" color="#979797">
                {isMobile ? "Mini Mode" : "Hide"}
              </TypoGraphy>
            )}
          </CloseButtonFrame>
        </SideBarFrame>
        <ClosedSideBar
          isMobile={isMobile}
          visible={closed}
          onClick={() => {
            closeModal();
            onToggle(false);
          }}
        />
      </>
    );
  })
);
