import { Box } from "@mui/material";
import styled from "styled-components";

interface PlaceContentProps {
  isMobile: boolean;
}

export const PlaceContent = styled(Box)<PlaceContentProps>`
  margin-bottom: ${({ isMobile }) => (isMobile ? "70px" : "50px")};
  text-align: center;
  .title {
    font-family: "Godo B";
    font-size: ${({ isMobile }) => (isMobile ? "30px" : "70px")};
    font-weight: 600;
    margin-bottom: ${({ isMobile }) => (isMobile ? "20px" : "30px")};
  }
  .description {
    font-family: "Godo M";
    font-size: ${({ isMobile }) => (isMobile ? "15px" : "25px")};
    color: #444444;
  }
`;
