import {
  ArcRotateCamera,
  Engine,
  Scene,
  FreeCamera,
  FollowCamera,
} from "@babylonjs/core";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import { UserVideoChatWay } from "context/UserVideoChatContext";
import { MetaverseStore } from "stores/MetaverseStore";
import { getDeviceDetect, DeviceDetectInterface } from "utils/WindowDimensions";
import { SceneState } from "./SceneManager/SceneInterface";
import { Inspector } from "./utils/Inspector";
import { LivingLab } from "./SceneManager/LivingLab";
import { when } from "mobx";
import { Lounge } from "./SceneManager/Lounge";
import { Gwanghwamun } from "./SceneManager/Gwanghwamun";
import { AliceGarden } from "./SceneManager/AliceGarden";

export default class MetaverseInitialize {
  private _canvas: HTMLCanvasElement;
  private _engine: Engine;
  private _scene: Scene;
  private _store: MetaverseStore;

  private _device: DeviceDetectInterface;
  private _onChangeUserSpace: (type: UserVideoChatWay, data: string) => void;
  private _setLoadingProgress;
  constructor(
    canvas: HTMLCanvasElement,
    store: MetaverseStore,
    onChangeUserSpace: (type: UserVideoChatWay, data: string) => void,
    setLoadingProgress
  ) {
    this._setLoadingProgress = setLoadingProgress;
    this._store = store;
    this._canvas = canvas;
    this._device = getDeviceDetect();
    // init scene
    this._engine = new Engine(this._canvas, true, undefined, true);
    this._scene = new Scene(this._engine);
    this._init();
    // it will run if fist function return true then second function trigger to re-render
    when(
      () => this._store.sceneStore.isReRender,
      () => {
        this._init();
        this._store.sceneStore.stopReRender();
      }
    );
    this._onChangeUserSpace = onChangeUserSpace;
  }

  private async _init(): Promise<void> {
    await this.renderScene();
    this.handleWindowResize();
  }

  private async getInitScene() {
    switch (this._store.sceneStore.currentScene) {
      case SceneState.LOUNGE:
        return await this._goToLivingMap();
      case SceneState.INTERNET_CAFE:
        return await this._goToLoungeMap();
      case SceneState.PALACE:
        return await this._goToGwanghwamunMap();
      case SceneState.ALICE:
        return await this._goToAliceGardenMap();
      default:
        return await this._goToLivingMap();
    }
  }

  private async renderScene(): Promise<void> {
    await this.getInitScene();
    // Register a render loop to repeatedly render the scene
    this._engine.runRenderLoop(() => {
      switch (this._store.sceneStore.currentScene) {
        case SceneState.LOUNGE:
        case SceneState.PALACE:
        case SceneState.ALICE:
        case SceneState.INTERNET_CAFE:
          this._scene.render();
          break;
        default:
          break;
      }
    });
  }

  private async LoadScene(
    scene: Scene,
    camera: ArcRotateCamera | FreeCamera | FollowCamera
  ) {
    this._scene.activeCamera = camera;
    await scene.whenReadyAsync();
    this._scene.dispose();
    this._scene = scene;
    Inspector(scene);
    this._store.setIsLoading(false);
  }

  private handleWindowResize(): void {
    //resize if the screen is resized/rotated
    window.addEventListener("resize", () => {
      this._device = getDeviceDetect();
      this._engine.resize();
    });
  }

  private async _goToLivingMap(): Promise<void> {
    return await LivingLab({
      engine: this._engine,
      store: this._store,
      onChangeScene: () => {
        window.location.href = "/character";
      },
      onLoad: (scene: Scene, camera: ArcRotateCamera) =>
        this.LoadScene(scene, camera),
      onChangeUserSpace: (type: UserVideoChatWay, data: string) =>
        this._onChangeUserSpace(type, data),
      onProgress: (value) => this._setLoadingProgress(value),
    });
  }

  private async _goToLoungeMap(): Promise<void> {
    return await Lounge({
      engine: this._engine,
      store: this._store,
      onChangeScene: () => {
        window.location.href = "/character";
      },
      onLoad: (scene: Scene, camera: ArcRotateCamera) =>
        this.LoadScene(scene, camera),
      onChangeUserSpace: (type: UserVideoChatWay, data: string) =>
        this._onChangeUserSpace(type, data),
      onProgress: (value) => this._setLoadingProgress(value),
    });
  }

  private async _goToGwanghwamunMap(): Promise<void> {
    return await Gwanghwamun({
      engine: this._engine,
      store: this._store,
      onChangeScene: () => {
        window.location.href = "/character";
      },
      onLoad: (scene: Scene, camera: ArcRotateCamera) =>
        this.LoadScene(scene, camera),
      onChangeUserSpace: (type: UserVideoChatWay, data: string) =>
        this._onChangeUserSpace(type, data),
      onProgress: (value) => this._setLoadingProgress(value),
    });
  }

  private async _goToAliceGardenMap(): Promise<void> {
    return await AliceGarden({
      engine: this._engine,
      store: this._store,
      onChangeScene: () => {
        window.location.href = "/character";
      },
      onLoad: (scene: Scene, camera: ArcRotateCamera) =>
        this.LoadScene(scene, camera),
      onChangeUserSpace: (type: UserVideoChatWay, data: string) =>
        this._onChangeUserSpace(type, data),
      onProgress: (value) => this._setLoadingProgress(value),
    });
  }

  private choseScene() {
    switch (this._store.sceneStore.lastMap) {
      case SceneState.LOUNGE:
        return this._goToLivingMap();
      case SceneState.INTERNET_CAFE:
        return this._goToLoungeMap();
      case SceneState.PALACE:
        return this._goToGwanghwamunMap();
      case SceneState.ALICE:
        return this._goToAliceGardenMap();
      default:
        return this._goToLivingMap();
    }
  }
}
