import { ApiBaseResponse } from "api-manager/interfaces/FetchInterface";
import axios, { AxiosResponse } from "axios";
import { deleteCookie, getCookie } from "utils/SessionStorageHelper";

const handleSuccessResponse = (
  response: AxiosResponse<any, any>
): ApiBaseResponse => {
  console.log("handleSuccessResponse ", response);
  return {
    status: "SUCCESS",
    code: "",
    data: response.data,
  };
};

const handleErrorResponse = (error: any): ApiBaseResponse => {
  // console.log("handleErrorResponse ", error);
  if (error.response?.data?.error === "UnauthorizedException") {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    window.location.href = "/";
  }
  return {
    status: "FAILURE",
    code: error.code,
    message: "Something went wrong!!!",
  };
};

const getRequestHeader = (isFormData?: boolean) => {
  const token = getCookie("accessToken");
  const headers = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };
  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }
  return { headers };
};

export const getData = async (reqUrl: string): Promise<ApiBaseResponse> => {
  try {
    const reqHeader = getRequestHeader();
    const response = await axios.get(reqUrl, reqHeader);
    return handleSuccessResponse(response);
  } catch (error) {
    return handleErrorResponse(error);
  }
};

export const postData = async (
  reqUrl: string,
  payload: any
): Promise<ApiBaseResponse> => {
  try {
    const reqHeader = getRequestHeader();
    const response = await axios.post(reqUrl, payload, reqHeader);
    return handleSuccessResponse(response);
  } catch (error) {
    return handleErrorResponse(error);
  }
};

export const patchData = async (
  reqUrl: string,
  payload: any
): Promise<ApiBaseResponse> => {
  try {
    const reqHeader = getRequestHeader();
    const response = await axios.patch(reqUrl, payload, reqHeader);
    return handleSuccessResponse(response);
  } catch (error) {
    return handleErrorResponse(error);
  }
};

export const postFormData = async (
  reqUrl: string,
  payload: FormData
): Promise<ApiBaseResponse> => {
  try {
    const reqHeader = getRequestHeader(true);
    const response = await axios.post(reqUrl, payload, reqHeader);
    return handleSuccessResponse(response);
  } catch (error) {
    return handleErrorResponse(error);
  }
};
