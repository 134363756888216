import { action, observable } from "mobx";

export interface IJoystickDeltaData {
  type: string;
  x: number | null;
  y: number | null;
  direction?: string | null;
  distance?: number | null;
}

export class MovementStore {
  @observable private _joystickDeltaData: IJoystickDeltaData;
  private _jumpingState: boolean;
  private _mouseMoveInterval: NodeJS.Timer | null = null;

  constructor() {
    this._joystickDeltaData = {
      type: "stop",
      x: null,
      y: null,
      direction: null,
      distance: null,
    };
    this._jumpingState = false;
  }

  @action
  public setJoystickDeltaData(object: IJoystickDeltaData): void {
    this._joystickDeltaData = object;
  }
  public setJumpingState(state: boolean): void {
    this._jumpingState = state;
  }
  public setMouseMoveIntervalState(state: NodeJS.Timer | null): void {
    this._mouseMoveInterval = state;
  }

  get joystickDeltaData(): IJoystickDeltaData {
    return this._joystickDeltaData;
  }
  get jumpingState(): boolean {
    return this._jumpingState;
  }
  get mouseMoveInterval(): NodeJS.Timer | null {
    return this._mouseMoveInterval;
  }
}
