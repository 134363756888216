import axios from "axios"
import { UserChatState } from "components/sidebar/living/types";
import { ParticipantInfo } from "livekit-client/dist/src/proto/livekit_models";

export interface MediaMetaData {
  state?: UserChatState;
  stateMessage?: string;
}

export interface GetTestVideoTokenDto {
  userId: string;
  userNickname: string;
  room: string;
}

interface CreateTokenDTO {
  apiUserToken: string;
  nickname: string;
  room: string;
  canPublish?: boolean;
  canPublishData?: boolean;
  state?: UserChatState;
  stateMessage?: string;
}

export const getMediaToken = (dto: CreateTokenDTO) => {
  return new Promise<string>((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_API_MEDIA_HOST}/token`, dto)
      .then((res) => res.data)
      .then(resolve)
      .catch(reject)
  })
}

export const updateParticipant = (apiUserToken: string, room: string, metaData: MediaMetaData) => {
  return new Promise<ParticipantInfo>((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_API_MEDIA_HOST}/room/participant`,{
      apiUserToken,
      room,
      ...metaData
    })
      .then((res) => res.data)
      .then(resolve)
      .catch(reject)
  })
}