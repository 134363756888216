import { Domain, Endpoint } from "./PropertyInterface";

export const env = "dev"; // can process with .env file process.env

export const domain: Domain = {
  // dev: "https://api.heartverse.xyz",
  dev: "https://babylon-server.heartverse.xyz/user"
};
export const mediaDomain: Domain = {
  // dev: "https://media.heartverse.xyz",
  dev: "https://babylon-server.heartverse.xyz/media",
};
export const realTimeDomain: Domain = {
  // dev: "wss://game-server.heartverse.xyz",
  // dev: "ws://localhost:2567",
  dev: "wss://babylon-server.heartverse.xyz",
}

const baseURL = domain[env];
const mediaURL = mediaDomain[env];

export const Endpoints: Endpoint = {
  endpoint_auth_google: {
    url: baseURL + "/v1/auth/google/login",
  },
  endpoint_auth_naver: {
    url: baseURL + "/v1/auth/naver/login",
  },
  endpoint_costume: {
    url: baseURL + "/v1/accessories",
  },
  endpoint_upload_avatar: {
    url: baseURL + "/v1/file/avatar",
  },
  endpoint_user_profile: {
    url: baseURL + "/v1/users/profile",
  },
  endpoint_refresh_token: {
    url: baseURL + "/v1/auth/refresh-token",
  },
  endpoint_get_media_token: {
    url: mediaURL + "/token",
  },
};
