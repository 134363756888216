import { SignInPage } from "pages";
import { ShowroomContainer } from "pages/character/ShowroomContainer";
import { MainPage } from "pages/main/MainPageComponent";
import MetaversePage from "pages/metaverse/MetaversePage";
import { PlacesPage } from "pages/places/PlacesPage";
import { ContactUsPage } from "pages/contact-us/ContactUsPage";
import { Navigate } from "react-router-dom";

export const NAVIGATION_ROUTER = {
  DEFAULT: "/",
  PLACES: "/places",
  CHARACTER: "/character",
  SIGNIN: "/login",
  METAVERSE: "/metaverse",
  CONTACT_US: "/contact-us",
  ANY: "*",
};

export const router = [
  { path: NAVIGATION_ROUTER.DEFAULT, component: <MainPage /> },
  { path: NAVIGATION_ROUTER.PLACES, component: <PlacesPage /> },
  { path: NAVIGATION_ROUTER.CONTACT_US, component: <ContactUsPage /> },
  { path: NAVIGATION_ROUTER.CHARACTER, component: <ShowroomContainer /> },
  { path: NAVIGATION_ROUTER.SIGNIN, component: <SignInPage /> },
  { path: NAVIGATION_ROUTER.METAVERSE, component: <MetaversePage /> },
  {
    path: NAVIGATION_ROUTER.ANY,
    component: <Navigate to={NAVIGATION_ROUTER.DEFAULT} replace />,
  }, // handle callback response from naver login return callback url
];
