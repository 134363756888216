import {
  AbstractMesh,
  ArcRotateCamera,
  Axis,
  FollowCamera,
  ICrowd,
  Matrix,
  Mesh,
  Nullable,
  PointerEventTypes,
  RecastJSPlugin,
  Scene,
  TransformNode,
  Vector2,
  Vector3,
  Color3,
  StandardMaterial
} from "@babylonjs/core";
import { OnChangeUserSpace } from "context/UserVideoChatContext";
import { MetaverseStore } from "stores/MetaverseStore";
import {
  DeviceDetectInterface,
  getDeviceDetect,
  handleDeviceResize,
} from "utils/WindowDimensions";
import { CAMERA_TYPE } from "../CameraManager/CameraController";
import {
  FCallBackAction,
  FCallBackMouseMove,
  FCallBackMove,
} from "../Network/NetworkHandle";
import { ROOM_SIZE } from "../utils/RoomSize";
import { IJoystickDeltaData } from "stores/MovementStore";
import { AdvancedDynamicTexture, Control } from "@babylonjs/gui";
import { getButton } from "../utils/getButton";
import { SoundTreeItemComponent } from "@babylonjs/inspector/components/sceneExplorer/entities/soundTreeItemComponent";

const navMeshParameters = {
  cs: 0.1,
  ch: 0.1,
  walkableSlopeAngle: 0,
  walkableHeight: 2,
  walkableClimb: 2,
  walkableRadius: 1,
  maxEdgeLen: 12,
  maxSimplificationError: 1.3,
  minRegionArea: 8,
  mergeRegionArea: 20,
  maxVertsPerPoly: 6,
  detailSampleDist: 6,
  detailSampleMaxError: 1,
};

const agentParams = {
  radius: 0.1,
  height: 0.2,
  maxAcceleration: 1000,
  maxSpeed: 1.0,
  collisionQueryRange: 0.5,
  pathOptimizationRange: 0.0,
  separationWeight: 1.0,
};
export interface MouseManager {
  init: () => void;
}

export const MouseController = (
  scene: Scene,
  char: AbstractMesh,
  impostors: Mesh,
  // guiCanvas: GUI.AdvancedDynamicTexture,
  // guiButton: GUI.Button,
  camera: FollowCamera | ArcRotateCamera,
  // map: any,
  callBackMove: FCallBackMove,
  callBackAction: FCallBackAction,
  callBackMouseMove: FCallBackMouseMove,
  // character: Character,
  onChangeUserSpace: OnChangeUserSpace,
  store: MetaverseStore,
  onChangeScene: () => void,
  spamPosition: Vector3
) => {
  const init = (): void => {
    let currentSpeed = 1;
    let inRoom = false;

    let leftMouseDown = false;
    let rightMouseDown = false;
    let _device = getDeviceDetect();
    handleDeviceResize((device: DeviceDetectInterface) => (_device = device));

    // Animation toggler
    let walk = false;
    let run = false;
    let jump = false;

    let isJumping = false;
    const jumpHeight = 0.02;

    // NavigationPlugin
    let navigationPlugin = new RecastJSPlugin();
    let startingPoint: Nullable<Vector3> = char.position;
    const transform = new TransformNode(char.name);
    transform.id = char.id;
    // Animations
    const idleAnim = scene.getAnimationGroupByName("Idle");
    const walkAnim = scene.getAnimationGroupByName("Walk");
    const jumpAnim = scene.getAnimationGroupByName("Jumping");
    const runAnim = scene.getAnimationGroupByName("Run");
    // Nav setup
    navigationPlugin.createNavMesh([impostors], navMeshParameters);
    //Crowd
    const crowd = navigationPlugin.createCrowd(10, 0.1, scene);
    const randomPos = navigationPlugin.getRandomPointAround(
      new Vector3(-14, 0, 4.5),
      0.5
    );
    char.parent = transform;
    crowd.addAgent(randomPos, agentParams, transform);
    crowd.agentTeleport(0, spamPosition);

    callBackMove({
      x: randomPos.x,
      y: randomPos.y,
      z: randomPos.z,
      tX: 0,
      tY: 0,
      tZ: 0,
      tW: currentSpeed,
    });

    // DEBUG FOR NAVIGATION
    // const navMeshDebug = navigationPlugin.createDebugNavMesh(scene);
    // navMeshDebug.position = new Vector3(0, 0, 0);

    // const matDebug = new StandardMaterial("matdebug", scene);
    // matDebug.diffuseColor = new Color3(0.1, 0.2, 1);
    // matDebug.alpha = 0.2;
    // navMeshDebug.material = matDebug;

    const directToPath = (char: AbstractMesh, crowd: ICrowd) => {
      if (crowd.getAgentVelocity(0).length() > 0) {
        char.lookAt(
          Vector3.Normalize(crowd.getAgentVelocity(0)).multiplyByFloats(
            -1,
            -1,
            -1
          )
        );
      }
    };

    const getGroundPosition = (): Nullable<Vector3> => {
      const pickInfo = scene.pick(scene.pointerX, scene.pointerY);
      if (pickInfo.hit) {
        return pickInfo.pickedPoint;
      }
      return null;
    };

    const pointerDown = () => {
      startingPoint = getGroundPosition();
      if (startingPoint) {
        const position = crowd.getAgentPosition(0);
        callBackMove({
          x: position.x,
          y: position.y,
          z: position.z,
          tX: startingPoint.x,
          tY: startingPoint.y,
          tZ: startingPoint.z,
          tW: currentSpeed,
        });
        const target = navigationPlugin.getClosestPoint(startingPoint);
        if (!target.equals(new Vector3(0, 0, 0))) {
          moveAction(target);
        }
      }
    };

    const moveAction = (pos: Vector3) => {
      const agents = crowd.getAgents();
      for (let i = 0; i < agents.length; i++) {
        crowd.agentGoto(agents[i], pos);
      }
    };

    const jumpingAction = () => {
      if (!isJumping) {
        const position = crowd.getAgentPosition(0);
        callBackMove({
          x: position.x,
          y: position.y,
          z: position.z,
          tX: position.x,
          tY: position.y,
          tZ: position.z,
          tW: currentSpeed,
        });
        callBackAction("jump");
        isJumping = true;
        setTimeout(function () {
          let time = 0;
          let intervalID = setInterval(function () {
            const currentX = char.absolutePosition.x;
            const currentY = char.absolutePosition.y;
            const currentZ = char.absolutePosition.z;
            char.setAbsolutePosition(
              new Vector3(currentX, currentY + jumpHeight, currentZ)
            );
            if (++time === 20) {
              window.clearInterval(intervalID);
            }
          }, 10);
        }, 400);
        setTimeout(function () {
          let time = 0;
          let intervalID = setInterval(function () {
            const currentX = char.absolutePosition.x;
            const currentY = char.absolutePosition.y;
            const currentZ = char.absolutePosition.z;

            char.setAbsolutePosition(
              new Vector3(currentX, currentY - jumpHeight, currentZ)
            );
            if (++time === 20) {
              window.clearInterval(intervalID);
            }
          }, 20);
        }, 600);
      }
    };

    const checkRoom = (center: Vector3, size: any, name: string) => {
      const x1 = -center.x + size.x / 2;
      const z1 = center.z + size.z / 2;
      const x2 = -center.x - size.x / 2;
      const z2 = center.z - size.z / 2;
      const x = crowd.getAgentPosition(0).x;
      const z = crowd.getAgentPosition(0).z;
      return x > Math.min(x1, x2) &&
        x < Math.max(x1, x2) &&
        z > Math.min(z1, z2) &&
        z < Math.max(z1, z2)
        ? true
        : false;
    };

    const differencePosition = (current: number, next: number) => {
      let numb = Math.abs(next - current);
      return Math.round((numb + Number.EPSILON) * 10) / 10;
    };

    const rotation = (target: Vector2, angle: number) => {
      return new Vector2(
        target.x * Math.cos(angle) - target.y * Math.sin(angle),
        target.x * Math.sin(angle) + target.y * Math.cos(angle)
      );
    };

    const getCameraAngle = () => {
      const cameraMobile = camera as ArcRotateCamera;
      let angleRadians = -cameraMobile.alpha - Math.PI / 2;
      while (angleRadians < 0) {
        angleRadians += 2 * Math.PI;
      }
      while (angleRadians >= 2 * Math.PI) {
        angleRadians -= 2 * Math.PI;
      }
      return angleRadians;
    };

    let lastJoystickData: IJoystickDeltaData = {
      direction: null,
      distance: null,
      type: "stop",
      x: null,
      y: null,
    };
    let lastJumpingState = false;

    const { movementStore } = store;
    if (movementStore.mouseMoveInterval !== null) {
      clearInterval(movementStore.mouseMoveInterval);
    }
    let lastType = "STOP";
    let lastTypeCount = 0;
    movementStore.setMouseMoveIntervalState(
      setInterval(() => {
        const { joystickDeltaData, jumpingState } = movementStore;
        const angle = getCameraAngle();
        const pos = crowd.getAgentPosition(0);
        const rotated = rotation(new Vector2(pos.x, pos.z), angle);
        if (
          lastJoystickData.x !== joystickDeltaData.x ||
          lastJoystickData.y !== joystickDeltaData.y ||
          lastJoystickData.type !== joystickDeltaData.type ||
          lastJoystickData.distance !== joystickDeltaData.distance ||
          lastJoystickData.direction !== joystickDeltaData.direction ||
          lastJumpingState !== jumpingState
        ) {
          lastTypeCount = 0;
          lastJoystickData = joystickDeltaData;
          lastJumpingState = jumpingState;
          const position = crowd.getAgentPosition(0);
          callBackMouseMove({
            positionX: position.x,
            positionY: position.y,
            positionZ: position.z,
            type: joystickDeltaData.type.toUpperCase(),
            vX: joystickDeltaData.x || 0,
            vY: joystickDeltaData.y || 0,
            direction: joystickDeltaData.direction || "NONE",
            distance: joystickDeltaData.distance || 0,
            rotateX: rotated.x,
            rotateY: rotated.y,
            angle: angle,
            seq: 0,
          });
        }

        if (lastType !== joystickDeltaData.type) {
          lastType = joystickDeltaData.type;
        } else if (lastTypeCount < 6) {
          lastTypeCount++;
        }
        if (lastTypeCount === 5 && lastType === "stop") {
          callBackMouseMove({
            positionX: pos.x,
            positionY: pos.y,
            positionZ: pos.z,
            type: "STOP",
            vX: joystickDeltaData.x || 0,
            vY: joystickDeltaData.y || 0,
            direction: joystickDeltaData.direction || "NONE",
            distance: joystickDeltaData.distance || 0,
            rotateX: rotated.x,
            rotateY: rotated.y,
            angle: angle,
            seq: 0,
          });
        }
      }, 50)
    );

    scene.onBeforeRenderObservable.add(() => {
      const pos = crowd.getAgentPosition(0);
      camera.target = pos.clone().add(new Vector3(0, 0.8, 0));
      // camera.position = transform.position.clone().add(new Vector3(0, 2, -1));
      const { joystickDeltaData, jumpingState } = movementStore;
      const angle = getCameraAngle();
      const rotated = rotation(new Vector2(pos.x, pos.z), angle);
      if (joystickDeltaData.x && joystickDeltaData.y && !isJumping && !jump) {
        const direction = new Vector2(
          joystickDeltaData.x / 5,
          joystickDeltaData.y / 5
        );
        const finalTarget = rotation(rotated.add(direction), -angle);
        const pow =
          Math.pow(joystickDeltaData.x, 2) + Math.pow(joystickDeltaData.y, 2);
        if (pow >= 0.9) {
          crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: 2.0 });
        } else {
          crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: 1.0 });
        }
        moveAction(new Vector3(finalTarget.x, pos.y, finalTarget.y));
      }

      if (walk || run) {
        const cPos = crowd.getAgentPosition(0);
        setTimeout(() => {
          const nPos = crowd.getAgentPosition(0);
          if (
            differencePosition(nPos.x, cPos.x) === 0 &&
            differencePosition(nPos.z, cPos.z) === 0
          ) {
            crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: 0 });
          }
        }, 500);
        const currentRoom = window.localStorage.getItem("room");
        scene.meshes
          .filter((item) => item?.name?.includes("temp"))
          .every((item, index, array) => {
            const size = ROOM_SIZE[0].rooms[item.name];
            if (size && checkRoom(item.position, size, item.name)) {
              if (currentRoom !== item.name) {
                window.localStorage.setItem("room", item.name);
                // onChangeUserSpace("Room", item.name);
                store.userStore.setCurUserRoom(item.name);
                inRoom = true;
              }
              return false;
            } else if (index === array.length - 1) {
              inRoom = false;
            }
            return true;
          });
        if (currentRoom !== "lobby" && !inRoom) {
          window.localStorage.setItem("room", "lobby");
          // onChangeUserSpace("Room", "lobby");
          store.userStore.setCurUserRoom('lobby');
        }
      }

      if ((leftMouseDown && rightMouseDown) || jumpingState) {
        crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: 0 });
        if (!jump && !isJumping) {
          jump = true;
          setTimeout(function () {
            jump = false;
            isJumping = false;
          }, 1920);
        }
      }

      if (crowd.getAgentVelocity(0).length() > 1.3) {
        run = true;
        walk = false;
      } else if (crowd.getAgentVelocity(0).length() > 0) {
        walk = true;
        run = false;
      } else {
        walk = false;
        run = false;
      }

      if (jump) {
        jumpingAction();
        jumpAnim?.play(true);
        walkAnim?.stop();
        idleAnim?.stop();
        runAnim?.stop();
      } else if (walk) {
        jumpAnim?.stop();
        walkAnim?.play(true);
        idleAnim?.stop();
      } else if (run) {
        idleAnim?.stop();
        walkAnim?.stop();
        runAnim?.play(true);
      } else {
        idleAnim?.play(true);
        walkAnim?.stop();
        jumpAnim?.stop();
        runAnim?.stop();
      }
      directToPath(char, crowd);
    });

    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOUBLETAP:
          if (
            pointerInfo.event.button === 2 &&
            pointerInfo.pickInfo?.hit &&
            pointerInfo.pickInfo?.pickedMesh?.name !== char.name &&
            _device?.isDesktop &&
            scene.activeCamera?.id === CAMERA_TYPE.MOBILE &&
            !isJumping &&
            !jump
          ) {
            crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: 2.0 });
            currentSpeed = 2.0;
            pointerDown();
          }
          break;
        case PointerEventTypes.POINTERDOWN:
          setTimeout(function () {
            if (!(leftMouseDown && rightMouseDown)) {
              if (
                pointerInfo.event.button === 2 &&
                pointerInfo.pickInfo?.hit &&
                pointerInfo.pickInfo?.pickedMesh?.name !== char.name &&
                _device?.isDesktop &&
                scene.activeCamera?.id === CAMERA_TYPE.MOBILE &&
                !isJumping &&
                !jump
              ) {
                crowd.updateAgentParameters(0, {
                  ...agentParams,
                  maxSpeed: 1.0,
                });
                currentSpeed = 1.0;
                pointerDown();
              }
            }
          }, 50);
          leftMouseDown = pointerInfo.event.button === 0 ? true : leftMouseDown;
          rightMouseDown =
            pointerInfo.event.button === 2 ? true : rightMouseDown;
          break;
        case PointerEventTypes.POINTERUP:
          leftMouseDown =
            pointerInfo.event.button === 0 ? false : leftMouseDown;
          rightMouseDown =
            pointerInfo.event.button === 2 ? false : rightMouseDown;
          break;
        default:
          break;
      }
    });
  };

  return {
    init,
  };
};
