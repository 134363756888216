import { Track } from "livekit-client";
import { useEffect, useRef } from "react";
import { LocalStream } from "./styles/Video";

interface VideoRendererProps {
  track: Track;
  isLocal: boolean;
  isSpeaking: boolean;
}

export const VideoRenderer:React.FC<VideoRendererProps> = ({
  track,
  isLocal,
  isSpeaking
}) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);
    };
  }, [track, ref]);

  return <LocalStream ref={ref} autoPlay={true} />
}