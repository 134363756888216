import { AudioRenderer, RoomState } from "@livekit/react-components"
import { Meeting } from "./Meeting"

export interface MeetingStageProps {
  roomState: RoomState;
  isMobile: boolean;
}

export const MeetingStage: React.FC<MeetingStageProps> = (props) => {
  return (
    <>
      <Meeting {...props} />
      {props.roomState.audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}
    </>
  )
}