import { LegacyButton } from "components/buttons";
import UserGuideIcon from "components/buttons/common/UserGuideIcon";
import { XButton } from "components/buttons/common/XButton";
import { TypoGraphy } from "components/typography";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import styled from "styled-components";
import mobileImg from "../../../../assets/img/userguide/mobile.png";
import pcImg from "../../../../assets/img/userguide/pc.png";

const UserGuide = styled.div`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? "306px" : "490px")};
  height: ${({ selectedOption, isMobile }) => (!!selectedOption ? (isMobile ? "498px" : "653px") : "222px")};
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  padding: 30px 20px;
  z-index: 5;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? "12px" : "21px")};
  position: absolute;
  bottom: 0;
`;

const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 34px;

  padding-bottom: ${({ selectedOption }) => !!selectedOption && (selectedOption === "PC" ? "73px" : "31px")};
`;

const ImageWrapper = styled.div`
  border-radius: 13px;
  border: 2px solid #f1f5f9;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 13px;
  }
`;

export const UserGuideModal = inject("store")(
  observer((props: any) => {
    const { isMobile, visible, onClose, store } = props;
    const [selectedOption, setSelectedOption] = useState("");
    const handleCloseUserGuide = () => {
      onClose();
      setSelectedOption("");
    };

    const handleClick = (option: string) => {
      setSelectedOption(option);
    };

    return (
      <UserGuide visible={visible} isMobile={isMobile} selectedOption={selectedOption}>
        <ContentWrapper>
          <HeaderFrame>
            <XButton onClick={handleCloseUserGuide} />
          </HeaderFrame>
          <TitleWrap selectedOption={selectedOption}>
            {selectedOption === "" && <UserGuideIcon />}
            <TypoGraphy variant='Title1' color='#242424'>
              {selectedOption === "" ? "User Guide" : selectedOption}
            </TypoGraphy>
          </TitleWrap>
          {selectedOption.length > 0 ? (
            <ImageWrapper>
              <img src={selectedOption === "PC" ? pcImg : mobileImg}></img>
            </ImageWrapper>
          ) : (
            <ButtonWrap>
              <LegacyButton buttonType='Primary' state='Default' paddingHorizon={60} onClick={() => handleClick("PC")}>
                PC
              </LegacyButton>
              <LegacyButton buttonType='Primary' state='Default' paddingHorizon={60} onClick={() => handleClick("Mobile")}>
                Mobile
              </LegacyButton>
            </ButtonWrap>
          )}
        </ContentWrapper>
      </UserGuide>
    );
  })
);
