import CustomRoutes from "routes";
import GlobalStyle from "styles/GlobalStyle";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { properties } from "api-manager/resource/Properties";
import { Provider } from "mobx-react";
import { initStore } from "utils/SessionStorageHelper";
import { UserVideoChatProvider } from "context/UserVideoChatContext";
import { useEffect } from "react";
import { RefreshToken } from "api-manager/services/AuthServices/AuthServices";

const App = () => {
  const store = initStore();
  const refreshToken = () => {
    if (!store.userStore.refreshToken) return;
    RefreshToken({
      refreshToken: store.userStore.refreshToken,
    }).then((data) => {
      store.userStore.setToken(data.data);
    });
  };
  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={properties.GOOGLE}>
        <GlobalStyle />
        <CustomRoutes/>
        {/* <UserVideoChatProvider>
          <CustomRoutes />
        </UserVideoChatProvider> */}
      </GoogleOAuthProvider>
    </Provider>
  );
};

export default App;
