import styled from 'styled-components';
import { Variant, TypoGraphyType } from 'components/typography/types';

const letterSpacing = (variant: Variant) => {
  let value = '';
  if (variant === 'LargeTitle' || variant === 'Title1' || variant === 'Title2') value = '-0.03em';
  else value = '-0.02em';
  return value;
};

type Variants = {
  [key in Variant]: string;
};

const fontSize: Variants = {
  LargeTitle: '44px',
  Title1: '32px',
  Title2: '28px',
  Headline2: '22px',
  Headline1: '19px',
  Body1: '17px',
  Body2: '15px',
  Caption1: '13px',
  Caption2: '12px',
  Caption3: '11px'
};

const defaultLineHeight: Variants = {
  LargeTitle: '50px',
  Title1: '36px',
  Title2: '32px',
  Headline2: '25px',
  Headline1: '21px',
  Body1: '19px',
  Body2: '17px',
  Caption1: '15px',
  Caption2: '14px',
  Caption3: '12px'
};

const multiLineLineHeight: Variants = {
  LargeTitle: '66px',
  Title1: '48px',
  Title2: '42px',
  Headline2: '33px',
  Headline1: '28px',
  Body1: '26px',
  Body2: '22px',
  Caption1: '19px',
  Caption2: '18px',
  Caption3: '15px'
};

interface TypoGraphyProps {
  typographyType?: TypoGraphyType;
  variant?: Variant;
  hover?: boolean;
  hoverColor?: string;
  wordBreak?: boolean;
  color?: string;
  padding?: string;
}

export const TypoGraphy = styled.div<TypoGraphyProps>`
  font-family: Godo M;
  font-size: ${({ variant }) => fontSize[variant ? variant : 'Body1']};
  letter-spacing: ${({ variant }) => letterSpacing(variant ? variant : 'Body1')};
  line-height: ${({ typographyType, variant }) => (typographyType === 'Multiline' ? multiLineLineHeight[variant ? variant : 'Body1'] : defaultLineHeight[variant ? variant : 'Body1'])};
  color: ${({ color }) => color || '#000000'};
  white-space: ${({ typographyType }) => (typographyType === 'Multiline' ? 'pre-line' : 'normal')};
  padding: ${({padding}) => padding};
  ${({ hover, hoverColor }) => {
    if (!hover || !hoverColor) return '';
    return `
      &:hover {
        color: ${hoverColor};
      }
    `;
  }}

  ${({wordBreak}) => {
    if (wordBreak) {
      return `
        word-break: break-all;
      `
    }
  }}
`;
