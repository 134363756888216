import React from "react";

function LogOutIcon(props) {
  const { onClick } = props;
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      fill="none"
      viewBox="0 0 13 15"
    >
      <path
        fill="#BDBDBD"
        d="M9.35 10.985V8.831H4.315V5.958H9.35V3.804l3.596 3.59-3.596 3.591zM7.91.213A1.44 1.44 0 019.35 1.65v1.436H7.91V1.65H1.438v11.49h6.473v-1.437H9.35v1.437a1.435 1.435 0 01-1.439 1.436H1.438A1.44 1.44 0 010 13.14V1.65A1.435 1.435 0 011.438.213h6.473z"
      ></path>
    </svg>
  );
}

export default LogOutIcon;
