import { TypoGraphy } from "components/typography";
import { RemoteParticipant, Room, RoomEvent } from "livekit-client";
import { useEffect, useRef } from "react";
import { ChatMessage } from "../types";
import { ChatAreaFrame, ChatTextArea, MessageFrame } from "./styles/chat-area";
import { CloseBtn } from "components/sidebar/living/modal/LocationModal";
import { XButton } from "components/buttons/common/XButton";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

interface TextChatListProps {
  room: Room;
  focus: boolean;
  visible: boolean;
  messages: string[];
  isMobile: boolean;
  setFocus: (focus: boolean) => void;
  activeBlink: () => void;
  addMessage: (nickname: string, message: string) => void;
  store?: any;
}

const ButtonWrap = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`

export const TextChatList: React.FC<TextChatListProps> = inject('store')(observer(({
  room,
  focus,
  messages,
  isMobile,
  visible,
  activeBlink,
  setFocus,
  addMessage,
  store,
}) => {
  const chatTextAreaRef = useRef<HTMLDivElement>(null);
  const { modalStore } = store;
  const onReceive = (payload: Uint8Array, participant?: RemoteParticipant | undefined) => {
    const decoder = new TextDecoder();
    const chat = JSON.parse(decoder.decode(payload)) as ChatMessage;
    addMessage(participant?.name ? participant.name : 'unknown', chat.message);
  }

  const transformMessage = (key: number, message: string) => {
    let color = message.slice(0,2).includes('ME') ? '#FFFFFF' : '#979797';
    return (
      <MessageFrame
        key={key}
      >
        <TypoGraphy
          typographyType="Multiline"
          variant={isMobile ? 'Caption2' : 'Body1'}
          color={color}
          wordBreak={true}
        >
          {message}
        </TypoGraphy>
      </MessageFrame>
    )
  }

  useEffect(() => {
    room.on(RoomEvent.DataReceived, onReceive);
    return () => {
      room.off(RoomEvent.DataReceived, onReceive);
    }
  },[room])

  useEffect(() => {
    if (chatTextAreaRef.current) {
      chatTextAreaRef.current.scrollTop = chatTextAreaRef.current.scrollHeight;
    }
    if(messages.length) {
      if(!visible && !messages[messages.length-1].slice(0,2).includes('ME')) activeBlink();
    }
    return () => {
    }
  }, [messages])
  
  return (
    <ChatAreaFrame
      focus={focus}
      onClick={(e) => {
        e.stopPropagation();
        setFocus(true);
      }}
      visible={visible}
      isMobile={isMobile}
    >
      {isMobile && (
        <ButtonWrap>
          <CloseBtn onClick={() => modalStore.closeModal('TextChat')}>
            <XButton onClick={() => modalStore.closeModal('TextChat')} fontSize={30} color='#FFFFFF' />
          </CloseBtn>
        </ButtonWrap>
      )}
      <ChatTextArea
        ref={chatTextAreaRef}
      >
        {
          messages.map((message, index) => transformMessage(index, message))
        }
      </ChatTextArea>
    </ChatAreaFrame>
  )
}))