import { properties } from "api-manager/resource/Properties";
import { patchData } from "api-manager/utils/Fetch";
import { LegacyButton } from "components/buttons";
import { MediaMetaData, updateParticipant } from "components/chat/api";
import { CommonStyleProps } from "components/chat/types";
import { TypoGraphy } from "components/typography";
import { useInput } from "hooks/useInput";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.85);
  border: 2px solid #F1F5F9;
  backdrop-filter: blur(7px);
  border-radius: 12px;

  resize: none;
  outline: none;

  text-align: left;

  white-space:nowrap;

  resize: none;

  font-family: Godo M;
  font-size: 15px;
  line-height: 19px;
  color: #575757;
  padding: 0px 20px;
`

const ModalFrame = styled.div<CommonStyleProps>`
  position: absolute;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
  border-radius: 30px;

  ${({isMobile}) => {
    if (isMobile) {
      return `
        width: 300px;
        height: 238px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 0px;
      `
    } else {
      return `
        width: 360px;
        height: 280px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 0px;
      `
    }
  }}

  display: grid;
  grid-template-rows: 35px 35px auto;

  place-items: center;
  gap: 30px 0px;

  z-index: 1;

  visibility: ${({visible}) => visible ? 'display' : 'hidden'};
  opacity: ${({visible}) => visible ? '1' : '0'};
`

const TitleFrame = styled.div`
`

const InputFrame = styled.div<{isMobile: boolean}>`
  width: ${({isMobile}) => isMobile ? '210px' : '280px'};
  display: grid;
  place-items: center;
`

const WarningFrame = styled.div`
  position: relative;
  bottom: -10px;
`

const ButtonFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 30px;
`

interface IUpdateStateMessageModal extends CommonStyleProps{
  onClose: () => void;
  store?: any;
}

export const UpdateStateMessageModal = inject('store')(observer((props: IUpdateStateMessageModal) => {
  const { visible, isMobile, onClose, store } = props;
  const { userStore } = store;
  const [ input, onInput, inputInit ] = useInput("");
  const [ warning, setWarning ] = useState<string>();

  const onSubmit = () => {
    if (input.length > 35) {
      setWarning("State message should be less than 35 characters");
      return;
    }
    if (!userStore.token) return;
    patchData(properties.ENDPOINTS.endpoint_user_profile.url, {
      stateMessage: input
    }).then((res) => {
      if (res.status === 'SUCCESS') {
        updateParticipant(userStore.token, "text-chat-room", {
          state: userStore.user.state || "active",
          stateMessage: input
        }).then((response) => {
          const metaData = JSON.parse(response.metadata) as MediaMetaData;
          userStore.updateUser(metaData);
          onClose();
        }).catch(() => onClose())
      }
    });
  }

  useEffect(() => {
    if (!visible) {
      inputInit("");
      setWarning(undefined);
    }
  }, [visible])
  
  
  return (
    <ModalFrame
      visible={visible}
      isMobile={isMobile}
    >
      <TitleFrame>
        <TypoGraphy
          variant="Headline2"
          color="#5F5F5F"
        >
          Status message change
        </TypoGraphy>
      </TitleFrame>
      <InputFrame
        isMobile={isMobile}
      >
        <Input
          placeholder={"Enter a status message."}
          value={input}
          onChange={onInput}
        />
        <WarningFrame>
          <TypoGraphy
            variant="Caption1"
            color="#FF5C8D"
          >
            {warning}
          </TypoGraphy>
        </WarningFrame>
      </InputFrame>
      <ButtonFrame>
        <LegacyButton
          buttonType="Secondary"
          state="Default"
          paddingHorizon={isMobile ? 30 : 50}
          onClick={onClose}
        >
          Cancel
        </LegacyButton>
        <LegacyButton
          buttonType="Primary"
          state="Default"
          paddingHorizon={isMobile ? 30 : 50}
          onClick={onSubmit}
        >
          Ok
        </LegacyButton>
      </ButtonFrame>
    </ModalFrame>
  )
}))