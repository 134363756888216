import { useRoom } from "@livekit/react-components";
import { ConnectionState } from "livekit-client";
import { useEffect } from "react";
import { RoomProps } from "../types"
import { MeetingStage } from "./meeting/MeetingStage";

interface VideoChatRoom extends RoomProps{
  isMobile: boolean;
}

export const VideoChatRoom: React.FC<VideoChatRoom> = ({
  token,
  url,
  connectOptions,
  onConnected,
  roomOptions,
  isMobile
}) => {
  const roomState = useRoom(roomOptions);
  const {
    error,
    isConnecting,
    room,
    participants
  } = roomState;

  useEffect(() => {
    if (roomState.room) {
      roomState.connect(url, token, connectOptions).then((room) => {
        if (!room) {
          return;
        }
        if (onConnected && room.state === ConnectionState.Connected) {
          onConnected(room);
        }
      });
    }
    return () => {
      if (roomState.room?.state !== ConnectionState.Disconnected) {
        roomState.room?.disconnect();
      }
    };
  }, [token, roomState.room]);

  if (error || isConnecting || !room || participants.length === 0) {
    return <></>;
  }
  
  return <MeetingStage isMobile={isMobile} roomState={roomState}/>
}