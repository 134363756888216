import styled from "styled-components";
import { ReactComponent as BackSpace } from "assets/img/icon/v2/backspace.svg";
import open from "assets/img/icon/v2/remote.svg";
import { useMouseOver } from "hooks/videochat/useMouseOver";
import { TypoGraphy } from "components/typography";
import { CommonStyleProps } from "../types";

const CloseButtonFrame = styled.div<CommonStyleProps>`
  position: absolute;

  display: grid;
  place-items: center;

  width: 36px;
  height: 36px;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        top: 10px;
        right: 5px;  
      `;
    } else {
      return `
        top: 30px;
        right: 25px;  
      `;
    }
  }}

  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 13px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.85);
  }

  z-index: 13;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;
const CloseButton: React.FC<
  Pick<IVideoToggleButton, "onClick" | "visible"> &
    Pick<CommonStyleProps, "isMobile">
> = ({ onClick, visible, isMobile }) => {
  const [onMouse, onMouseOver, onMouseOut] = useMouseOver<SVGSVGElement>();

  return (
    <CloseButtonFrame
      onClick={() => onClick(true)}
      visible={visible}
      isMobile={isMobile}
    >
      <BackSpace
        fill={onMouse ? "#FFFFFF" : "#979797"}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </CloseButtonFrame>
  );
};

const OpenButtonFrame = styled.div<CommonStyleProps>`
  position: absolute;

  display: grid;
  place-items: center;

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        top: 10px;
        right: 10px;
        width: auto;
        height: 25px;
        padding: 5px 8px;
      `;
    } else {
      return `
        top: 30px;
        right: 30px;
        width: auto;
        height: 40px;
        padding: 5px 28px;
      `;
    }
  }}

  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 25px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.85);
  }

  z-index: 13;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;

const OpenFrame = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 0px ${({ isMobile }) => (isMobile ? "8px" : "11px")};
  align-items: center;
`;

const OpenButton: React.FC<
  Pick<IVideoToggleButton, "onClick" | "visible"> &
    Pick<CommonStyleProps, "isMobile">
> = ({ onClick, visible, isMobile }) => {
  return (
    <OpenButtonFrame onClick={() => onClick(false)} visible={visible}>
      <OpenFrame>
        <img width={isMobile ? 15 : 24} src={open} alt="" />
        <TypoGraphy
          typographyType="Default"
          variant={isMobile ? "Caption2" : "Body2"}
          color="#FFFFFF"
        >
          Open
        </TypoGraphy>
      </OpenFrame>
    </OpenButtonFrame>
  );
};

interface IVideoToggleButton extends CommonStyleProps {
  closed: boolean;
  onClick: (closed: boolean) => void;
}

export const VideoToggleButton: React.FC<IVideoToggleButton> = ({
  closed,
  onClick,
  visible,
  isMobile,
}) => {
  if (closed)
    return (
      <OpenButton isMobile={isMobile} onClick={onClick} visible={visible} />
    );

  return (
    <CloseButton onClick={onClick} visible={visible} isMobile={isMobile} />
  );
};
