import { Color3, StandardMaterial, AbstractMesh, Scene } from "@babylonjs/core";

export const applyLights = (allMesh: AbstractMesh[],
  LIGHT_COLOR: string[],
  scene: Scene) => {
    const lights = allMesh.filter(e => e.name.toLocaleLowerCase().includes("emiss"));
    for(let i = 0; i <= lights.length - 1; i++) {
      const mat = new StandardMaterial('mat', scene);
      mat.emissiveColor = Color3.FromHexString(LIGHT_COLOR[i]);
      lights[i].material = mat;
    }
}