import styled from "styled-components";
import { pencil } from "assets/img/icon/v1/index";
import { TModal, TModalParam } from "./types";
import { Avatar } from "components/chat/video/Avatar";
import { TypoGraphy } from "components/typography";
import { useAudio } from "hooks/videochat/useAudio";
import { CommonStyleProps } from "components/chat/types";

const MyUserFrame = styled.div<{ minimode: boolean, isMobile: boolean }>`
  display: grid;
  ${({ minimode, isMobile }) => {
    if (minimode) return;
    else if (!isMobile) {
      return `grid-template-columns: 72px 168px;`;
    } else if (isMobile) {
      return `
        grid-template-rows: 60px 45px;
      `;
    }
  }}
  gap: 0px 15px;
`;

const MyUserInfoFrame = styled.div<Pick<CommonStyleProps,'isMobile'>>`
  display: grid;
  gap: ${({isMobile}) => (isMobile ? "0px" : "10px")} 0px;
  place-items: center start;
`;

const LocationFrame = styled.div`
  display: grid;
  place-items: center start;
  grid-template-columns: 11px auto;
  gap: 0px 8px;
`;

const EditFrame = styled.div<Pick<CommonStyleProps,'isMobile'>>`
  position: absolute;
  ${({isMobile}) => {
    if (isMobile) {
      return `
      top: 105.5px;
      left: 120px;
      `;
    } else {
      return `
        top: 66.5px;
        right: 17.5px;
      `;
    }
  }}

  &:hover {
    cursor: pointer;
  }
`;

export interface IUserListItem {
  onChangeModal: (modal: TModal, blur?: boolean, param?: TModalParam) => void;
  isMobile: boolean;
  minimode: boolean;
  store?: any;
}

export const UserListItem = (props: IUserListItem) => {
  const { minimode, isMobile, onChangeModal, store } = props;
  const { userStore } = store;
  const userInfo = store.userStore.user;
  const [playAudio] = useAudio();
  
  const onOpenItem = (modal: TModal) => {
    playAudio();
    onChangeModal(modal);
  }

  return (
    <MyUserFrame isMobile={isMobile} minimode={minimode}>
      <Avatar
        avatar={userInfo.picture}
        size={isMobile ? "medium" : "large"}
        state={userStore.user.state || "disabled"}
        visibleActive={true}
        onAvatarSettingClick={() => onOpenItem("AvatarSetting")}
      />
      {!minimode ? (
        <MyUserInfoFrame>
          <EditFrame isMobile={isMobile} onClick={() => onOpenItem("UpdateMessage")}>
            <img src={pencil} alt="" />
          </EditFrame>
          <TypoGraphy variant={isMobile ? "Body1" : "Headline1"}>
            {userStore.user?.name || "unknown"}
          </TypoGraphy>
          <TypoGraphy variant={isMobile ? "Caption2" : "Caption1"} color="#5F5F5F">
            {userStore.user?.stateMessage || ""}
          </TypoGraphy>
          {/* {
              location
              ? (
                <LocationFrame>
                  <img src={marker} alt="" width={10.67}/>
                  <TypoGraphy
                    variant="Caption1"
                    color="#5F5F5F"
                  >
                    {location}
                  </TypoGraphy>
                </LocationFrame>
              )
              : (<></>)
            } */}
        </MyUserInfoFrame>
      ) : (
        <></>
      )}
    </MyUserFrame>
  );
};
