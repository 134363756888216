import { CommonStyleProps } from "components/chat/types";
import styled from "styled-components"

const ClosedSideBarFrame = styled.div<CommonStyleProps>`
  position: absolute;
  ${({isMobile}) => {
    const size = isMobile ? '34px' : '64px';
    const position = '35px';
    return `
      width: ${size};
      height: ${size};
      left: ${position};
      top: ${position};
    `
  }}
  background: rgba(95, 99, 104, 0.75);
  backdrop-filter: blur(5px);

  border-radius: 43px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px 0px;

  &:hover {
    cursor: pointer;
    background: rgba(95, 99, 104, 1);
  }

  visibility: ${({visible}) => visible ? 'display' : 'hidden'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  z-index: 1;
`

const Line = styled.div<Pick<CommonStyleProps,'isMobile'>>`
  width: ${({isMobile}) => isMobile ? '16px' : '26px'};
  height: 2px;
  background: #FFFFFF;
`

interface IClosedSideBar extends CommonStyleProps {
  onClick: () => void;
}

export const ClosedSideBar: React.FC<IClosedSideBar> = ({
  visible,
  isMobile,
  onClick
}) => {
  return (
    <ClosedSideBarFrame
      visible={visible}
      isMobile={isMobile}
      onClick={onClick}
    >
      <Line isMobile={isMobile}/>
      <Line isMobile={isMobile}/>
      <Line isMobile={isMobile}/>
    </ClosedSideBarFrame>
  )
}