import { ApiBaseResponse } from "api-manager/interfaces/FetchInterface";
import { properties } from "api-manager/resource/Properties";
import { getData, postData } from "../../utils/Fetch";
import { GoogleRequestPayload, NaverRequestPayload } from "./AuthInterface";

export interface RefreshTokenDTO {
  refreshToken: string;
}
export const LoginWithGoogle = async (
  payload: GoogleRequestPayload
): Promise<ApiBaseResponse> => {
  const params = "?accessToken=" + payload.access_token;
  const url = properties.ENDPOINTS.endpoint_auth_google.url + params;
  const response = await getData(url);
  return response;
};

export const LoginWithNaver = async (
  payload: NaverRequestPayload
): Promise<ApiBaseResponse> => {
  const params = "?accessToken=" + payload.accessToken;
  const url = properties.ENDPOINTS.endpoint_auth_naver.url + params;
  const response = await getData(url);
  return response;
};

export const RefreshToken = async (
  dto: RefreshTokenDTO
): Promise<ApiBaseResponse> => {
  const url = properties.ENDPOINTS.endpoint_refresh_token.url;
  const response = await postData(url, dto);
  return response;
};
