import styled from "@emotion/styled";
import { Box } from "@mui/material";
interface IProgressBar {
  percent: number;
  width?: string;
  height?: string;
  background: string;
}

const ProgressBarStyled = styled(Box)<IProgressBar>`
  height: ${(props) => props.height};
  box-shadow: 0px 0px 4px rgba(124, 109, 120, 0.25);
  border-radius: 50px;
  background: white;
  padding: 2px;

  .percent {
    border-radius: 50px;
    width: ${(props) => `${props.percent}%`};
    height: 100%;
    background: ${(props) => props.background};
  }
`;

export const ProgressBar = (props) => {
  const { percent, width = "auto", height = "auto", background } = props;
  return (
    <ProgressBarStyled
      background={background}
      width={width}
      height={height}
      percent={percent}
    >
      <Box className="percent" />
    </ProgressBarStyled>
  );
};
