import { CommonStyleProps } from 'components/chat/types';
import styled from 'styled-components';

export const Button = styled.button<Pick<CommonStyleProps,'isMobile'>>`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px 3px 0px #ffffff, inset 0px 0px 12px #f1f3f4;
  }
  &:active {
    box-shadow: 0px 12px 20px rgba(123, 97, 255, 0.15), inset 0px -3px 0px #ffffff, inset 0px 0px 12px #f1f3f4;
  }
  padding: 16px 66px;
  width: ${({isMobile}) => isMobile ? '280px' : '380px'};
  height: 72px;
  background: ${props => (props.state === 'Disable' ? `#F1F5F9` : `#FFFFFF`)};
  box-shadow: ${props => (props.state === 'Default' ? '0px 12px 20px rgba(123, 97, 255, 0.05), inset 0px 2px 3px rgba(255, 255, 255, 0.35), inset 0px 0px 12px #F1F3F4' : '')};
  border-radius: 16px;
  border-width: 0px;
`;
