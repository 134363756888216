import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";

const FooterStyled = styled(Box)`
  width: 100%;
  height: ${(props) => (props.isMobile ? "50px" : "73px")};
  background: #787878;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    padding-left: ${(props) => (props.isMobile ? "11px" : "20px")};
    font-size: ${(props) => (props.isMobile ? "13px" : "19px")};
    font-family: "Godo M";
    color: #b5b5b5;
  }
`;

export const Footer = () => {
  const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);
  handleDeviceResize((device) => {
    setIsMobile(device.isMobile);
  });
  return (
    <FooterStyled isMobile={isMobile}>
      <Typography children={`© ${new Date().getFullYear()} Heartverse Company`} />
    </FooterStyled>
  );
};
