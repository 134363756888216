import { createContext, useEffect, useState } from "react";
import { Props } from "./types";

export type UserVideoChatWay = 'Room' | 'Group';
export type OnChangeUserSpace = (type: UserVideoChatWay, data: string) => void;

interface UserVideoChatContextProps {
  onChangeUserSpace: OnChangeUserSpace;
  curUserRoom?: string;
  curUserGroup?: string;
  videoRoom?: string;
}

export const UserVideoChatContext = createContext<UserVideoChatContextProps>({
  onChangeUserSpace(type, data) {},
});


export const UserVideoChatProvider = ({ children }: Props): JSX.Element => {
  const [curUserRoom, setCurUserRoom] = useState<string>('lobby');
  const [curUserGroup, setCurUserGroup] = useState<string>('');
  const [videoRoom, setVideoRoom] = useState<string>('');

  const onChangeUserSpace = (type: UserVideoChatWay, data: string) => {
    if (type === 'Room') {
      setCurUserRoom(data);
    } else if (type === 'Group') {
      setCurUserGroup(data);
    }
  }

  useEffect(() => {
    if (curUserRoom === 'lobby' && curUserGroup.length) {
      setVideoRoom(curUserGroup);
    } else if (curUserRoom !== 'lobby') {
      setVideoRoom(curUserRoom);
    } else {
      setVideoRoom('');
    }
  },[curUserRoom, curUserGroup])

  return (
    <UserVideoChatContext.Provider
      value={{
        onChangeUserSpace,
        videoRoom
      }}
    >
      {children}
    </UserVideoChatContext.Provider>
  )
}