import { MenuItem, Select } from "@mui/material";
import { thickArrowBlack } from "assets/img/icon/v1/index";
import { LegacyButton, ToggleButton } from "components";
import { XButton } from "components/buttons/common/XButton";
import { CommonStyleProps } from "components/chat/types";
import { TypoGraphy } from "components/typography";
import { Resoultion, UserSettingContext } from "context/UserSettingContext";
import { useAudio } from "hooks/videochat/useAudio";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTER } from "routes/constant";

const SettingModalFrame = styled.div<CommonStyleProps>`
  box-sizing: border-box;

  position: absolute;
  width: 360px;
  height: 620px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ isMobile }) => {
    if (isMobile) {
      return `
        position: absolute;
        width: 300px;
        height: 520px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);  
      `;
    } else {
      return `
        position: absolute;
        width: 360px;
        height: 620px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);  
      `;
    }
  }}

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  padding: 30px 20px;
  z-index: 5;

  display: flex;
  flex-direction: column;
  gap: 30px 0px;

  visibility: ${({ visible }) => (visible ? "display" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;

const HeaderFrame = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentsFrame = styled.div`
  display: grid;
  gap: 30px 0px;
`;

const ContentFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AvatarSettingFrame = styled(ContentFrame)`
  margin-top: 30px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonFrame = styled.div<Pick<CommonStyleProps, "isMobile">>`
  ${({ isMobile }) => {
    if (isMobile) {
      return `
        display: flex;
        justify-content: center;
        align-items: center;  
      `;
    } else {
      return `
        position: absolute;
        bottom: 30px;
        transform: translate(55%);
      `;
    }
  }}
`;

interface ISettingModal extends CommonStyleProps {
  onClose: () => void;
}

export enum DeviceKind {
  AudioInput = "audioinput",
  AudioOutput = "audiooutput",
  VideoInput = "videoinput",
}

export enum SpaceSettingKey {
  SpaceAutoCameraEnabled = "space_auto_camera_enabled",
  IsBlurredBackground = "isBlurredBackground",
  SpaceAudioEnabled = "space_audio_enabled",
  SpaceVideoEnabled = "space_video_enabled",
}

export const SettingModal: React.FC<ISettingModal> = ({
  isMobile,
  visible,
  onClose,
}) => {
  const {
    autoVideoConnection,
    handleAutoVideoConnection,
    onChangeDevice,
    resoultion,
    onChangeResoultion,
  } = useContext(UserSettingContext);

  const [selectedMic, setSelectedMic] = useState<number>(0);
  const [selectedCamera, setSelectedCamera] = useState<number>(0);
  const [selectBoxSize, setSelectBoxSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 180,
    height: 48,
  });
  const [mics, setMics] = useState<InputDeviceInfo[]>([]);
  const [videos, setVideos] = useState<InputDeviceInfo[]>([]);
  const [play] = useAudio();

  const navigate = useNavigate()

  async function getConnectedDevices(type: MediaDeviceKind) {
    return new Promise<MediaDeviceInfo[]>(async (resovle, reject) => {
      if (navigator.userAgent.indexOf("Firefox") > -1)
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        resovle(devices.filter((device) => device.kind === type));
      });
    });
  }

  const onDeviceChange = (_: Event) => {
    getConnectedDevices("videoinput").then((devicese) => {
      setVideos(devicese);
      const index = devicese.findIndex(
        (device) => device.deviceId !== "default"
      );
      setSelectedCamera(index !== -1 ? index : 0);
    });
    getConnectedDevices("audioinput").then((devicese) => {
      setMics(devicese);
      const index = devicese.findIndex(
        (device) => device.deviceId !== "default"
      );
      setSelectedMic(index !== -1 ? index : 0);
    });
  };

  useEffect(() => {
    onDeviceChange(new Event(""));
    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        onDeviceChange
      );
    };
  }, []);

  useEffect(() => {
    if (mics[selectedMic]) onChangeDevice("audioinput", mics[selectedMic]);
  }, [mics, selectedMic]);

  useEffect(() => {
    if (videos[selectedCamera])
      onChangeDevice("videoinput", videos[selectedCamera]);
  }, [videos, selectedCamera]);

  useEffect(() => {
    if (isMobile) {
      setSelectBoxSize({
        width: 120,
        height: 40,
      });
    }
  }, [isMobile]);

  return (
    <SettingModalFrame visible={visible} isMobile={isMobile}>
      <HeaderFrame>
        <TypoGraphy variant="Headline2">Settings</TypoGraphy>
        <XButton onClick={onClose} />
      </HeaderFrame>
      <ContentsFrame>
        {/* <ContentFrame>
          <TypoGraphy
            variant="Body2"
            color="#5F5F5F"
          >
            스피커
          </TypoGraphy>
          <Select
            style={{
              width: 180,
              height: 48,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #F1F5F9',
            }}
            value={selectedAudio}
            onChange={(e) => setSelectedAudio(Number(e.target.value))}
          >
            {audios.map((v, i) => (
              <MenuItem  key={i} defaultChecked={i === 0} value={i}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </ContentFrame> */}
        {/* <ContentFrame>
          <TypoGraphy
            variant="Body2"
            color="#5F5F5F"
          >
            비디오 설정
          </TypoGraphy>
          <ToggleButton
            checked={audio}
            handleToggle={() => handleMedia('audio', !audio)}
          />
        </ContentFrame> */}
        <ContentFrame>
          <TypoGraphy variant="Body2" color="#5F5F5F">
            Microphone
          </TypoGraphy>
          <Select
            style={{
              ...selectBoxSize,
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #F1F5F9",
            }}
            value={mics.length ? selectedMic : ""}
            onChange={(e) => setSelectedMic(Number(e.target.value))}
          >
            {mics.map((v, i) => (
              <MenuItem key={i} defaultChecked={i === 0} value={i}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </ContentFrame>
        <ContentFrame>
          <TypoGraphy variant="Body2" color="#5F5F5F">
            Camera
          </TypoGraphy>
          <Select
            style={{
              ...selectBoxSize,
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #F1F5F9",
            }}
            value={mics.length ? selectedCamera : ""}
            onChange={(e) => setSelectedCamera(Number(e.target.value))}
          >
            {videos.map((v, i) => (
              <MenuItem key={i} defaultChecked={i === 0} value={i}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </ContentFrame>
        <ContentFrame>
          <TypoGraphy variant="Body2" color="#5F5F5F">
            Resolution
          </TypoGraphy>
          <Select
            style={{
              ...selectBoxSize,
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #F1F5F9",
            }}
            value={resoultion}
            onChange={(e) => onChangeResoultion(e.target.value as Resoultion)}
          >
            <MenuItem value={"h720"}>720p</MenuItem>
            <MenuItem value={"h360"}>360p</MenuItem>
          </Select>
        </ContentFrame>
        {/* <ContentFrame>
          <TypoGraphy
            variant="Body2"
            color="#5F5F5F"
          >
            전송 해상도
          </TypoGraphy>
          <Select
            style={{
              width: 180,
              height: 48,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #F1F5F9',
            }}
            value={0}
          >
            <MenuItem value={0}>
              720p
            </MenuItem>
          </Select>
        </ContentFrame>
        <ContentFrame>
          <TypoGraphy
            variant="Body2"
            color="#5F5F5F"
          >
            수신 해상도
          </TypoGraphy>
          <Select
            style={{
              width: 180,
              height: 48,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #F1F5F9',
            }}
            value={0}
          >
            <MenuItem value={0}>
              720p
            </MenuItem>
          </Select>
        </ContentFrame> */}
        <ContentFrame>
          <TypoGraphy variant="Body2" color="#5F5F5F">
            Automatic Video Connection
          </TypoGraphy>
          <ToggleButton
            checked={autoVideoConnection}
            handleToggle={() => {
              play();
              handleAutoVideoConnection(!autoVideoConnection);
            }}
          />
        </ContentFrame>
        {/* <ContentFrame>
          <TypoGraphy
            variant="Body2"
            color="#5F5F5F"
          >
            화상채팅 블러 처리
          </TypoGraphy>
          <ToggleButton
            checked={audio}
            handleToggle={() => handleMedia('audio', !audio)}
          />
        </ContentFrame> */}
        <AvatarSettingFrame>
          <TypoGraphy onClick={() => {
            navigate(NAVIGATION_ROUTER.CHARACTER)
          }} variant="Headline1">Avatar Settings</TypoGraphy>
          <img src={thickArrowBlack} alt="" />
        </AvatarSettingFrame>
      </ContentsFrame>
      <ButtonFrame isMobile={isMobile}>
        <LegacyButton
          buttonType="Primary"
          state="Default"
          paddingHorizon={60}
          onClick={() => {
            play();
            onClose();
          }}
        >
          Apply
        </LegacyButton>
      </ButtonFrame>
    </SettingModalFrame>
  );
};
