import styled from "styled-components";
import { person } from "../../../assets/img/icon/v1/index";
import { useCallback, useMemo } from "react";
import { UserChatState } from "../types";

const getAvatarFrameSize = (size: TSize) => {
  if (size === "large") return 72;
  else if (size === "medium") return 58;
  else return 48;
};

const AvatarFrame = styled.div<Pick<IAvatar, "size" | "noneHover">>`
  position: relative;
  width: ${({ size }) => getAvatarFrameSize(size)}px;
  height: ${({ size }) => getAvatarFrameSize(size)}px;

  background: linear-gradient(104.04deg, #d9e4f6 0%, #d9d5fb 100%);

  border-radius: 100%;

  display: grid;
  place-items: center;

  &:hover {
    cursor: ${({ noneHover }) => (noneHover ? "default" : "pointer")};
  }
  img {
    width: ${(props) => props.avatar && "100%"};
    height: ${(props) => props.avatar && "100%"};
    border-radius: ${(props) => props.avatar && "50%"};
  }
`;

const State = styled.div<IAvatar>`
  position: absolute;

  right: -3px;
  bottom: -3px;

  width: ${({ size }) => (size === "large" ? 20 : 16)}px;
  height: ${({ size }) => (size === "large" ? 20 : 16)}px;

  background: ${({ state }) => {
    if (state === "meeting") return "#644BE6";
    else if (state === "disabled") return "#979797";
    else return "#82E6C8";
  }};
  border: 3px solid #ffffff;
  border-radius: 100%;
`;

type TSize = "large" | "medium" | "small";

interface IAvatar {
  avatar?: string;
  size: TSize;
  state: UserChatState;
  visibleActive: boolean;
  noneHover?: boolean;
  onAvatarSettingClick?: () => void;
}

export const Avatar: React.FC<IAvatar> = ({
  avatar,
  size,
  state,
  visibleActive,
  noneHover,
  onAvatarSettingClick,
}) => {
  const getSizeNum = useCallback(() => {
    let sizeNum = 34.43;
    switch (size) {
      case "large":
        sizeNum = 34.43;
        break;
      case "medium":
        sizeNum = 21.33;
        break;
      case "small":
        sizeNum = 16.33;
        break;
    }
    return sizeNum;
  }, [size]);
  const sizeNum = useMemo(() => getSizeNum(), [size]);

  return (
    <>
      <AvatarFrame
        avatar={avatar}
        size={size}
        noneHover={noneHover}
        onClick={onAvatarSettingClick}
      >
        {visibleActive ? (
          <State state={state} size={size} visibleActive={true} />
        ) : (
          <></>
        )}
        <img src={avatar ? avatar : person} />
      </AvatarFrame>
    </>
  );
};
