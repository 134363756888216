import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { ProgressBar } from "./ProgressBar";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { useState } from "react";
export interface ILoadingProps {
  children: React.ReactNode;
  loading: boolean;
  progress?: number;
  showProgress?: boolean;
}

interface ILoadingStyled {
  isMobile: boolean;
}

export const LoadingStyled = styled(Box)<ILoadingStyled>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .loading-content {
    z-index: 3;
    padding-left: ${(props) => (props.isMobile ? "10px" : "83px")};

    /* padding-left: 83px; */
    width: 100%;
    .loading-title {
      margin-top: 10px;
      color: white;
      font-size: ${(props) => (props.isMobile ? "25px" : "70px")};
      font-family: "Godo B";
      text-shadow: 0px 0px 20px rgba(66, 36, 71, 0.3);
    }
  }
`;

export const Loading = (props: ILoadingProps) => {
  const { loading, children, progress, showProgress = true } = props;
  const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);
  handleDeviceResize((device) => {
    setIsMobile(device.isMobile);
  });
  return (
    <Box position="relative" width="100%" height="100%">
      {loading && (
        <LoadingStyled isMobile={isMobile} className="loading">
          <img src={require("../../assets/img/main/loading-screen.png")} />
          <Box className="loading-content">
            {showProgress && (
              <ProgressBar
                width="90%"
                height="15px"
                percent={progress}
                background="linear-gradient(90deg, #EB00FF -7.27%, #00F0FF 118.18%)"
              />
            )}

            <Typography
              className="loading-title"
              children="Entering the metaverse!"
            />
          </Box>
        </LoadingStyled>
      )}
      {children}
    </Box>
  );
};
