import { IconButton, IconButtonProps } from "components/buttons"
import ic_send from 'assets/img/button/ic_send.svg';

interface SendButtonProps extends IconButtonProps {
  onClick: () => void;
}

export const SendButton: React.FC<SendButtonProps> = ({
  state,
  onClick
}) => {
  return (
    <IconButton
      state={state}
      onClick={onClick}
    >
      <img src={ic_send} alt="ic_send.svg" />
    </IconButton>
  )
}