import { SettingModal } from "components/sidebar/living/modal/SettingModal";
import { UpdateStateMessageModal } from "components/sidebar/living/modal/UpdateStateMessageModal";
import { UpdateStateModal } from "components/sidebar/living/modal/UpdateStateModal";
import { UserListModal } from "../../../components/sidebar/living/modal/UserListModal";
import { TModal } from "../../../components/sidebar/living/types";
import { AvatarSettingModal } from "components/sidebar/living/modal/AvatarSetting";
import { MetaverseStore } from "stores/MetaverseStore";
import { UserGuideModal } from "components/sidebar/living/modal/UserGuide";
import { ExitModal } from "components/sidebar/living/modal/ExitModal";
import { LocationModal } from "components/sidebar/living/modal/LocationModal";

export type Modal = '' | 'UserList';

interface ModalBrokerProps {
  isMobile: boolean;
  onClose: () => void;
  modal?: TModal;
  apiUserToken?: string;
  store?: MetaverseStore;
}

export const ModalBroker:React.FC<ModalBrokerProps> = ({
  isMobile,
  modal,
  onClose,
}) => {
  return (
    <>
      <UpdateStateModal 
        visible={modal === 'UpdateState'}
        onClose={onClose}
        isMobile={isMobile}
      />
      <UpdateStateMessageModal
        visible={modal === 'UpdateMessage'}
        onClose={onClose}
        isMobile={isMobile}
      />
      <UserListModal
        onClose={onClose}
        visible={modal === 'UserList'}
        isMobile={isMobile}
      /> 
      <SettingModal
        onClose={onClose}
        visible={modal === 'Setting'}
        isMobile={isMobile}
      />
      <AvatarSettingModal
        onClose={onClose}
        visible={modal === 'AvatarSetting'}
        isMobile={isMobile}
      />
      <UserGuideModal
        onClose={onClose}
        visible={modal === 'UserGuide'}
        isMobile={isMobile}
      />
      <ExitModal
        onClose={onClose}
        visible={modal === 'Exit'}
        isMobile={isMobile}
      />
      <LocationModal
        onClose={onClose}
        visible={modal === 'Location'}
        isMobile={isMobile}
      />
    </>
  )
}