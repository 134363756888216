import { useState, useRef, useEffect, useContext, useCallback } from "react";
import { ReactComponent as Video } from "assets/img/icon/v2/video.svg";
import { ReactComponent as Mic } from "assets/img/icon/v2/mic.svg";
import { ReactComponent as OffVideo } from "assets/img/icon/v2/video-off.svg";
import { ReactComponent as OffMic } from "assets/img/icon/v2/mic-off.svg";
import { UserSettingContext } from "context/UserSettingContext";
import { useMouseOver } from "hooks/videochat/useMouseOver";
import { Avatar } from "./Avatar";
import { TypoGraphy } from "components/typography";
import {
  ItemFrame,
  LocalStream,
  UsersViewFrame,
  UserViewFrame,
  VideoChatFrame,
  VideoControlFrame,
  VideoControlItemsFrame,
} from "./styles/Video";

/* const ArrowFrame = styled.div<{direction: 'left' | 'right'}>`
  width: 20px;
  transform: rotate(${({direction}) => direction === 'left' ? 180 : 0}deg);
  &:hover {
    cursor: pointer;
  }
` */

interface LocalVideoProps {
  nickname: string;
  isMobile: boolean;
}

export const LocalVideo: React.FC<LocalVideoProps> = ({
  nickname,
  isMobile,
}) => {
  // const { userInfo } = useContext(AuthContext);
  const [localStream, setLocalStream] = useState<MediaStream>();
  const localRef = useRef<HTMLVideoElement>(null);
  const { video, mic, handleMedia, onChangeDevice, cameraDevice } =
    useContext(UserSettingContext);
  const [onVideo, onVideoOver, onVideoOut] = useMouseOver<SVGSVGElement>();
  const [onMic, onMicOver, onMicOut] = useMouseOver<SVGSVGElement>();

  useEffect(() => {
    if (localRef.current)
      localRef.current.srcObject = localStream ? localStream : null;
  }, [localStream]);

  useEffect(() => {
    const constraints: MediaStreamConstraints = {
      video: !cameraDevice
        ? false
        : {
            deviceId: cameraDevice.deviceId,
          },
    };
    if (constraints.video)
      navigator.mediaDevices.getUserMedia(constraints).then(setLocalStream);
  }, [video, cameraDevice]);

  const loadConnectedDevices = useCallback(
    (type: MediaDeviceKind) => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const filtered = devices.filter((device) => device.kind === type);
        if (filtered.length) {
          onChangeDevice(type, filtered[0]);
          // if (type === 'videoinput') changeDevice('video',filtered[0])
          // else if (type === 'audioinput') changeDevice('mic',filtered[0])
        }
      });
    },
    [onChangeDevice]
  );

  useEffect(() => {
    loadConnectedDevices("videoinput");
    loadConnectedDevices("audioinput");
    navigator.mediaDevices.addEventListener("devicechange", (event) => {
      loadConnectedDevices("videoinput");
      loadConnectedDevices("audioinput");
    });
  }, []);

  return (
    <VideoChatFrame visible={true} isMobile={isMobile}>
      <UsersViewFrame>
        <UserViewFrame isMobile={isMobile}>
          {video && localStream?.active ? (
            <LocalStream ref={localRef} autoPlay={true} />
          ) : (
            <>
              <Avatar
                size={isMobile ? "small" : "large"}
                state="active"
                visibleActive={false}
                noneHover={true}
              />
              <TypoGraphy
                typographyType="Default"
                variant={isMobile ? "Caption2" : "Body1"}
                color="#D8D8D8"
              >
                {/* {userInfo?.nickName} */}
                {nickname}
              </TypoGraphy>
            </>
          )}
        </UserViewFrame>
      </UsersViewFrame>
      <VideoControlFrame>
        <div></div>
        <VideoControlItemsFrame>
          <ItemFrame
            onClick={() => {
              const flag = !video;
              handleMedia("video", flag);
            }}
          >
            {video ? (
              <Video
                onMouseOver={onVideoOver}
                onMouseOut={onVideoOut}
                fill={onVideo ? "#FFFFFF" : "#D8D8D8"}
              />
            ) : (
              <OffVideo
                onMouseOver={onVideoOver}
                onMouseOut={onVideoOut}
                fill={onVideo ? "#FFFFFF" : "#D8D8D8"}
              />
            )}
            <TypoGraphy
              typographyType="Default"
              variant="Caption3"
              color="#979797"
            >
              Video
            </TypoGraphy>
          </ItemFrame>
          <ItemFrame
            onClick={() => {
              const flag = !mic;
              handleMedia("mic", flag);
            }}
          >
            {mic ? (
              <Mic
                onMouseOver={onMicOver}
                onMouseOut={onMicOut}
                fill={onMic ? "#FFFFFF" : "#D8D8D8"}
              />
            ) : (
              <OffMic
                onMouseOver={onMicOver}
                onMouseOut={onMicOut}
                fill={onMic ? "#FFFFFF" : "#D8D8D8"}
              />
            )}
            <TypoGraphy
              typographyType="Default"
              variant="Caption3"
              color="#979797"
            >
              Audio
            </TypoGraphy>
          </ItemFrame>
        </VideoControlItemsFrame>
        <div></div>
      </VideoControlFrame>
    </VideoChatFrame>
  );
};
