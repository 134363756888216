import { MediaMetaData } from "components/chat/api";
import { ChatUser } from "components/sidebar/living/types";
import { LocalParticipant, RemoteParticipant, Room } from "livekit-client";
import { createContext, useEffect, useState } from "react";
import { Props } from "./types";

const defaultUser: ChatUser = {
  id: "",
  nickname: "",
  state: "active",
  stateMessage: "",
  connectedAt: 0
}

interface UsersTextChatContextProps {
  room?: Room;
  setRoom: React.Dispatch<React.SetStateAction<Room | undefined>>;
  me: ChatUser;
  users: ChatUser[];
  setMe: React.Dispatch<React.SetStateAction<ChatUser>>;
  initUsers: (users: ChatUser[]) => void;
  onConnected: (user: ChatUser) => void;
  onDisconnected: (userId: string) => void;
  onParticipantMetadataChanged: (meta: string | undefined, participant: RemoteParticipant | LocalParticipant) => void;
}

export const UsersTextChatContext = createContext<UsersTextChatContextProps>({
  room: undefined,
  setRoom(room) {},
  me: defaultUser,
  users: [],
  initUsers(uesrs) {},
  onConnected(user) {},
  onDisconnected(userId) {},
  setMe(user) {},
  onParticipantMetadataChanged(meta, participant) {},
});


export const UsersTextChatProvider = ({ children }: Props): JSX.Element => {
  const [room, setRoom] = useState<Room>();
  const [me, setMe] = useState<ChatUser>(defaultUser);
  const [localParticipant, setLocalParticipant] = useState<LocalParticipant>();
  const [users, setUsers] = useState<ChatUser[]>([]);

  const initUsers = (users: ChatUser[]) => {
    setUsers(users);
  }

  const onConnected = (user: ChatUser) => {
    setUsers((users) => users.concat(user));
  }

  const onDisconnected = (userId: string) => {
    setUsers((users) => users.filter((user) => user.id !== userId));
  }

  const onParticipantMetadataChanged = (_: string | undefined, participant: RemoteParticipant | LocalParticipant) => {
    const metaData = participant.metadata;
    if (metaData) {
      const mediaMetaData = JSON.parse(metaData) as MediaMetaData;
      setUsers((users) => users.map((user) => user.id === participant.identity ? {...user, ...mediaMetaData} : user))
    }
  }

  return (
    <UsersTextChatContext.Provider
      value={{
        me,
        room,
        users,
        setRoom,
        setMe,
        initUsers,
        onConnected,
        onDisconnected,
        onParticipantMetadataChanged
      }}
    >
      {children}
    </UsersTextChatContext.Provider>
  )
}