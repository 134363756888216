export enum Breakpoints {
  mobile = 320,
  tablet = 768,
  desktop = 1024,
}

export enum DeviceTypes {
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum DeviceName {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export interface DeviceDetectInterface {
  isLandscape: boolean;
  deviceName: DeviceName;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}


const isLandscape = (): boolean => {
  return window.innerWidth > window.innerHeight;
};

const getDeviceType = (): DeviceTypes => {
  if (window.innerWidth < Breakpoints.tablet) {
    return DeviceTypes.MOBILE;
  } else if (window.innerWidth >= Breakpoints.desktop) {
    return DeviceTypes.DESKTOP;
  } else {
    return DeviceTypes.TABLET;
  }
};

const getDeviceName = (): DeviceName => {
  switch (getDeviceType()) {
    case DeviceTypes.MOBILE:
      return DeviceName.MOBILE;
    case DeviceTypes.TABLET:
      return DeviceName.TABLET;
    default:
      return DeviceName.DESKTOP;
  }
};

export function getDeviceDetect(): DeviceDetectInterface{
  return {
    isLandscape: isLandscape(),
    deviceName: getDeviceName(),
    isMobile: getDeviceName() === DeviceName.MOBILE,
    isTablet: getDeviceName() === DeviceName.TABLET,
    isDesktop: getDeviceName() === DeviceName.DESKTOP,
  };
};

export function handleDeviceResize(cb: { (device: DeviceDetectInterface): void; }): void {
  window.addEventListener('resize', () => {
    const device: DeviceDetectInterface = {
      isLandscape: isLandscape(),
      deviceName: getDeviceName(),
      isMobile: getDeviceName() === DeviceName.MOBILE,
      isTablet: getDeviceName() === DeviceName.TABLET,
      isDesktop: getDeviceName() === DeviceName.DESKTOP,
    };
    return cb(device)
  })
}
