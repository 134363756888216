import { ICharacterInfo } from "../CharacterManager/interface";
import { CharacterInfoState } from "../Network/Schema/CharacterInfoState";

export function mapCharacterInfo(data: CharacterInfoState): ICharacterInfo {
  const character: ICharacterInfo = {
    sessionId: data.sessionId,
    id: data.id,
    skinId: data.skinId,
    skinColor: data.skinColor,
    hairId: data.hairId,
    hairColor: data.hairColor,
    faceId: data.faceId,
    faceColor: data.faceColor,
    bodyId: data.bodyId,
    bodyColor: data.bodyColor,
    bottomId: data.bottomId,
    bottomColor: data.bottomColor,
    shoesId: data.shoesId,
    shoesColor: data.shoesColor,
    suitId: data.suitId,
    suitColor: data.suitColor,
    glassId: data.glassId,
    glassColor: data.glassColor,
    hatId: data.hatId,
    hatColor: data.hatColor,
    earringId: data.earringId,
    earringColor: data.earringColor,
    otherId: data.otherId,
    necklaceId: data.necklaceId,
  };
  return character;
}
