import { Box } from "@mui/material";
import { ExploreSpace } from "./ExploreSpace";
import { inject, observer } from "mobx-react";
import NavigationBar from "pages/main/NavigationBar";
import { useEffect, useState } from "react";
export const PlacesPage = inject("store")(
  observer((props: any) => {
    const { store } = props;
    useEffect(() => {
      const root = document.getElementById("root")!;
      root.style.overflowY = "visible";
      return () => {
        root.style.overflowY = "hidden";
      };
    }, []);

    return (
      <Box>
        <NavigationBar />
        <ExploreSpace />
      </Box>
    );
  })
);
