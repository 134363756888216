import { useRoom } from "@livekit/react-components";
import { ConnectionState } from "livekit-client";
import { useEffect, useState } from "react";
import { RoomProps } from "../types";
import { ChatNotification } from "./styles/chat-input";
import { TextChatInput } from "./TextChatInput";
import { TextChatList } from "./TextChatList";

interface TextChatRoomProps extends RoomProps {
  isMobile: boolean;
  visible: boolean;
}

export const TextChatRoom: React.FC<TextChatRoomProps> = ({
  url,
  token,
  roomOptions,
  connectOptions,
  onConnected,
  isMobile,
  visible,
}) => {
  const roomState = useRoom(roomOptions);
  const [focus, setFocus] = useState<boolean>(false);
  const [enableBlink, setEnableBlink] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);

  const { error, isConnecting, room } = roomState;
  const addMessage = (nickname: string, message: string) => {
    setMessages((prev) => prev.concat(`${nickname}: ${message}`));
  };

  const activeBlink = () => setEnableBlink(true);

  const onTextChatActive = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setFocus(true);
    setEnableBlink(false);
  };

  const disable = () => {
    setFocus(false);
  };

  useEffect(() => {
    if (roomState.room) {
      roomState.connect(url, token, connectOptions).then((room) => {
        if (!room) {
          return;
        }
        if (onConnected && room.state === ConnectionState.Connected) {
          onConnected(room);
        }
      });
    }
    return () => {
      if (roomState.room?.state !== ConnectionState.Disconnected) {
        roomState.room?.disconnect();
      }
    };
  }, [roomState.room]);

  useEffect(() => {
    document.addEventListener("click", disable);
    return () => {
      document.removeEventListener("click", disable);
    };
  }, []);

  if (error || isConnecting || !room) {
    return <div>no one is in the room</div>;
  }

  return (
    <>
      <ChatNotification 
        visible={enableBlink}
        isMobile={isMobile}
      />
      <TextChatList
        room={room}
        focus={focus}
        setFocus={setFocus}
        messages={messages}
        visible={isMobile ? visible : focus}
        activeBlink={activeBlink}
        addMessage={addMessage}
        isMobile={isMobile}
      />
      <TextChatInput
        room={room}
        focus={focus}
        addMessage={addMessage}
        onTextChatActive={onTextChatActive}
        isMobile={isMobile}
      />
    </>
  );
};
