function EmoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#D1D1D1"
        d="M12.643 25.899C5.716 25.899.101 20.284.101 13.356.1 6.43 5.716.814 12.643.814s12.543 5.615 12.543 12.542c0 6.928-5.616 12.543-12.543 12.543zM7.626 14.61a5.017 5.017 0 0010.034 0H7.626zm0-2.509a1.881 1.881 0 100-3.763 1.881 1.881 0 000 3.763zm10.034 0a1.881 1.881 0 100-3.762 1.881 1.881 0 000 3.762z"
      ></path>
    </svg>
  );
}

export default EmoteIcon;