import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TypoGraphy } from "components";
import { Button } from "components/buttons/common/Button";
import { ButtonState } from "components/buttons/types";
import GoogleLogo from "assets/img/logos/google.svg";
import NaverLogo from "assets/img/logos/naver.svg";
import {
  DeviceDetectInterface,
  getDeviceDetect,
  handleDeviceResize,
} from "utils/WindowDimensions";
import { CommonStyleProps } from "components/chat/types";

type SocialKoreanType = "구글" | "네이버";
type SocialKoreanTypes = {
  [key in LogoType]: SocialKoreanType;
};

interface SocialButtonProps extends Pick<CommonStyleProps, "isMobile"> {
  state?: ButtonState;
}
type LogoType = "google" | "naver";

type LogoTypes = {
  [key in LogoType]: string;
};

interface LoginLogoProps {
  type: LogoType;
}

const SocialButton = styled.div<SocialButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px ${({ isMobile }) => (isMobile ? "36px" : "66px")};
  gap: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: pointer;
  background: white;
`;

const logoTypes: LogoTypes = {
  google: GoogleLogo,
  naver: NaverLogo,
};

const socialKoreanTypes: SocialKoreanTypes = {
  google: "구글",
  naver: "네이버",
};

const socialEnglish = {
  google: "Google",
  naver: "Naver",
};
const LoginLogo = styled.img.attrs<LoginLogoProps>((props) => ({
  src: logoTypes[props.type],
}))<LoginLogoProps>``;

export const SocialLoginButton: React.FC<{
  type: LogoType;
  onClick?: () => void;
}> = (props) => {
  const { type, onClick } = props;
  const [isMobile, setIsMobile] = useState(getDeviceDetect().isMobile);

  handleDeviceResize((device: DeviceDetectInterface) =>
    setIsMobile(device.isMobile)
  );

  return (
    <SocialButton isMobile={isMobile} onClick={onClick}>
      <LoginLogo type={type} />
      <TypoGraphy typographyType="Default" variant="Headline1">
        {socialEnglish[type]}
        {/* {socialKoreanTypes[type]}로 로그인하기 */}
      </TypoGraphy>
    </SocialButton>
  );
};
