import { useCallback } from "react";
import styled from "styled-components";
import { cross } from "assets/img/icon/v1";
import { useAudio } from "hooks/videochat/useAudio";
import { TModal, TModalParam, UserChatState } from "../types";
import { TypoGraphy } from "components/typography";
const audio = require("assets/audio/gen_item_get.mp3");

const StateButtonFrame = styled.div<{ state: UserChatState }>`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border-color: #ffffff;

  background: ${({ state }) => {
    let color = "#979797";
    switch (state) {
      case "meeting":
        color = "#644BE6";
        break;
      case "active":
        color = "#82E6C8";
        break;
      case "disabled":
        color = "#979797";
        break;
    }
    return color;
  }};

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px 10px;
`;

const EditFrame = styled.div<{ isMobile: boolean }>`
  position: absolute;
  display: grid;
  place-items: center;
  left: ${({ isMobile }) => (isMobile ? "115px" : "200px")};
`;

interface IStateButton {
  state: UserChatState;
  onChangeModal: (modal: TModal, blur?: boolean, param?: TModalParam) => void;
  minimode: boolean;
  isMobile: boolean;
}

export const StateButton: React.FC<IStateButton> = ({
  state,
  onChangeModal,
  minimode,
  isMobile,
}) => {
  const [playAudio] = useAudio(audio);

  const getDescriptionByState = useCallback(() => {
    let description = "";
    switch (state) {
      case "active":
        description = "Online";
        break;
      case "meeting":
        description = "Meeting";
        break;
      case "disabled":
        description = "Offline";
        break;
      default:
        break;
    }
    return description;
  }, [state]);

  const onClick = () => {
    playAudio();
    onChangeModal("UpdateState", false, state);
  };

  return (
    <StateButtonFrame state={state} onClick={onClick}>
      <TypoGraphy variant="Body2" color="#FFFFFF">
        {getDescriptionByState()}
      </TypoGraphy>
      {!minimode ? (
        <EditFrame isMobile={isMobile}>
          <img src={cross} alt="" width={14} />
        </EditFrame>
      ) : (
        <></>
      )}
    </StateButtonFrame>
  );
};
