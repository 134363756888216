import lounge from "assets/img/places/lounge.png";
import palace from "assets/img/places/palace.png";
import alice from "assets/img/places/alice.png";
import internetCafe from "assets/img/places/internet-cafe.png";

import { useState } from "react";
import { getDeviceDetect, handleDeviceResize } from "utils/WindowDimensions";
import { PlaceDescription } from "./components/PlaceDescription";
import { ExploreSpaceStyled } from "./ExploreSpaceStyled";
import { PlaceCard, PlaceCardProps } from "./components/PlaceCard";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { NAVIGATION_ROUTER } from "routes/constant";
import { inject, observer } from "mobx-react";
import { SceneState } from "pages/metaverse/SceneManager/SceneInterface";
import ExpandMoreIcon from "components/buttons/svg/ExpandMoreIcon";

interface IExploreSpace {
  store?: any;
  haveLimit?: boolean;
}

export const ExploreSpace = inject("store")(
  observer((props: IExploreSpace) => {
    const { store, haveLimit = true } = props;
    const userProfile = store.userStore.user;
    const { user, isNewUser } = store.userStore;
    const navigate = useNavigate();
    const places = [
      {
        title: "Lounge",
        member: 11,
        image: lounge,
        scene: SceneState.LOUNGE,
      },
      {
        title: "Palace",
        member: 11,
        image: palace,
        scene: SceneState.PALACE,
      },
      {
        title: "Alice",
        member: 11,
        image: alice,
        scene: SceneState.ALICE,
      },
      {
        title: "Internet Cafe",
        member: 11,
        image: internetCafe,
        scene: SceneState.INTERNET_CAFE,
      },
    ];
    const [isMobile, setIsMobile] = useState<boolean>(
      getDeviceDetect().isMobile
    );

    const [limit, setLimit] = useState(2);

    const onPlacesCardClick = (sceneState: SceneState) => {
      if (userProfile) {
        // store.sceneStore.setCurrentScene(sceneState);
        navigate({
          pathname: NAVIGATION_ROUTER.METAVERSE,
          search: createSearchParams({
            scene: sceneState.toString(),
          }).toString(),
        });
      } else {
        store.modalStore.setOpenLogin(true);
      }
    };
    handleDeviceResize((device) => setIsMobile(device.isMobile));

    return (
      <ExploreSpaceStyled isMobile={isMobile}>
        <PlaceDescription
          title="Let's go together to explore space"
          description="Choose the space you want. We provide an interactive experience!"
        />
        <Box className="places-list">
          {places.map((place, i) => {
            if (haveLimit) {
              if (i < limit) {
                return (
                  <PlaceCard
                    place={place}
                    onClick={() => onPlacesCardClick(place.scene)}
                  />
                );
              }
            } else {
              return (
                <PlaceCard
                  place={place}
                  onClick={() => onPlacesCardClick(place.scene)}
                />
              );
            }
          })}
        </Box>
        <Divider
          sx={{
            display: limit >= places.length || !haveLimit ? "none" : "flex",
          }}
          className="show-more"
          onClick={() => setLimit(limit + 2)}
        >
          <ExpandMoreIcon />
          <Typography className="more-text">More</Typography>
        </Divider>
      </ExploreSpaceStyled>
    );
  })
);
