import styled from "styled-components";

export const MetaverseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
  }
`;

export const OutfitMenuWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const NickNameIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  text-align: center;
  background-color: #cbe9fa;
  z-index: 9999;
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`;

export const ControllerButtonGroup = styled.div`
  position: absolute;
  bottom: ${({ isMobile }) => (isMobile ? "22px" : "30px")};
  left: 50%;
  transform: translate(-50%, 0);
  display: ${({ visible, isMobile }) =>
    isMobile ? (visible ? "flex" : "none") : "flex"};
  gap: ${({ isMobile }) => (isMobile ? "10px" : "21px")};
`;
