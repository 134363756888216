import React from 'react';
import { SocialLoginButton } from 'components';

type Props = {
  callbackFunc?: () => void;
};
export const GoogleLoginButton: React.FC<Props> = ({ callbackFunc }) => {
  return (
    <SocialLoginButton
      onClick={() => {
        callbackFunc?.();
      }}
      type="google"
    />
  );
};
