import { SVGIconProps } from "../SVGIconInterface";

const Refresh = (props: SVGIconProps) => {
  const { size } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0.632812 9.5C0.632846 7.6568 1.2073 5.85944 2.27627 4.35788C3.34524 2.85633 4.8556 1.72523 6.59726 1.12192C8.33892 0.51861 10.2253 0.473073 11.9941 0.991642C13.7628 1.51021 15.326 2.56711 16.4661 4.01533M18.3661 9.5C18.3661 11.3432 17.7917 13.1406 16.7227 14.6421C15.6537 16.1437 14.1434 17.2748 12.4017 17.8781C10.66 18.4814 8.77365 18.5269 7.00491 18.0084C5.23616 17.4898 3.67299 16.4329 2.53281 14.9847M6.33281 14.5667H1.89948V19M17.0995 0V4.43333H12.6661"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
};

export default Refresh;
