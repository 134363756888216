import { getUserProfile } from "api-manager/services/CharacterServices/CharacterServices";
import { UserChatState } from "components/chat/types";
import { UserVideoChatWay } from "context/UserVideoChatContext";
import {
  action,
  makeAutoObservable,
  observable,
  runInAction,
  when,
} from "mobx";
import { getCookie, setCookie } from "utils/SessionStorageHelper";
export interface IUserProfile {
  accessory: any;
  email: string;
  isNewUser: boolean;
  name: string;
  picture: string;
  verified: boolean;
  stateMessage: string;
  state: UserChatState;
}
export class UserStore {
  @observable private _accessToken: string;
  @observable private _refreshToken: string;
  private _isNewUser: boolean = false;
  private _completeAvatarSetting: boolean = false;
  private _completeNickNameSetting: boolean = false;
  @observable private _user: IUserProfile | null | undefined;
  @observable private _defaultCostume;
  private _selectedCostume;
  private _characterController;
  private _isShowChangeName: boolean = false;

  @observable private _curUserRoom: string;
  @observable private _curUserGroup: string;
  @observable private _videoRoom: string;

  constructor() {
    makeAutoObservable(this);
    this._accessToken = getCookie("accessToken") || "";
    this._refreshToken = getCookie("refreshToken") || "";
    this._curUserRoom = 'lobby';
    this._curUserGroup = '';
    this._videoRoom = '';
    when(
      () => !!this._accessToken,
      () => {
        this.getUserInformation();
      }
    );
  }

  @action
  public setToken(token: string): void {
    setCookie("accessToken", token);
    this._accessToken = token;
  }
  public setRefreshToken(token: string): void {
    setCookie("refreshToken", token);
    this._refreshToken = token;
  }
  @action
  public setIsNewUser(state: boolean): void {
    this._isNewUser = state;
  }
  @action
  public setCompleteAvatar(state: boolean): void {
    this._completeAvatarSetting = state;
  }
  @action
  public setCompleteNickName(state: boolean): void {
    this._completeNickNameSetting = state;
  }
  @action
  public setIsCompleteNewUser(isComplete: boolean) {
    this.setCompleteAvatar(isComplete);
    this.setCompleteNickName(isComplete);
  }
  @action
  public checkinUserStatus(token: string): void {
    this.setToken(token);
    runInAction(() => {
      this.getUserInformation();
    });
  }
  @action
  public async getUserInformation(): Promise<IUserProfile | null> {
    const response = await getUserProfile();
    if (response.status === "SUCCESS") {
      const user = { ...response.data, state: "active" };
      this.setUser(user);
      this.setIsNewUser(response.data.isNewUser);

      this.setIsCompleteNewUser(!response.data.isNewUser);
      return user;
    }
    return null;
  }

  @action
  public setCurUserRoom(room: string) {
    this._curUserRoom = room;
    if (room !== 'lobby') this.setVideoRoom(room);
    // else this.setVideoRoom('');
  }
  @action
  public setCurUserGroup(group: string) {
    this._curUserGroup = group;
    if (this._curUserRoom === 'lobby' && group.length) this.setVideoRoom(group);
    // else this.setVideoRoom('');
  }
  @action
  public setVideoRoom(room: string) {
    this._videoRoom = room;
  }

  public allowMediaService(): boolean {
    return this.isCompleteAvatar && this.isCompleteNickName;
  }

  public setUser(user: IUserProfile | null): void {
    this._user = user;
  }

  public updateUser(user: Partial<IUserProfile>): void {
    if (this._user) {
      this._user = {
        ...this._user,
        ...user,
      };
    }
  }

  get user() {
    return this._user;
  }

  get characterController() {
    return this._characterController;
  }

  get token(): string {
    return this._accessToken;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  get isNewUser(): boolean {
    return this._isNewUser;
  }

  get isCompleteAvatar(): boolean {
    return this._completeAvatarSetting;
  }

  get isCompleteNickName(): boolean {
    return this._completeNickNameSetting;
  }

  public setCharacterController(characterController) {
    this._characterController = characterController;
  }

  get isShowChangeName(): boolean {
    return this._isShowChangeName;
  }

  
  public get curUserRoom() : string {
    return this._curUserRoom;
  }

  public get curUserGroup() : string {
    return this._curUserGroup;
  }

  public get videoRoom() : string {
    return this._videoRoom;
  }
  

  @action
  public setShowChangeName(state: boolean): void {
    this._isShowChangeName = state;
  }
}
