import { Scene } from "@babylonjs/core";
import "@babylonjs/inspector";

export const Inspector = (scene: Scene) => {
  window.addEventListener("keydown", (ev) => {
    if (ev.code === "Backquote") {
      if (scene.debugLayer.isVisible()) {
        scene.debugLayer.hide();
      } else {
        scene.debugLayer.show();
      }
    }
  });
};
