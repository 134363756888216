import { TAB } from "./ShowRoomInterface";
import { Vector3 } from "@babylonjs/core/Maths/math";
import * as GUI from "@babylonjs/gui";

export const basicMesh = ["Chr_Face_00", "Chr_Body_00", "Chr_inner_00"];
export const stageMesh = [
  "floor",
  "lights_primitive0",
  "lights_primitive1",
  "stage_primitive0",
  "stage_primitive1",
];

export const tabData = [
  { title: TAB.SUIT, subTab: [], zoom: new Vector3(-5, 4, 3) },
  { title: TAB.FACE, subTab: [], zoom: new Vector3(-5, 4, 1) },
  { title: TAB.HAIR, subTab: [], zoom: new Vector3(-5, 5, 1) },
  { title: TAB.TOP, subTab: [], zoom: new Vector3(-5, 3, 1) },
  { title: TAB.BOTTOM, subTab: [], zoom: new Vector3(-5, 2, 1) },
  { title: TAB.SHOES, subTab: [], zoom: new Vector3(-5, 1, 1) },
  {
    title: TAB.ACC,
    subTab: [TAB.HAT, TAB.EARRING, TAB.GLASSES, TAB.BAG, TAB.NECKLACE],
    zoom: new Vector3(-5, 4, 1),
  },
];

export const characterItems = [
  {
    label: "Chr_F_Hair_00",
    name: "Chr_F_Hair_00",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_01",
    name: "Chr_F_Hair_01",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_03",
    name: "Chr_F_Hair_03",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_05",
    name: "Chr_F_Hair_05",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_06",
    name: "Chr_F_Hair_06",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_07",
    name: "Chr_F_Hair_07",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_08",
    name: "Chr_F_Hair_08",
    type: TAB.HAIR,
  },
  {
    label: "Chr_F_Hair_09",
    name: "Chr_F_Hair_09",
    type: TAB.HAIR,
  },

  {
    label: "Chr_M_Hair_01",
    name: "Chr_M_Hair_01",
    type: TAB.HAIR,
  },
  {
    label: "Chr_M_Hair_02",
    name: "Chr_M_Hair_02",
    type: TAB.HAIR,
  },
  {
    label: "Chr_M_Hair_03",
    name: "Chr_M_Hair_03",
    type: TAB.HAIR,
  },
  {
    label: "Chr_onepiece_00",
    name: "Chr_onepiece_00",
    type: TAB.SUIT,
  },
  {
    label: "Chr_onepiece_01",
    name: "Chr_onepiece_01",
    type: TAB.SUIT,
  },
  {
    label: "Chr_onepiece_02",
    name: "Chr_onepiece_02",
    type: TAB.SUIT,
  },
  {
    label: "Chr_onepiece_03",
    name: "Chr_onepiece_03",
    type: TAB.SUIT,
  },
  {
    label: "Chr_Face_01",
    name: "Chr_Face_01",
    type: TAB.FACE,
  },
  {
    label: "Chr_Face_02",
    name: "Chr_Face_02",
    type: TAB.FACE,
  },
  {
    label: "Chr_Face_03",
    name: "Chr_Face_03",
    type: TAB.FACE,
  },
  {
    label: "Chr_Top_01",
    name: "Chr_Top_01",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_02",
    name: "Chr_Top_02",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_03",
    name: "Chr_Top_03",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_04",
    name: "Chr_Top_04",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_05",
    name: "Chr_Top_05",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_06",
    name: "Chr_Top_06",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_07",
    name: "Chr_Top_07",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_08",
    name: "Chr_Top_08",
    type: TAB.TOP,
  },

  {
    label: "Chr_Top_09",
    name: "Chr_Top_09",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_10",
    name: "Chr_Top_10",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_11",
    name: "Chr_Top_11",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_14",
    name: "Chr_Top_14",
    type: TAB.TOP,
  },
  {
    label: "Chr_Top_15",
    name: "Chr_Top_15",
    type: TAB.TOP,
  },
  {
    label: "Chr_Bottoms_02",
    name: "Chr_Bottoms_02",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_03",
    name: "Chr_Bottoms_03",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_04",
    name: "Chr_Bottoms_04",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_05",
    name: "Chr_Bottoms_05",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_06",
    name: "Chr_Bottoms_06",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_08",
    name: "Chr_Bottoms_08",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_09",
    name: "Chr_Bottoms_09",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_11",
    name: "Chr_Bottoms_11",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_12",
    name: "Chr_Bottoms_12",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_13",
    name: "Chr_Bottoms_13",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Bottoms_14",
    name: "Chr_Bottoms_14",
    type: TAB.BOTTOM,
  },
  {
    label: "Chr_Shoes_00",
    name: "Chr_Shoes_00",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Shoes_02",
    name: "Chr_Shoes_02",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Shoes_04",
    name: "Chr_Shoes_04",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Shoes_05",
    name: "Chr_Shoes_05",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Shoes_06",
    name: "Chr_Shoes_06",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Shoes_07",
    name: "Chr_Shoes_07",
    type: TAB.SHOES,
  },
  {
    label: "Chr_Bag_01",
    name: "Chr_Bag_01",
    type: TAB.BAG,
  },
  {
    label: "Chr_Cap_01",
    name: "Chr_Cap_01",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_02",
    name: "Chr_Cap_02",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_03",
    name: "Chr_Cap_03",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_04",
    name: "Chr_Cap_04",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_05",
    name: "Chr_Cap_05",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_06",
    name: "Chr_Cap_06",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_07",
    name: "Chr_Cap_07",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_08",
    name: "Chr_Cap_08",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_09",
    name: "Chr_Cap_09",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_10",
    name: "Chr_Cap_10",
    type: TAB.HAT,
  },
  {
    label: "Chr_Cap_12",
    name: "Chr_Cap_12",
    type: TAB.HAT,
  },
  {
    label: "Chr_Earring_01",
    name: "Chr_Earring_01",
    type: TAB.EARRING,
  },
  {
    label: "Chr_Earring_02",
    name: "Chr_Earring_02",
    type: TAB.EARRING,
  },
  {
    label: "Chr_Earring_03",
    name: "Chr_Earring_03",
    type: TAB.EARRING,
  },
  {
    label: "Chr_Glasses_01",
    name: "Chr_Glasses_01",
    type: TAB.GLASSES,
  },
  {
    label: "Chr_Glasses_02",
    name: "Chr_Glasses_02",
    type: TAB.GLASSES,
  },
  {
    label: "Chr_Glasses_03",
    name: "Chr_Glasses_03",
    type: TAB.GLASSES,
  },
  {
    label: "Chr_Glasses_04",
    name: "Chr_Glasses_04",
    type: TAB.GLASSES,
  },
  {
    label: "Chr_Glasses_05",
    name: "Chr_Glasses_05",
    type: TAB.GLASSES,
  },
  {
    label: "Chr_hairband_00",
    name: "Chr_hairband_00",
    type: TAB.HAT,
  },
  {
    label: "Chr_Kick_item_00",
    name: "Chr_Kick_item_00",
    type: TAB.HAT,
  },
  {
    label: "Chr_wings_00",
    name: "Chr_wings_00",
    type: TAB.BAG,
  },
  {
    label: "Chr_necklace_00",
    name: "Chr_necklace_00",
    type: TAB.NECKLACE,
  },
];

export const characterItemColors = [
  {
    type: TAB.FACE,
    skin: [
      "M_Face_01",
      "M_Face_02",
      "M_Face_03",
      "M_Face_04",
      "M_Face_05",
      "M_Face_06",
      "F_Face_01",
      "F_Face_02",
      "F_Face_03",
      "F_Face_04",
      "F_Face_05",
      "F_Face_06",
    ],
  },
  {
    type: TAB.HAIR,
    skin: [
      "Hair_Color_00",
      "Hair_Color_01",
      "Hair_Color_02",
      "Hair_Color_04",
      "Hair_Color_05",
      "Hair_Color_06",
      "Hair_Color_07",
    ],
  },
  {
    type: TAB.EARRING,
    skin: [
      "Earring_Color_01",
      "Earring_Color_02",
      "Earring_Color_03",
      "Earring_Color_04",
      "Earring_Color_05",
    ],
  },
];
