import {
  AbstractMesh,
  AnimationGroup,
  ICrowd,
  Mesh,
  RecastJSPlugin,
  Scene,
  TransformNode,
  Vector2,
  Vector3,
} from "@babylonjs/core";
import { IMouseMove } from "../Network/NetworkHandle";
export interface IAnimation {
  highQuality: AnimationGroup | null;
  lowQuality: AnimationGroup | null;
}
const jumpHeight = 0.02;
const navMeshParameters = {
  cs: 0.1,
  ch: 0.1,
  walkableSlopeAngle: 0,
  walkableHeight: 1.95,
  walkableClimb: 1.95,
  walkableRadius: 1,
  maxEdgeLen: 12,
  maxSimplificationError: 1.3,
  minRegionArea: 8,
  mergeRegionArea: 20,
  maxVertsPerPoly: 6,
  detailSampleDist: 6,
  detailSampleMaxError: 1,
};
const agentParams = {
  radius: 0.1,
  height: 0.2,
  maxAcceleration: 1000,
  maxSpeed: 1.0,
  collisionQueryRange: 0.5,
  pathOptimizationRange: 0.0,
  separationWeight: 1.0,
};
export enum EControlType {
  JOYSTICK = "JOYSTICK",
  POINTERDOWN = "POINTERDOWN",
}
export class OtherPlayerController {
  scene: Scene;
  char: any;
  charLowQuality: any;
  animation: AnimationGroup[];
  startingPoint: Vector3;

  walk: boolean;
  run: boolean;
  jump: boolean;
  isJumping: boolean;

  navigationPlugin: RecastJSPlugin;
  transform: TransformNode;
  dataMosueMove: IMouseMove;

  idleAnim: AnimationGroup;
  walkAnim: AnimationGroup;
  jumpAnim: AnimationGroup;
  runAnim: AnimationGroup;

  crowd: ICrowd;
  controlType: EControlType = EControlType.POINTERDOWN;

  constructor(
    scene: Scene,
    char: any,
    // charLowQuality: any,
    impostors: Mesh,
    currentPoint: Vector3
  ) {
    this.dataMosueMove = {
      positionX: 0,
      positionY: 0,
      positionZ: 0,
      type: "STOP",
      vX: 0,
      vY: 0,
      direction: "NONE",
      distance: 0,
      rotateX: 0,
      rotateY: 0,
      angle: 0,
      seq: 0,
    };
    this.scene = scene;
    this.char = char;
    this.animation = char.animations;
    this.startingPoint = currentPoint;
    this.walk = false;
    this.run = false;
    this.jump = false;
    this.isJumping = false;
    this.idleAnim = this.animation?.find((e) => e.name === "Idle")!;
    this.walkAnim = this.animation?.find((e) => e.name === "Walk")!;
    this.jumpAnim = this.animation?.find((e) => e.name === "Jumping")!;
    this.runAnim = this.animation?.find((e) => e.name === "Run")!;

    this.char.position = new Vector3(0, 0, 0);
    this.transform = new TransformNode(this.char.id);
    this.transform.id = this.char.id;

    this.navigationPlugin = new RecastJSPlugin();
    this.navigationPlugin.createNavMesh([impostors], navMeshParameters);
    // map.wall.forEach((e) => {
    //   this.navigationPlugin.addCylinderObstacle(e.position, 2, 2);
    // });

    this.crowd = this.navigationPlugin.createCrowd(10, 0.1, scene);
    this.char.parent = this.transform;
    this.crowd.addAgent(new Vector3(0, 0, 0), agentParams, this.transform);
    this.crowd.agentTeleport(0, currentPoint);
    this.scene.onBeforeRenderObservable.add(() => {
      const pos = this.crowd.getAgentPosition(0);

      // camera.position = transform.position.clone().add(new Vector3(0, 2, -1));
      if (
        this.dataMosueMove.vX &&
        this.dataMosueMove.vY &&
        !this.isJumping &&
        !this.jump &&
        this.controlType === EControlType.JOYSTICK
      ) {
        const rotated = new Vector2(
          this.dataMosueMove.rotateX,
          this.dataMosueMove.rotateY
        );
        const direction = new Vector2(
          this.dataMosueMove.vX / 5,
          this.dataMosueMove.vY / 5
        );
        const finalTarget = this.rotation(
          rotated.add(direction),
          -this.dataMosueMove.angle
        );
        const pow =
          Math.pow(this.dataMosueMove.vX, 2) +
          Math.pow(this.dataMosueMove.vY, 2);
        if (pow >= 0.9) {
          this.crowd.updateAgentParameters(0, {
            ...agentParams,
            maxSpeed: 2.0,
          });
        } else {
          this.crowd.updateAgentParameters(0, {
            ...agentParams,
            maxSpeed: 1.0,
          });
        }
        this.moveAction(new Vector3(finalTarget.x, pos.y, finalTarget.y));
      }

      if (this.crowd.getAgentVelocity(0).length() > 1.5) {
        this.run = true;
        this.walk = false;
      } else if (this.crowd.getAgentVelocity(0).length() > 0) {
        this.walk = true;
        this.run = false;
      } else {
        this.walk = false;
        this.run = false;
      }

      if (this.jump) {
        // high
        this.jumpingAction();
        this.jumpAnim?.play(true);
        this.walkAnim?.stop();
        this.idleAnim?.stop();
        this.runAnim?.stop();
      } else if (this.walk) {
        // high
        this.jumpAnim?.stop();
        this.walkAnim?.play(true);
        this.idleAnim?.stop();
      } else if (this.run) {
        // high
        this.idleAnim?.stop();
        this.walkAnim?.stop();
        this.runAnim?.play(true);
      } else {
        // high
        this.idleAnim?.play(true);
        this.walkAnim?.stop();
        this.runAnim?.stop();
      }
      this.directToPath(this.char, this.crowd);
    });
  }

  public useJoyStick() {
    this.controlType = EControlType.JOYSTICK;
  }

  public usePointerDown() {
    this.controlType = EControlType.POINTERDOWN;
  }

  public correctPosition(point: Vector3) {
    const currentPos = this.crowd.getAgentPosition(0);
    const distance = Vector3.Distance(currentPos, point);
    if (distance > 0.001) {
      this.crowd.agentTeleport(0, point);
    }
  }

  public mouseMove(data: IMouseMove) {
    this.dataMosueMove = data;
  }

  public moveTo(startingPoint: Vector3, speed: number) {
    this.startingPoint = startingPoint;
    this.crowd.updateAgentParameters(0, { ...agentParams, maxSpeed: speed });
    this.pointerDown(startingPoint);
  }

  public pointerDown(startingPoint: Vector3) {
    // console.log("startingPoint: ", startingPoint);

    const agents = this.crowd.getAgents();
    const target = this.navigationPlugin.getClosestPoint(startingPoint);
    if (!target.equals(new Vector3(0, 0, 0))) {
      for (let i = 0; i < agents.length; i++) {
        this.crowd.agentGoto(agents[i], target);
      }
    }
  }
  public jumpTo() {
    this.jump = true;
    this.isJumping = true;
    setTimeout(() => {
      this.jump = false;
      this.isJumping = false;
    }, 1920);
  }
  private jumpingAction() {
    if (!this.isJumping) {
      this.isJumping = true;

      const jump = (char: AbstractMesh) => {
        setTimeout(() => {
          let time = 0;
          let intervalID = setInterval(function () {
            const currentX = char.absolutePosition.x;
            const currentY = char.absolutePosition.y;
            const currentZ = char.absolutePosition.z;
            char.setAbsolutePosition(
              new Vector3(currentX, currentY + jumpHeight, currentZ)
            );
            if (++time === 20) {
              window.clearInterval(intervalID);
            }
          }, 10);
        }, 400);
        setTimeout(() => {
          let time = 0;
          let intervalID = setInterval(function () {
            const currentX = char.absolutePosition.x;
            const currentY = char.absolutePosition.y;
            const currentZ = char.absolutePosition.z;

            char.setAbsolutePosition(
              new Vector3(currentX, currentY - jumpHeight, currentZ)
            );
            if (++time === 20) {
              window.clearInterval(intervalID);
            }
          }, 20);
        }, 600);
      };
      jump(this.char);
    }
  }

  private lookToAngle() {
    // get the direction vector between the two points
    // const direction = this.startingPoint.subtract(this.char.position);
    // // get the angle between the two vectors
    // const angle = Math.atan2(direction.z, direction.x);
    // // convert the angle to degrees
    // const degrees = (angle * 180) / Math.PI;
    // console.log("degrees: ", degrees);
    // rotate the mesh to point in the direction of the target

    // get the rotation vector from current direction to target direction
    // const rotation = this.char.rotation.y - degrees;
    // console.log("rotation: ", rotation);
    // rotate the mesh to point in the direction of the target
    // this.char.rotation.y = rotation;

    // this.char.rotation = new Vector3(0, this.startingPoint.y, 0);
    this.char.lookAt(this.startingPoint);
  }

  private moveAction(pos: Vector3) {
    const agents = this.crowd.getAgents();
    for (let i = 0; i < agents.length; i++) {
      this.crowd.agentGoto(agents[i], pos);
    }
  }

  private rotation = (target: Vector2, angle: number) => {
    return new Vector2(
      target.x * Math.cos(angle) - target.y * Math.sin(angle),
      target.x * Math.sin(angle) + target.y * Math.cos(angle)
    );
  };

  public directToPath(char: AbstractMesh, crowd: ICrowd) {
    if (crowd.getAgentVelocity(0).length() > 0) {
      char.lookAt(
        Vector3.Normalize(crowd.getAgentVelocity(0)).multiplyByFloats(
          -1,
          -1,
          -1
        )
      );
    }
  }
}
