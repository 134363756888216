import { TypoGraphy } from "components/typography";
import { ReactFragment } from "react";
import { PrimaryButton, ButtonProps, SecondaryButton, TertiaryButton } from "./styles/button";

const Title: React.FC<ButtonProps &{children: ReactFragment}> = ({ buttonType, state, children }) => {
  let render = <></>;

  switch (buttonType) {
    case 'Primary':
      render = (
        <TypoGraphy
          typographyType="Default"
          variant="Headline1"
          color={state === 'Disable' ? '#FFD6E2' : '#FFFFFF'}
        >
          {children}
        </TypoGraphy>
      );
      break;
    case 'Secondary':
      render = (
        <TypoGraphy
          typographyType="Default"
          variant="Headline1"
          color={state === 'Disable' ? '#FFAEC6' : '#EB4678'}
        >
          {children}
        </TypoGraphy>
      );
      break;
    case 'Tertiary':
      render = (
        <TypoGraphy
          typographyType="Default"
          variant="Body2"
          color={state === 'Disable' ? '#FFAEC6' : '#EB4678'}
        >
          {children}
        </TypoGraphy>
      );
      break;
    default:
      break;
  }
  return render;
}

const CustomButton: React.FC<ButtonProps & { children?: ReactFragment, onClick?: () => void }> = ({ buttonType, state, onClick, children, paddingHorizon, disable}) => {
  let render = <></>;
  switch (buttonType) {
    case 'Primary':
      render = (
        <PrimaryButton
          state={state}
          onClick={onClick}
          paddingHorizon={paddingHorizon}
          disabled={disable}
        >
          {
            children ?
            (
              <Title
                buttonType={buttonType}
                state={state}
                children={children}
              />
            )
            : (<></>)
          }
        </PrimaryButton>
      );
      break;
    case 'Secondary':
      render = (
        <SecondaryButton
          state={state}
          onClick={onClick}
          paddingHorizon={paddingHorizon}
        >
          {
            children ?
            (
              <Title
                buttonType={buttonType}
                state={state}
                children={children}
              />
            )
            : (<></>)
          }
        </SecondaryButton>
      );
      break;
    case 'Tertiary':
      render = (
        <TertiaryButton
          state={state}
          onClick={onClick}
        >
          {
            children ?
            (
              <Title
                buttonType={buttonType}
                state={state}
                children={children}
              />
            )
            : (<></>)
          }
        </TertiaryButton>
      );
      break;
    default:
      break;
  }

  return render;
}

export const LegacyButton: React.FC<ButtonProps & { children?: ReactFragment, onClick?: () => void }> = (props) => {
  // const { state, buttonType, onClick, children}  = props;
  
  return (
    <CustomButton
      {
        ...props
      }
    />
  );
}