import {
  ArcRotateCamera,
  Engine,
  HemisphericLight,
  Scene,
  SceneLoader,
  Vector3,
} from "@babylonjs/core";
import { autorun } from "mobx";
import { AssestController } from "pages/metaverse/AssetsManager/AssestController";
import { CharacterController } from "pages/metaverse/CharacterManager/CharacterController";
import { focusOnItem } from "pages/metaverse/utils/FocusOnItem";
import { Inspector } from "pages/metaverse/utils/Inspector";
import { MetaverseStore } from "stores/MetaverseStore";
import { tabData } from "./ShowRoomData";

export default class Showroom {
  private _engine: Engine;
  private _scene: Scene;
  private _store: MetaverseStore;
  public _setLoading;
  public _setLoadingProgress;
  public _camera;
  public _mesh;

  constructor(
    canvas: HTMLCanvasElement,
    store: MetaverseStore,
    setLoading,
    setLoadingProgress
  ) {
    this._setLoading = setLoading;
    this._setLoadingProgress = setLoadingProgress;

    this._store = store;
    this._engine = new Engine(canvas, true, undefined, true);
    this._scene = new Scene(this._engine);
    const light = new HemisphericLight(
      "light",
      new Vector3(0, 1, 0),
      this._scene
    );
    const camera = new ArcRotateCamera(
      "ShowroomCamera",
      Math.PI / 2,
      Math.PI / 2.2,
      13,
      Vector3.Zero(),
      this._scene
    );
    this._camera = camera;
    this.init();

    autorun(() => {
      if (store.userStore.isShowChangeName || store.device.isMobile) {
        camera.target = new Vector3(-3.9, 0, 0);
        camera.radius = 10;
        camera.alpha = Math.PI / 1.9;
        camera.beta = Math.PI / 2.33;
      } else {
        camera.target = Vector3.Zero();
        camera.radius = 13;
        camera.alpha = Math.PI / 2;
        camera.beta = Math.PI / 2.2;
      }
      this._engine.resize();
    });
    Inspector(this._scene);
    this._engine.runRenderLoop(() => this._scene.render());
  }

  private async init() {
    await this.loadStage();
    const character = await new AssestController(this._scene).loadCharacter(
      this._store.userStore.user?.name,
      this._setLoading,
      this._setLoadingProgress
    ); // TODO -> refactor

    character.root.scaling.scaleInPlace(3);
    character.root.position = new Vector3(-4, 0, 0);
    this._mesh = character.root;
    const characterController = new CharacterController(character, this._scene); // TODO -> refactor
    this._store.userStore.setCharacterController(characterController);

    this.runBasicPose();
    autorun(() => {
      characterController.loadOutfit(this._store.userStore);
    });
    const characterModel = characterController.getNameArray(character.outfit);
    const sensitivity = -0.05;
    characterController.rotateOnYAxis(
      this._scene,
      character.root,
      sensitivity,
      characterModel
    );
    if (!this._store.device.isMobile) this.characterZoom(tabData[0].zoom);
  }

  private async loadStage() {
    return SceneLoader.ImportMeshAsync(null, "./models/", "stage.glb").then(
      (result) => {
        result.meshes[0].scaling.scaleInPlace(3);
      }
    );
  }

  public characterZoom(vector) {
    focusOnItem(this._camera, this._mesh, vector, this._scene);
  }

  private runBasicPose() {
    const standIdle = this._scene.getAnimationGroupByName("Idle");
    standIdle?.start(true, 1, standIdle.from, standIdle.to);
  }
}
