import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  ${({ wrap }) => (wrap ? 'flex-wrap: wrap;' : '')}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;
