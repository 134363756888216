import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "assets/img/icon/v2/thick-arrow.svg";
import { useMouseOver } from "hooks/videochat/useMouseOver";
import { useAudio } from "hooks/videochat/useAudio";
import { VideoToggleButton } from "./VideoToggleButton";
import { VideoChatRoom } from "./VideoChatRoom";
import { Room } from "livekit-client";
import { getMediaToken } from "../api";
import { LocalVideo } from "./LocalVideo";
import { UserVideoChatContext } from "context/UserVideoChatContext";
import { UsersTextChatContext } from "context/UsersTextChatContext";
import { UserSettingContext } from "context/UserSettingContext";
import { inject, observer } from "mobx-react";
import { MetaverseStore } from "stores/MetaverseStore";
const sound = require('assets/audio/gen_item_get.mp3');

interface VideoChatProps {
  visible: boolean;
  isMobile: boolean;
  store: MetaverseStore;
}

const ArrowFrame = styled.div<{direction: 'left' | 'right'}>`
  width: 20px;
  transform: rotate(${({direction}) => direction === 'left' ? 180 : 0}deg);
  &:hover {
    cursor: pointer;
  }
`

interface IPageControl {
  direction: 'left' | 'right';
  onClick: (direction: 'left' | 'right') => void;
}

export const PageControl:React.FC<IPageControl> = ({
  direction,
  onClick
}) => {
  const [ onMouse, onMouseOver, onMouseOut ] = useMouseOver<SVGSVGElement>();

  return (
    <ArrowFrame
      direction={direction}
      onClick={() => onClick(direction)}
    >
      <Arrow 
        fill={ onMouse ? "#FFFFFF" : "#979797"}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </ArrowFrame>
  )
}

export const VideoChat = inject('store')(observer((props: VideoChatProps) => {
  const {
    visible,
    isMobile,
    store
  } = props;
  const { userStore, sceneStore } = store;
  const { me } = useContext(UsersTextChatContext);
  const { autoVideoConnection } = useContext(UserSettingContext);
  // const { videoRoom } = useContext(UserVideoChatContext);
  const [ videoRoom, setVideoRoom ] = useState<string>('');
  const [token, setToken] = useState<string>();
  const [ closed, setClosed ] = useState<boolean>(true);

  const [ playAudio ] = useAudio(sound);

  const toggle = useCallback(
    (closed: boolean) => {
      playAudio();
      setClosed(closed);
    },
    [],
  )

  const onLeave = (room: Room) => {
    room.disconnect(true);
    setToken(undefined);
  }

  useEffect(() => {

  })
  useEffect(() => {
    if (userStore.curUserRoom === 'lobby' && userStore.curUserGroup.length) {
      setVideoRoom(userStore.curUserGroup);
    } else if (userStore.curUserRoom !== 'lobby') {
      setVideoRoom(userStore.curUserRoom);
    } else {
      setVideoRoom('');
    }
  },[userStore.curUserGroup, userStore.curUserRoom])

  useEffect(() => {
    if (!sceneStore.currentScene) return;
    if (!autoVideoConnection || !userStore.token) {
      setToken(undefined);
      return;
    }
    if (videoRoom.length) {
      getMediaToken({
        apiUserToken: userStore.token || "",
        nickname: userStore.user?.name || 'unkown',
        room: `${sceneStore.currentScene}-${videoRoom}`,
        canPublish: true,
        canPublishData: false
      }).then((token) => {
        setToken(token);
      })
      .catch((err) => {
        console.log(err);
        
        setToken(undefined);
      })
    } else {
      setToken(undefined);
    }
  }, [videoRoom, autoVideoConnection, userStore.token, userStore.user?.name, sceneStore.currentScene])

  return (
    <div>
      <VideoToggleButton
        visible={visible}
        closed={closed}
        onClick={toggle}
        isMobile={isMobile}
      />
      {
        closed
        ? <></>
        : (
          <>
            {
              token ?
              <VideoChatRoom
                isMobile={isMobile}
                token={token}
                url={process.env.REACT_APP_LIVE_KIT_HOST || ''}
                roomOptions={{
                  adaptiveStream: true,
                  dynacast: true,
                  publishDefaults: {
                    simulcast: true,
                  },
                }}
                onConnected={onConnected}
                onLeave={onLeave}
              />
              : <LocalVideo isMobile={isMobile} nickname={me.nickname}/>
            }
          </>
        )
      }
    </div>
  );
}))

async function onConnected(room: Room) {
  // make it easier to debug
  (window as any).currentRoom = room;
}
